import { Processo } from './processo.model';
import { Esteira } from './esteira.model';

export class WebPerfilPlanoParticipante {
    idPessoa: number;
    idPlano: number;
    dtVigencia: Date;
    idPerfil: number;
    webTipoProcesso: Processo;
    webEsteiraStatus: Esteira;
}
