import { MatTableDataSource, MatSort, MatPaginatorIntl, MatDialog } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator'
import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpClientJsonpModule, HttpHeaders } from '@angular/common/http';
import { WebConfigDashService } from '../../shared/services/webConfigDash.service';
import { WebConfigDash } from '../../shared/models/webConfigDash.model';
import { EditDialogFornecedorComponent } from './dialogs/edit/edit.dialog.component';
import { WebListaFornecedoresService } from '../../shared/services/webListaFornecedores.service';
import { WebListaFornecedores } from '../../shared/models/webListaFornecedores.model';
import { InsertDialogFornecedorComponent } from './dialogs/insert/insert.dialog.component';
import { DeleteDialogFornecedorComponent } from './dialogs/delete/delete.dialog.component';



export interface PeriodicElement {
  nome: string;
  posicao: number;
  peso: number;
  simbolo: string;
}

@Component({
  selector: 'app-configFornecedores',
  templateUrl: './configFornecedores.component.html',
  styleUrls: ['./configFornecedores.component.scss']
})

export class configFornecedoresComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns: string[] = ['actions', 'idFornecedor', 'nmDescricao', 'nrCnpj'];

  dataSource = new MatTableDataSource<any>();
  listaConfig: WebConfigDash[] = [];


  constructor(public dialog: MatDialog,
    private webListaFornecedoresService: WebListaFornecedoresService) {
  }

  ngOnInit() {


    this.webListaFornecedoresService.getAll().subscribe(obj => {
      this.dataSource = new MatTableDataSource<WebListaFornecedores>(obj);
      this.dataSource.paginator = this.paginator;
      console.log(obj);
    });

  }

  insertNew() {
    const dialogRef = this.dialog.open(InsertDialogFornecedorComponent, {
      data: { WebConfigDash: WebConfigDash }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        this.webListaFornecedoresService.criar(this.webListaFornecedoresService.getDialogData()).subscribe(listInsert => {
          console.log(listInsert);

          this.webListaFornecedoresService.getAll().subscribe(obj => {
            this.dataSource = new MatTableDataSource<WebListaFornecedores>(obj);
            this.dataSource.paginator = this.paginator;
            console.log(obj);
            this.refreshTable();
          });

        });

      }
    });
  }

  startEdit(idFornecedor: number, nmDescricao: string, nrCnpj: string) {
    const dialogRef = this.dialog.open(EditDialogFornecedorComponent, {
      data: { idFornecedor: idFornecedor, nmDescricao: nmDescricao, nrCnpj: nrCnpj }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        // When using an edit things are little different, firstly we find record inside DataService by id
        this.webListaFornecedoresService.Put(this.webListaFornecedoresService.getDialogData()).subscribe(listUpdate => {
          console.log(listUpdate);

          this.webListaFornecedoresService.getAll().subscribe(obj => {
            this.dataSource = new MatTableDataSource<WebListaFornecedores>(obj);
            this.dataSource.paginator = this.paginator;
            console.log(obj);
            this.refreshTable();
          });

        });


      }
    });
  }

  deleteItem(idFornecedor: number, nmDescricao: string, nrCnpj: string) {
    const dialogRef = this.dialog.open(DeleteDialogFornecedorComponent, {
      data: { idFornecedor: idFornecedor, nmDescricao: nmDescricao, nrCnpj: nrCnpj }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        this.webListaFornecedoresService.deleteById(idFornecedor).subscribe(listDelete => {
          console.log(listDelete);

          this.webListaFornecedoresService.getAll().subscribe(obj => {
            this.dataSource = new MatTableDataSource<WebListaFornecedores>(obj);
            this.dataSource.paginator = this.paginator;
            console.log(obj);
            this.refreshTable();
          });

        }, error => console.log(error));

      }
    });
  }

  private refreshTable() {
    this.paginator._changePageSize(this.paginator.pageSize);
  }
}
