import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FuseSidebarModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { ContentModule } from 'app/main/content/content.module';
import { FooterModule } from 'app/main/footer/footer.module';
import { NavbarModule } from 'app/main/navbar/navbar.module';
import { QuickPanelModule } from 'app/main/quick-panel/quick-panel.module';
import { ToolbarModule } from 'app/main/toolbar/toolbar.module';
import { SharedModule } from './content/shared/shared.module';
import { FuseMainComponent } from './main.component';


@NgModule({
    declarations: [
        FuseMainComponent
    ],
    imports     : [
        RouterModule,

        FuseSharedModule,
        FuseSidebarModule,
        ContentModule,
        FooterModule,
        NavbarModule,
        QuickPanelModule,
        ToolbarModule,
        SharedModule
    ],
    exports: [
        FuseMainComponent
        
    ]
})
export class MainModule
{

    
}
