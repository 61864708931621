import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HistContribuicao } from '../models/histContribuicao.model';

export class HistContribuicaoService extends ApiService implements BaseService<HistContribuicao>{

    private strAction = 'api/HistContribuicaoADO';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<HistContribuicao[]> {
        return this.get<HistContribuicao[]>(this.strAction + `/GetAll`);
    }

    deleteById(idPessoa: number) {
        return this.post(this.strAction + `/DeleteById?id=${idPessoa}`);
    }

    getById(idPessoa: number): Observable<HistContribuicao> {
        return this.get(this.strAction + `/GetById?id=${idPessoa}`);
    }

    Post(HistContribuicao: HistContribuicao): Observable<HistContribuicao> {
        return this.post<HistContribuicao>(this.strAction + `/Post`, HistContribuicao);
    }


    getUltimaContribuicao(idPessoa : number, idPlano: number): Observable<HistContribuicao> {
        return this.get<HistContribuicao>(this.strAction + `/GetUltimaContribuicao?idPessoa=${idPessoa}&idPlano=${idPlano}`);
    }

}