import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseService } from 'app/core/base.service';
import { ApiService } from './api.services';
import { ViewModelEnviarNotificacao } from '../models/viewModelEnviarNotificacao.model';
import { ViewModelEnviarNotificacaoPessoa } from '../models/viewModelEnviarNotificacaoPessoa.model';

@Injectable()
export class WebNotificacaoService extends ApiService {

    private strAction = 'api/WebNotificacao';
    dialogData: any;


    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    
    listInsertData(viewModelEnviarNotificacao: ViewModelEnviarNotificacao): void {
        this.dialogData = viewModelEnviarNotificacao;
    }

    //Data to be used in CRUD
    getDialogData() {
        return this.dialogData;
    }
    
    enviarNotificacao(viewModelEnviarNotificacao: ViewModelEnviarNotificacao) {
        console.log(viewModelEnviarNotificacao)
        return this.post(this.strAction + `/EnviarNotificacao`, viewModelEnviarNotificacao)
    }

    enviarNotificacaoPessoa(viewModelEnviarNotificacaoPessoa: ViewModelEnviarNotificacaoPessoa) {
        return this.post(this.strAction + `/EnviarNotificacaoPessoa`, viewModelEnviarNotificacaoPessoa)
    }

}