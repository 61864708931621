import { Pessoa } from './pessoa.model';
import { Plano } from './plano.model';
import { Processo } from './processo.model';
import { Esteira } from './esteira.model';

export class WebHistContribuicaoPercentual {
    iD_HIST: number;
    pessoa: Pessoa;
    plano: Plano;
    iD_CONTRIBUICAO: number;
    pC_CONTRIB: number;
    qT_PARCELAS: number;
    dT_VIGENCIA: Date;
    iC_OPTANTE: string;
    qT_TETO: number;
    qT_AJUSTES_PAGAS: number;
    iC_SUSPENSAO: string;
    iD_MOT_ALTERACAO: number;
    processo: Processo;
    status: Esteira;
}