import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { WebContratoSeguroPerguntas } from '../models/webContratoSeguroPerguntas.model';

export class WebContratoSeguroPerguntasService extends ApiService implements BaseService<WebContratoSeguroPerguntas>{
    
    private strAction = 'api/WebContratoSeguroPerguntas';
    
    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
        }

        getAll(): Observable<WebContratoSeguroPerguntas[]> {
            return this.get<WebContratoSeguroPerguntas[]>(this.strAction + `/GetAll`);
        }

        deleteById(idPessoa: number) {
            return this.post(this.strAction + `/DeleteById?id=${idPessoa}`);
        }
    
        Post(WebContratoSeguro: WebContratoSeguroPerguntas): Observable<WebContratoSeguroPerguntas[]>{
            return this.post<WebContratoSeguroPerguntas[]>(this.strAction + `/Post`, WebContratoSeguroPerguntas);
        }

}