import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuseSharedModule } from '@fuse/shared.module';
import { RouterModule } from '@angular/router';
import { MatButtonModule, MatFormFieldModule, MatIconModule, MatInputModule, MatSelectModule, MatDatepickerModule, MatCheckboxModule, MatTableModule, MatTabsModule, MatDividerModule, MatListModule, MatChipsModule, MatDialogModule, MatMenuModule, MatRippleModule, MatSidenavModule, MatToolbarModule, MatTooltipModule, MatPaginatorModule, MatNativeDateModule, MatStepperModule, MatOptionModule, MatRadioModule } from '@angular/material';
import { FuseConfirmDialogModule, FuseMaterialColorPickerModule, FuseWidgetModule } from '@fuse/components';
import { NgxDnDModule } from '@swimlane/ngx-dnd';
import { SharedModule } from './../shared/shared.module';


import { DetalhesComponent } from './detalhes.component';
import { AdesaoComponent } from './tabs/adesao/adesao.component';
import { DependentesComponent } from './tabs/dependentes/dependentes.component';
import { PPEComponent } from './tabs/ppe/ppe.component';
import { CtbVoluntariaComponent } from './tabs/ctbvoluntaria/ctbvoluntaria.component';
import { AlteracaoContribuicaoComponent } from './tabs/alteracaocontribuicao/alteracaocontribuicao.component';
import { SolicitacaoResgateComponent } from './tabs/solicitacaoresgate/solicitacaoresgate.component';
import { SolicitacaoBeneficioComponent } from './tabs/solicitacaobeneficio/solicitacaobeneficio.component';
import { SolicitacaoAutoPatrocinioComponent } from './tabs/solicitacaoautopatrocinio/solicitacaoautopatrocinio.component';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Tema2Module } from '../arearestrita/paginainicial/tema2/tema2.module';
import { TemaModule } from '../arearestrita/paginainicial/tema/tema.module';

const routes = [
  {
    path: 'detalhes',
    component: DetalhesComponent
  }
];

@NgModule({
  declarations:
    [DetalhesComponent,
      AdesaoComponent,
      DependentesComponent,
      PPEComponent,
      CtbVoluntariaComponent,
      AlteracaoContribuicaoComponent,
      SolicitacaoAutoPatrocinioComponent,
      SolicitacaoResgateComponent,
      SolicitacaoBeneficioComponent],
  imports: [
    SharedModule,
    CommonModule,
    FuseSharedModule,
    RouterModule.forChild(routes),
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatTableModule,
    MatTabsModule,
    MatDividerModule,
    MatListModule,
    MatChipsModule,
    MatDialogModule,
    MatMenuModule,
    MatRippleModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTooltipModule,
    NgxDnDModule,
    FuseConfirmDialogModule,
    MatPaginatorModule,
    MatNativeDateModule,
    Ng4LoadingSpinnerModule,
    TemaModule,
    Tema2Module,
    MatRadioModule,
    MatOptionModule,
    BrowserAnimationsModule,
    FormsModule,
    FuseWidgetModule,
    NgxChartsModule,

  ],
  exports: [DetalhesComponent]
})
export class DetalhesModule { }
