// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr: false,
    isDateFormat: true,

    // TrustCRMArHost: 'http://localhost:40320/',
    // TrustCRMApiHost: 'http://localhost:40320/'
   // TrustCRMArHost: 'https://homologacao.trustprev.com.br/basf/webapiadm/',
  // TrustCRMApiHost: 'https://homologacao.trustprev.com.br/basf/webapiadm/'

    // TrustCRMArHost: 'http://localhost:40320/',
    // TrustCRMApiHost: 'http://localhost:40320/',


    //TrustCRMApiHost: 'http://apihmg.sebraeprevidencia.com.br/ApiAdministrativo/',
    //TrustCRMArHost: 'http://webparticipanteshmg.sebraeprevidencia.com.br/',
    //TrustCRMApiHost: 'http://api.sebraeprevidencia.com.br/ApiAdministrativo/',
    //Trust/CRMArHost: 'http://webparticipantes.sebraeprevidencia.com.br/',
    //TrustCRMApiHost: 'http://localhost:40320/',
    //TrustCRMApiHost: 'http://apihmg.sebraeprev.hmg/ApiAdministrativo/',
    // TrustCRMApiHost: 'https://homologacao.trustprev.com.br/sebraeprev/webapiadm/',
    // TrustCRMArHost: 'http://homologacao.trustprev.com.br/sebraeprev/ArNova/',
    //TrustCRMArHost: 'http://homologacao.trustprev.com.br/basf/ar2/',
    //TrustCRMApiHost: 'https://homologacao.trustprev.com.br/previericsson/webapiadm/',
   // TrustCRMApiHost: 'https://homologacao.trustprev.com.br/basf/webapiadm/',
    //TrustCRMApiHost: 'https://homologacao.trustprev.com.br/valueprev/webapiadm/',
    //TrustCRMApiHost: 'https://homologacao.trustprev.com.br/basf/webapiadm/',
    //TrustCRMArHost: 'http://homologacao.trustprev.com.br/basf/Ar2/',
    // TrustCRMApiHost: 'http://familiadev.previndus.com.br/webapiadm/',
    //TrustCRMArHost: 'http://familiadev.previndus.com.br/ar/',
    //TrustCRMApiHost: 'https://familia.previndus.com.br/webapiadm/',
    //TrustCRMArHost: 'https://familia.previndus.com.br/ar/',
    //TrustCRMApiHost: 'https://homologacao.trustprev.com.br/sebraeprev/webapiadm/',
    //TrustCRMArHost: 'https://homologacao.trustprev.com.br/Previndus/webparticipante/',
    //TrustCRMApiHost: 'https://homologacao.trustprev.com.br/Previndus/webapiadm/',
    //TrustCRMArHost: 'https://tst.valueprev.com.br/',
    //TrustCRMApiHost: 'https://tstapi.valueprev.com.br/webapiadm/',
   
  
    //TrustCRMArHost: 'http://homologacao.sebraeprevidencia.com.br/',
    //TrustCRMApiHost: 'https://restritahmg.previericsson.com.br/webapiadm/',
    //TrustCRMArHost: 'https://restrita.previericsson.com.br/restrita/',
    //TrustCRMApiHost: 'https://restrita.previericsson.com.br/webapiadm/',
  

   // ESTEIRA PARA  VALUEPREV PROD
   TrustCRMApiHost: 'http://api.valueprev.com.br/webapiadm/',
   TrustCRMArHost: 'https://arearestrita.valueprev.com.br/',

    //ESTEIRA PARA PREVISC
    // TrustCRMApiHost: 'http://homologacao.trustprev.com.br/previsc/webapiadm/',
    // TrustCRMArHost: '',

    //ESTEIRA PARA BOSCH
   // TrustCRMApiHost: 'http://homologacao.trustprev.com.br/bosch/webapiadm/',
    //TrustCRMArHost: '',
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
