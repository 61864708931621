import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { WebRegraNotificacao } from '../models/webRegraNotificacao.model';

export class WebRegraNotificacaoService extends ApiService implements BaseService<WebRegraNotificacao>{

    private strAction = 'api/WebRegraNotificacao';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<WebRegraNotificacao[]> {
        return this.get<WebRegraNotificacao[]>(this.strAction + `/GetAll`);
    }

    getById(id : number): Observable<WebRegraNotificacao> {
        return this.get<WebRegraNotificacao>(this.strAction + `/GetById/${id}`);
    }

    deleteById(idRegra: number) {
        return this.post(this.strAction + `/DeleteById?id=${idRegra}`);
    }

    Post(WebRegraNotificacao: WebRegraNotificacao): Observable<WebRegraNotificacao[]> {
        return this.post<WebRegraNotificacao[]>(this.strAction + `/CriarRegra`, WebRegraNotificacao);
    }

    Put(WebRegraNotificacao: WebRegraNotificacao): Observable<WebRegraNotificacao[]> {
        return this.post<WebRegraNotificacao[]>(this.strAction + `/Put`, WebRegraNotificacao);
    }

}