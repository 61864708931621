import { HistPlanoParticipanteORMService } from './services/histPlanoParticipanteORM.service';
import { EsteiraService } from './services/esteira.service';
import { ProcessoService } from './services/processo.service';
import { AdesaoStore } from './store/adesao.store';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AdesaoService } from './services/adesao.service';
import { NotfoundComponent } from '../notfound/notfound.component';
import { AtendenteService } from './services/atendente.service';
import { AtendenteStore } from './store/atendente.store';
import { TermoAutoPatrocinioService } from './services/termoAutoPatrocinio.service';
import { TermoBpdService } from './services/termoBpd.service';
import { TermoContribuicaoVoluntariaService } from './services/termoContribuicaoVoluntaria.service';
import { TermoPpeService } from './services/TermoPpe.service';
import { TermoRequerimentoAposentadoriaService } from './services/TermoRequerimentoAposentadoria.service';
import { TermoResgateService } from './services/termoResgate.service';
import { NgxMaskModule, IConfig} from 'ngx-mask';
import { WebGrupoNotificacaoService } from './services/webGrupoNotificacao.service';
import { WebRegraNotificacaoService } from './services/webRegraNotificacao.service';
import { WebRegraRegistrosService } from './services/webRegraRegistros.service';
import { WebTempDependenteService } from './services/webTempDependente.service';
import { WebRegraService } from './services/webRegra.service';
import { WebPlanoDadosCadastraisService } from './services/webPlanoDadosCadastrais.service';
import { WebUploadDocumentosService } from './services/webUploadDocumentos.service';
import { PessoaService } from './services/pessoa.service';
import { FinalidadeService } from './services/finalidade.service';
import { DependenteFinalidadeService } from './services/dependentefinalidade.service';
import { WebStatusRegistroService } from './services/webStatusRegistro.service';
import { WebAlterarCadastroTempService } from './services/webAlterarCadastroTemp.service';
import { WebStatusObsService } from './services/webStatusObs.service';
import { WebHistContribuicaoPercentualService } from './services/webHistContribuicaoPercentual.service';
import { WebRelacaoDocumentosService } from './services/webRelacaoDocumentos.service';
import { HistPlanoParticipanteService } from './services/histPlanoParticipante.service';
import { PlanoParticipanteADOService } from './services/planoParticipanteADO.service';
import { PerfilPlanoParticipanteService } from './services/perfilPlanoParticipante.service';
import { WebHistPlanoParticipanteService } from './services/webHistPlanoParticipante.service';
import { WebPlanoParticipanteService } from './services/webPlanoParticipante.service';
import { WebPerfilPlanoParticipanteService } from './services/webPerfilPlanoParticipante.service';
import { PessoaADOService } from './services/pessoaAdo.service';
import { DadosBancariosADOService } from './services/dadosBancariosADO.service';
import { DadosBancoADOService } from './services/dadosBancoADO.service';
import { HistContribuicaoPercentualService } from './services/histContribuicaoPercentual.service';
import { AutoPatrocinioService } from './services/autoPatrocinio.service';
import { WebStatusSeguradoraService } from './services/webStatusSeguradora.service';
import { DependenteFinalidadeADOService } from './services/dependenteFinalidadeADO.service';
import { DependenteADOService } from './services/dependenteADO.service';
import { WebContratoSeguroService } from './services/webContratoSeguro.service';
import { WebContratoSeguroRespostasService } from './services/webContratoSeguroRespostas.service';
import { WebContratoSeguroPerguntasService } from './services/webContratoSeguroPerguntas.service';
import { WebEsteiraTipoProcesso } from './models/webEsteiraTipoProcesso.model';
import { WebEsteiraProcessoFluxoService } from './services/webEsteiraProcessoFluxo.service';
import { PlanoService } from './services/plano.service';
import { PlanoSituacaoService } from './services/planoSituacao.service';
import { PlanoCategoriaService } from './services/planoCategoria.service';
import { WebDocumentoRestritoService } from './services/webDocumentosRestrito.service';
import { WebUploadDocumentosPessoaService } from './services/webUploadDocumentosPessoa.service';
import { EmpresaService } from './services/empresa.service';
import { PessoaContaBancariaService } from './services/pessoaContaBancaria.service';
import { PlanoParticipanteService } from './services/planoParticipante.service';
import { Parentesco } from './models/parentesco.model';
import { ParentescoService } from './services/parentesco.service';
import { PerfilInvestimentoADOService } from './services/perfilInvestimentoADO.service';
import { EstadoCivilService } from './services/estadoCivil.service';
import { HistContribuicaoService } from './services/histContribuicao.service';
import { WebConfigDashService } from './services/webConfigDash.service';
import { WebTempTermoPortabilidadeService } from './services/webTempTermoPortabilidade.service';
import { WebTempConvitePlanoFamiliaService } from './services/webTempConvitePlanoFamilia.service';
import { WebLoginService } from './services/webLogin.service';
import { WebPlanoAdesaoNovoService } from './services/webPlanoAdesaoNovo.service';
import { ContribuicaoService } from './services/contribuicao.service';
import { WebTempTermoTipoRendaService } from './services/webTempTermoTipoRenda.service';
import { WebTempTermoPerfilService } from './services/webTempTermoPerfil.service';
import { WebTempTermoAlteracaoPercentualService } from './services/termoAlteracaoPercentual.service';
import { EnvioEmailAdesaoService } from './services/envioEmailAdesao.service';
import { WebControleAdesaoService } from './services/webControleAdesao.service';
import { WebListaAtasService } from './services/webListaAtas.service';
import { WebListaFornecedoresService } from './services/webListaFornecedores.service';
import { WebProcessoControleService } from './services/webProcessoControle.service';
import { TermoREService } from './services/TermoRE.service';
import { WebIntegrPerfilFuncADOService } from './services/webIntegrPerfilFuncADO.service';
import { WebIntegrFuncionalidadeADOService } from './services/webIntegrFuncionalidadeADO.service';
import { WebIntegrPerfilADOService } from './services/webIntegrPerfilADO.service';
import { WebTempTermoAlteracaoResponsavelService } from './services/TermoAlteracaoResponsavel.service';
import { WebNotificacaoService } from './services/webNotificacao.service';
import { TermoRgTributarioService } from './services/TermoRgTributario.service';
import { WebSimulacaoService } from './services/webSimulacao.service';
import { WebClienteService } from './services/webCliente.service';
import { RetornarQuestionarioService } from './services/retornarQuestionario.service';
import { TermoContaComplementarService } from './services/TermoContaComplementar.service';
import { TermoUSPersonService } from './services/TermoUSPerson.service';
import { TermoSaqueBeneficioService } from './services/TermoSaqueBeneficio.service';
import { TermoPPECompletoService } from './services/TermoPPECompleto.service';
import { TermoEstadoCivilService } from './services/TermoEstadoCivil.service';
import { TermoIsencaoIRService } from './services/TermoIsencaoIR.service';
import { EnviarEmailCobrancasService } from './services/enviarEmailCobrancas.service';

export let options: Partial<IConfig> | (() =>Partial<IConfig>);

const routes = [
  {
    path: 'errorpage',
    component: NotfoundComponent
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FuseSharedModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxMaskModule.forRoot(options)
  ],
  exports: [
    NgxMaskModule
  ],
  providers:[
    AtendenteService,
    AtendenteStore,
    AdesaoService,
    PessoaService,
    DependenteFinalidadeService,
    FinalidadeService,
    AdesaoStore,
    ProcessoService,
    EsteiraService,
    TermoAutoPatrocinioService,
    TermoBpdService,
    TermoContribuicaoVoluntariaService,
    TermoPpeService,
    TermoRequerimentoAposentadoriaService,
    TermoResgateService,
    WebGrupoNotificacaoService,
    WebRegraNotificacaoService,
    WebRegraRegistrosService,
    WebStatusRegistroService,
    WebAlterarCadastroTempService,
    WebStatusObsService,
    WebUploadDocumentosService,
    WebTempDependenteService,
    WebPlanoDadosCadastraisService,
    WebRegraService,
    WebHistContribuicaoPercentualService,
    WebRelacaoDocumentosService,
    HistPlanoParticipanteService,
    PlanoParticipanteADOService,
    PerfilPlanoParticipanteService,
    WebPlanoParticipanteService,
    WebHistPlanoParticipanteService,
    WebPerfilPlanoParticipanteService,
    PessoaADOService,
    DadosBancariosADOService,
    DadosBancoADOService,
    HistContribuicaoPercentualService,
    AutoPatrocinioService,
    WebStatusSeguradoraService,
    DependenteFinalidadeADOService,
    DependenteADOService,
    WebContratoSeguroService,
    WebContratoSeguroPerguntasService,
    WebContratoSeguroRespostasService,
    WebEsteiraProcessoFluxoService,
    PlanoService,
    PlanoSituacaoService,
    PlanoCategoriaService,
    WebTempTermoPortabilidadeService,
    WebDocumentoRestritoService,
    WebUploadDocumentosPessoaService,
    EmpresaService,
    PessoaContaBancariaService,
    PlanoParticipanteService,
    ParentescoService,
    PerfilInvestimentoADOService,
    EstadoCivilService,
    HistContribuicaoService,
    WebConfigDashService,
    WebTempConvitePlanoFamiliaService,
    WebLoginService,
    WebPlanoAdesaoNovoService,
    ContribuicaoService,
    WebTempTermoTipoRendaService,
    WebTempTermoPerfilService,
    WebTempTermoAlteracaoPercentualService,
    EnvioEmailAdesaoService,
    WebControleAdesaoService,
    WebListaAtasService,
    WebListaFornecedoresService,
    WebProcessoControleService,
    TermoREService,
    WebIntegrPerfilFuncADOService,
    WebIntegrFuncionalidadeADOService,
    WebIntegrPerfilADOService,
    WebTempTermoAlteracaoResponsavelService,
    WebNotificacaoService,
    TermoRgTributarioService,
    WebSimulacaoService,
    WebClienteService,
    RetornarQuestionarioService,
    TermoPPECompletoService,
    TermoEstadoCivilService,
    TermoIsencaoIRService,
    TermoContaComplementarService,
    TermoUSPersonService,
    TermoSaqueBeneficioService,
    EnviarEmailCobrancasService,
    HistPlanoParticipanteORMService
  ]
})
export class SharedModule { }
