import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { WebCliente } from '../models/webCliente.model';
import { ViewModelRetornarQuestionario } from '../models/viewModelRetornarQuestionario.model';

export class RetornarQuestionarioService extends ApiService{

    private strAction = 'api/WebPerfilPreenchimento';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }


    retornar(idPessoa: number): Observable<ViewModelRetornarQuestionario[]> {
        return this.get(this.strAction + `/RetornarQuestionario?idPessoa=${idPessoa}`);
    }

}