import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { PlanoService } from '../../shared/services/plano.service';
import { Plano } from '../../shared/models/plano.model';
import { ViewModelBuscarPessoa } from '../../shared/models/viewModelBuscarPessoa.model';
import { PlanoParticipanteService } from '../../shared/services/planoParticipante.service';
import { SelectionModel } from '@angular/cdk/collections';
import { ModalComponent } from './dialogs/modal/modal.dialog.component';
import { PostFiltroRelatorio } from '../../shared/models/postFiltroRelatorio';
import { Pessoa } from '../../shared/models/pessoa.model';
import { WebNotificacaoService } from '../../shared/services/webNotificacao.service';
import { ViewModelEnviarNotificacao } from '../../shared/models/viewModelEnviarNotificacao.model';

@Component({
    selector: 'app-notificacaoparticipante',
    templateUrl: './notificacaoparticipante.component.html',
    styleUrls: ['./notificacaoparticipante.component.scss']
})
export class notificacaoparticipanteComponent implements OnInit {

    selectedRegime: string;
    selectedPlano: number;
    dataInicio: Date;
    dataFim: Date;
    listaPlano: Plano[] = [];
    postFiltroRelatorio: PostFiltroRelatorio = new PostFiltroRelatorio();

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    displayedColumns: string[] = ['select', 'cpf', 'nome', 'email', 'plano', 'regimeTributario', 'data', 'status'];

    dataSource = new MatTableDataSource<any>();
    selection = new SelectionModel<any>(true, []);

    constructor(private planoService: PlanoService,
        private planoParticipanteService: PlanoParticipanteService,
        private webNotificacaoService: WebNotificacaoService,
        public dialog: MatDialog,
        private _snackBar: MatSnackBar) {

    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected == numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }

    ngOnInit() {
        this.planoService.getAll().subscribe(obj => this.listaPlano = obj);
    }

    buscar() {
        this.postFiltroRelatorio.idPlano = this.selectedPlano;
        this.postFiltroRelatorio.regimeTributario = this.selectedRegime;
        this.postFiltroRelatorio.dataInicio = this.dataInicio;
        this.postFiltroRelatorio.dataFim = this.dataFim;
        this.planoParticipanteService.BuscarPessoa(this.postFiltroRelatorio).subscribe(obj => {
            console.log(obj)
            this.dataSource = new MatTableDataSource<ViewModelBuscarPessoa>(obj);
            this.dataSource.paginator = this.paginator;
            this.refreshTable();
        }, error => console.log(error));
    }

    limpar() {
        this.selectedRegime = undefined;
        this.selectedPlano = undefined;
        this.dataInicio = undefined;
        this.dataFim = undefined;
    }

    private refreshTable() {
        this.paginator._changePageSize(this.paginator.pageSize);
    }


    sendMessage() {
        const dialogRef = this.dialog.open(ModalComponent, {

        });
        dialogRef.afterClosed().subscribe(result => {
            if (result === 1) {
                this.enviarNotificacao()
            }
        });
    }

    enviarNotificacao() {
        let data: ViewModelEnviarNotificacao = this.webNotificacaoService.getDialogData()
        let pessoas: Pessoa[] = []

        this.dataSource.data.map(element => {
            if (this.selection.isSelected(element)) {
                pessoas.push(element.pessoa)
            }
        })

        data.idPlano = this.selectedPlano;
        data.icRegime = this.selectedRegime;
        data.pessoas = pessoas
        this.webNotificacaoService.enviarNotificacao(data).subscribe(obj => {
            this.buscar();
            this._snackBar.open("Notificação enviada com sucesso!", null, {
                duration: 3000
            });
        }, error => console.log(error))
    }
}
