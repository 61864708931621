import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatDividerModule } from '@angular/material/divider';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { MatButtonModule } from '@angular/material/button';
import {MatRippleModule} from '@angular/material/core';
import { DarkenOnHouverDirectiveModule } from '@fuse/directives/darken-on-houver-directive/darken-on-houver-directive.module';

import { MenuselectComponent } from './menuselect.component';

const routes = [
  {
      path     : 'menu',
      component: MenuselectComponent
  }
];


@NgModule({
  declarations: [MenuselectComponent],
  imports: [
    CommonModule,
    FuseSharedModule,
    RouterModule.forChild(routes),
    MatDividerModule,
    FuseWidgetModule,
    MatButtonModule,
    MatRippleModule,
    DarkenOnHouverDirectiveModule
  ],
  exports:[MenuselectComponent]
})
export class MenuselectModule { }
