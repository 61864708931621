export class WebPlanoAdesaoNovo { 
    idPessoa : number;
    nmTin : string;
    icContribBasica : string;
    vlContribBasica : number; 
    nmLinkedin : string;
    nmInstagram : string;
    nmFacebook : string;
    icOutraNacionalidade : string;
    idOutraNacionalidade : number;
}