import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { WebCliente } from '../models/webCliente.model';

export class WebClienteService extends ApiService {

    private strAction = 'api/WebCliente';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getById(id: number): Observable<WebCliente> {
        return this.get(this.strAction + `/GetById/${id}`);
    }

}