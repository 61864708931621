import { MatDividerModule } from '@angular/material/divider';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatButtonModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import { FuseMaterialColorPickerModule } from '@fuse/components';
import { Tema2Component } from './tema2.component';
 
 

@NgModule({
    declarations: [Tema2Component],
    imports: [MatDialogModule, MatButtonModule, CommonModule, MatDividerModule, FuseMaterialColorPickerModule, MatFormFieldModule, MatInputModule ],
    entryComponents: [Tema2Component]
})
export class Tema2Module {}