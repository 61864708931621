import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AutoPatrocinio } from '../models/autoPatrocinio.model';

export class AutoPatrocinioService extends ApiService implements BaseService<AutoPatrocinio>{

    private strAction = 'api/AutoPatrocinioADO';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<AutoPatrocinio[]> {
        return this.get<AutoPatrocinio[]>(this.strAction + `/GetAll`);
    }

    deleteById(idPessoa: number) {
        return this.post(this.strAction + `/DeleteById?id=${idPessoa}`);
    }

    getAutoPatrocinio(idPessoa: number, idPlano : number, idContribuicao : number): Observable<AutoPatrocinio> {
        return this.get(this.strAction + `/GetAutoPatrocinio?pIdPessoa=${idPessoa}&pidPlano=${idPlano}&pidContribuicao=${idContribuicao}`);
    }

    Post(autoPatrocinio: AutoPatrocinio): Observable<AutoPatrocinio> {
        return this.post<AutoPatrocinio>(this.strAction + `/PostAutoPatrocinio`, autoPatrocinio);
    }


}