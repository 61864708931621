import { Component } from '@angular/core';

@Component({
    selector: 'tab-dependentes',
    templateUrl: './dependentes.component.html',
    styleUrls: ['./dependentes.component.scss']
})

export class DependentesComponent {

}