import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { WebListaAtas } from '../models/webListaAtas.model';

export class WebListaAtasService extends ApiService implements BaseService<WebListaAtas>{

    private strAction = 'api/WebListaAtas';
    dialogData: any;

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    listInsertData(webListaAtas: WebListaAtas): void {
        this.dialogData = webListaAtas;
    }

    listUpdateData(webListaAtas: WebListaAtas): void {
        this.dialogData = webListaAtas;
    }

    listDeleteData(id: number): void {
        this.dialogData = id;
    }

    //Data to be used in CRUD
    getDialogData() {
        return this.dialogData;
    }

    getAll(): Observable<WebListaAtas[]> {
        return this.get<WebListaAtas[]>(this.strAction + `/GetAll`);
    }

    deleteById(idAta: number) {
        return this.post(this.strAction + `/DeleteById?id=${idAta}`);
    }

    getById(idAta: number): Observable<WebListaAtas> {
        return this.get(this.strAction + `/GetById/${idAta}`);
    }

    Put(ata: WebListaAtas) {
        return this.post<WebListaAtas>(this.strAction + `/Put`, ata);
    }

    criar(ata: WebListaAtas) {
        return this.post<WebListaAtas>(this.strAction + `/Criar`, ata);
    }

}