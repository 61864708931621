import { Pessoa } from './pessoa.model';
import { WebContratoSeguroPerguntas } from './webContratoSeguroPerguntas.model';

export class WebContratoSeguroRespostas {
    idSeqResposta : Number;
    pessoa : Pessoa;
    idPlano : Number;
    pergunta :  WebContratoSeguroPerguntas;
    idContrato : Number;
    icResposta : string;
    nmResposta : string;
}