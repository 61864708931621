import { Processo } from './processo.model';
import { Esteira } from './esteira.model';

export class WebHistPlanoParticipante {
    idPessoa: number;
    idPlano: number;
    dtIniPlano: Date;
    dtFimPlano: Date;
    idCategoria: number;
    idSituacao: number;
    idMotSituacao: number;
    webTipoProcesso: Processo;
    webEsteiraStatus: Esteira;
}