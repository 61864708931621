import { Component, OnInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';

export interface item {
    color: string;
    background: string;
    text: string;
    router: string;
    icon: string;
}

@Component({
    selector     : 'arearestrita',
    templateUrl  : './arearestrita.component.html',
    styleUrls    : ['./arearestrita.component.scss'],
    animations: fuseAnimations
})

export class AreaRestritaComponent {

    // Menus= ['Operacional','Relátorios', 'Controle de Acesso', 'Configurações', 'Área Restrita'];
  
    Menus: item[] = [
      // { text: 'Operacional', color: '#232323aa', background: 'transparent', router: '/dashboard', icon: 'developer_board' },
      { text: 'Página Inicial', color: '#232323aa', background: 'transparent', router: '/paginaInicial', icon: 'view_list' },
      { text: 'Dados do Participante', color: '#232323aa', background: 'transparent', router: '/dadosParticipante', icon: 'view_list' },
      { text: 'Edição de Dependentes', color: '#232323aa', background: 'transparent', router: '/edicaoDependentes', icon: 'view_list' },
      { text: 'Inclusão de Dependentes', color: '#232323aa', background: 'transparent', router: '/inclusaoDependentes', icon: 'view_list' },
      { text: 'Plano Instituído', color: '#232323aa', background: 'transparent', router: '/planoInstituido', icon: 'view_list' },
      { text: 'Recadastramento', color: '#232323aa', background: 'transparent', router: '/recadastramento', icon: 'view_list' },
      { text: 'Contribuição', color: '#232323aa', background: 'transparent', router: '/contribuicao', icon: 'view_list' },
      { text: 'Requerimento de Benefício', color: '#232323aa', background: 'transparent', router: '/requerimentoBeneficio', icon: 'view_list' },
      { text: 'Alteração de Benefício', color: '#232323aa', background: 'transparent', router: '/alteracaoBeneficio', icon: 'view_list' },
      { text: 'Simulador de Benefício', color: '#232323aa', background: 'transparent', router: '/simuladorBeneficio', icon: 'view_list' },
      { text: 'Empréstimos', color: '#232323aa', background: 'transparent', router: '/emprestimo', icon: 'view_list' },
      { text: 'Dôssie do Participante', color: '#232323aa', background: 'transparent', router: '/dossieParticipante', icon: 'view_list' },
      { text: 'Dashboard', color: '#232323aa', background: 'transparent', router: '/configDashboard', icon: 'view_list' },
      { text: 'Envio de Notificação', color: '#232323aa', background: 'transparent', router: '/notificacaoParticipante', icon: 'view_list' },
      { text: 'Atas', color: '#232323aa', background: 'transparent', router: '/configAtas', icon: 'view_list' },
      { text: 'Fornecedores', color: '#232323aa', background: 'transparent', router: '/configFornecedores', icon: 'view_list' },
    ];
  
    constructor() { }
  
  }