import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { WebUploadDocumentos } from '../models/webUploadDocumentos.model';


export class WebUploadDocumentosService extends ApiService implements BaseService<WebUploadDocumentos>{

    private strAction = 'api/WebUploadDocumentos';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<WebUploadDocumentos[]> {
        return this.get<WebUploadDocumentos[]>(this.strAction + `/GetAll`);
    }

    deleteById(idUpload: number) {
        return this.post(this.strAction + `/DeleteById?id=${idUpload}`);
    }

    getById(idUpload: number): Observable<WebUploadDocumentos[]> {
        return this.get(this.strAction + `/GetById?id=${idUpload}`);
    }

    getByPessoa(idPessoa: number): Observable<WebUploadDocumentos> {
        return this.get(this.strAction + `/GetUploadByPessoa?idPessoa=${idPessoa}`);
    }

}