import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { WebListaAtas } from 'app/main/content/shared/models/webListaAtas.model';
import { WebListaAtasService } from 'app/main/content/shared/services/webListaAtas.service';
import { WebNotificacaoService } from 'app/main/content/shared/services/webNotificacao.service';
import { ViewModelEnviarNotificacao } from 'app/main/content/shared/models/viewModelEnviarNotificacao.model';

@Component({
  selector: 'app-modal.dialog',
  templateUrl: '../../dialogs/modal/modal.dialog.html',
  styleUrls: ['../../dialogs/modal/modal.dialog.scss']
})

export class ModalComponent {

  titulo: string = "";
  mensagem: string = "";

  constructor(public dialogRef: MatDialogRef<ModalComponent>,
    public webNotificacaoService: WebNotificacaoService) { }

  formControl = new FormControl('', [
    Validators.required
    // Validators.email,
  ]);

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' :
      this.formControl.hasError('email') ? 'Not a valid email' :
        '';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public confirm(): void {
    this.webNotificacaoService.listInsertData({
      titulo: this.titulo,
      mensagem: this.mensagem,
      idPlano: 0,
      icRegime: '',
      pessoas: []
    });
  }
}

