import { Component } from '@angular/core';

@Component({
    selector: 'tab-solicitacaobeneficio',
    templateUrl: './solicitacaobeneficio.component.html',
    styleUrls: ['./solicitacaobeneficio.component.scss']
})

export class SolicitacaoBeneficioComponent {

}