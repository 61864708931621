import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { WebRegraRegistros } from '../models/webRegraRegistros.model';


export class WebRegraRegistrosService extends ApiService implements BaseService<WebRegraRegistros>{

    private strAction = 'api/WebRegraRegistros';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<WebRegraRegistros[]> {
        return this.get<WebRegraRegistros[]>(this.strAction + `/GetAll`);
    }

    deleteById(idRegra: number) {
        return this.post(this.strAction + `/DeleteById?id=${idRegra}`);
    }

    getByCpf(nrCpf: string): Observable<WebRegraRegistros[]> {
        return this.get(this.strAction + `/GetRegistroByCpf?nrCpf=${nrCpf}`);
    }

    getByNome(nmRegra: string): Observable<WebRegraRegistros[]> {
        return this.get(this.strAction + `/GetRegistroByNome?nmRegra=${nmRegra}`);
    }

    getByData(dtRegistro: string): Observable<WebRegraRegistros[]> {
        return this.get(this.strAction + `/GetRegistroByData?dtRegistro=${dtRegistro}`);
    }

    getByNomeAndData(nmRegra: string, dtRegistro: string): Observable<WebRegraRegistros[]> {
        return this.get(this.strAction + `/GetRegistroByNomeAndData?nmRegra=${nmRegra}&dtRegistro=${dtRegistro}`);
    }

    getByPeriod(dtInicio: string, dtFim: string): Observable<WebRegraRegistros[]> {
        return this.get(this.strAction + `/GetRegistroByPeriod?dtInicio=${dtInicio}&dtFim=${dtFim}`);
    }

    getByNomeAndPeriod(nmRegra: string, dtInicio: string, dtFim: string): Observable<WebRegraRegistros[]> {
        return this.get(this.strAction + `/GetRegistroByPeriod?nmRegra=${nmRegra}&dtInicio=${dtInicio}&dtFim=${dtFim}`);
    }

}