import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Parentesco } from '../models/parentesco.model';

@Injectable()
export class ParentescoService extends ApiService implements BaseService<Parentesco>{

    private strAction = 'api/Parentesco';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

   deleteById(idParentesco: number) {
        return this.post(this.strAction + `/DeleteById?id=${idParentesco}`);
    }

    getAll(): Observable<Parentesco[]> {
        return this.get<Parentesco[]>(this.strAction + `/GetAll`);
    }


    getById(idParentesco: number): Observable<Parentesco> {
        return this.get(this.strAction + `/GetById/${idParentesco}`);
    }


}