import { Parentesco } from './parentesco.model';
import { Pessoa } from './pessoa.model';
import { Esteira } from './esteira.model';

export class WebTempConvitePlanoFamilia {
    idConvite : number;
    pessoa : Pessoa;
    dtEnvio : Date;
    nmEmailDep : string;
    nrCpfDep : string;
    parentesco : Parentesco;
    webEsteiraStatus: Esteira;
    nmPessoaDep : string;
    nmEmail2: string;
    nrTelefoneDep : string;
    nrCelularDep : string;
    dtNascimentoDep : Date;
    nmPessoaResp : string;
    nrCpfResp : string;
    nrRgResp : string;
    nmEnderecoResp : string;
    dtNascimentoResp : string;
    nrTelefoneResp : string;
    nrCelularResp : string;
    nmEmailResp : string;
    nmEmail2Resp : string;
}