import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HistContribuicaoPercentual } from '../models/histContribuicaoPercentual.model';

export class HistContribuicaoPercentualService extends ApiService implements BaseService<HistContribuicaoPercentual>{

    private strAction = 'api/HistContribuicaoPercentualADO';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<HistContribuicaoPercentual[]> {
        return this.get<HistContribuicaoPercentual[]>(this.strAction + `/GetAll`);
    }

    deleteById(idPessoa: number) {
        return this.post(this.strAction + `/DeleteById?id=${idPessoa}`);
    }

    getById(idPessoa: number): Observable<HistContribuicaoPercentual> {
        return this.get(this.strAction + `/GetById?id=${idPessoa}`);
    }

    getByPessoa(idPessoa: number, idPlano: number, idContrib : number): Observable<HistContribuicaoPercentual> {
        return this.get(this.strAction + `/GetHistContribuicaoPercentual?pIdPessoa=${idPessoa}&pIdPlano=${idPlano}&pIdContribuicao=${idContrib}`);
    }

    Post(histContribuicaoPercentual: HistContribuicaoPercentual): Observable<HistContribuicaoPercentual> {
        return this.post<HistContribuicaoPercentual>(this.strAction + `/PostHistContribuicaoPercentual`, histContribuicaoPercentual);
    }

    CriarList(histContribuicaoPercentual: HistContribuicaoPercentual[]): Observable<HistContribuicaoPercentual> {
        return this.post<HistContribuicaoPercentual>(this.strAction + `/PostHistContribuicaoPercentualLista`, histContribuicaoPercentual);
    }

}