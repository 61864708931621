import { Component } from '@angular/core';

@Component({
    selector: 'tab-alteracaocontribuicao',
    templateUrl: './alteracaocontribuicao.component.html',
    styleUrls: ['./alteracaocontribuicao.component.scss']
})

export class AlteracaoContribuicaoComponent {

}