import { MatTableModule } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Tema2Module } from './paginainicial/tema2/tema2.module';
import { TemaModule } from './paginainicial/tema/tema.module';
import { FuseMaterialColorPickerModule } from './../../../../@fuse/components/material-color-picker/material-color-picker.module';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgModule, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatDividerModule } from '@angular/material/divider';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule, MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { MatTabsModule, MatRadioModule, MatTableDataSource } from '@angular/material';
import { notificacaoparticipanteComponent } from './notificacaoparticipante/notificacaoparticipante.component';
import { dossieparticipanteComponent } from './dossieparticipante/dossieparticipante.component';
import { emprestimoComponent } from './emprestimo/emprestimo.component';
import { simuladordebeneficioComponent } from './simuladordebeneficio/simuladordebeneficio.component';
import { alteracaodebeneficioComponent } from './alteracaodebeneficio/alteracaodebeneficio.component';
import { requerimentodebeneficioComponent } from './requerimentodebeneficio/requerimentodebeneficio.component';
import { contribuicaoComponent } from './contribuicao/contribuicao.component';
import { recadastramentoComponent } from './recadastramento/recadastramento.component';
import { planoinstituidoComponent } from './planoinstituido/planoinstituido.component';
import { inclusaodedependentesComponent } from './inclusaodedependentes/inclusaodedependentes.component';
import { edicaodedependentesComponent } from './edicaodedependentes/edicaodedependentes.component';
import { AreaRestritaComponent } from './arearestrita.component';
import { paginainicialComponent } from './paginainicial/paginainicial.component';
import { dadosparticipanteComponent } from './dadosparticipante/dadosparticipante.component';
import { configDashboardComponent } from './configdashboard/configDashboard.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { configAtasComponent } from './configatas/configAtas.component';
import { configFornecedoresComponent } from './configfornecedores/configfornecedores.component';
import { configFuncionalidadesComponent } from './configfuncionalidades/configFuncionalidades.component';
import { SharedModule } from '../shared/shared.module';
import { SimulacoesComponent } from './simulacoes/simulacoes.component';
import { cobrancaComponent } from './cobranca/cobranca.component';

const routes = [
    {
        path: 'restrita',
        component: AreaRestritaComponent
    },

    {
        path: 'alteracaoBeneficio',
        component: alteracaodebeneficioComponent
    },

    {
        path: 'configDashboard',
        component: configDashboardComponent
    },

    {
        path: 'configAtas',
        component: configAtasComponent
    },

    {
        path: 'configFuncionalidades',
        component: configFuncionalidadesComponent
    },


    {
        path: 'configFornecedores',
        component: configFornecedoresComponent
    },


    {
        path: 'contribuicao',
        component: contribuicaoComponent
    },

    {
        path: 'cobranca',
        component: cobrancaComponent
    },

    {
        path: 'dadosParticipante',
        component: dadosparticipanteComponent
    },

    {
        path: 'dossieParticipante',
        component: dossieparticipanteComponent
    },

    {
        path: 'edicaoDependentes',
        component: edicaodedependentesComponent
    },

    {
        path: 'emprestimo',
        component: emprestimoComponent
    },

    {
        path: 'inclusaoDependentes',
        component: inclusaodedependentesComponent
    },

    {
        path: 'notificacaoParticipante',
        component: notificacaoparticipanteComponent
    },

    {
        path: 'simulacoes',
        component: SimulacoesComponent
    },
    

    {
        path: 'paginaInicial',
        component: paginainicialComponent
    },

    {
        path: 'planoInstituido',
        component: planoinstituidoComponent
    },

    {
        path: 'recadastramento',
        component: recadastramentoComponent
    },

    {
        path: 'requerimentoBeneficio',
        component: requerimentodebeneficioComponent
    },

    {
        path: 'simuladorBeneficio',
        component: simuladordebeneficioComponent
    }

];



@NgModule({
    declarations: [AreaRestritaComponent,
        paginainicialComponent, dadosparticipanteComponent,
        edicaodedependentesComponent, inclusaodedependentesComponent, planoinstituidoComponent,
        recadastramentoComponent, contribuicaoComponent, cobrancaComponent, requerimentodebeneficioComponent, alteracaodebeneficioComponent,
        simuladordebeneficioComponent, emprestimoComponent, dossieparticipanteComponent, configDashboardComponent, configAtasComponent, configFuncionalidadesComponent, configFornecedoresComponent, notificacaoparticipanteComponent, SimulacoesComponent],
    imports: [
        CommonModule,
        FuseSharedModule,
        SharedModule,
        RouterModule.forChild(routes),
        NgxChartsModule,
        MatDividerModule,
        FuseWidgetModule,
        MatButtonModule,
        MatRippleModule,
        MatIconModule,
        BrowserAnimationsModule,
        FormsModule,
        MatTabsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        MatInputModule,
        FuseMaterialColorPickerModule,
        TemaModule,
        Tema2Module,
        MatRadioModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatTableModule,
        MatPaginatorModule


    ],
    exports: [AreaRestritaComponent]
})
export class AreaRestritaModule { }