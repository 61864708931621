import { Component } from '@angular/core';

@Component({
    selector: 'tab-solicitacaoautopatrocinio',
    templateUrl: './solicitacaoautopatrocinio.component.html',
    styleUrls: ['./solicitacaoautopatrocinio.component.scss']
})

export class SolicitacaoAutoPatrocinioComponent {

}