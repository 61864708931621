import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { DependenteFinalidadeADO } from '../models/dependenteFinalidadeADO.model';

@Injectable()
export class DependenteFinalidadeADOService extends ApiService implements BaseService<DependenteFinalidadeADO>{

    private strAction = 'api/DependenteFinalidadeADO';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    deleteById(idPessoa: number) {
        return this.post(this.strAction + `/DeleteById?id=${idPessoa}`);
    }

    getAll(): Observable<DependenteFinalidadeADO[]> {
        return this.get<DependenteFinalidadeADO[]>(this.strAction + `/GetAll`);
    }


    getById(idPessoa: number): Observable<DependenteFinalidadeADO> {
        return this.get(this.strAction + `/GetByIdPessoa/${idPessoa}`);
    }


    getDep(idPessoa: number, idPessoaDep: number): Observable<DependenteFinalidadeADO> {
        return this.get(this.strAction + `/Get?idPessoa=${idPessoa}&idPessoaDep=${idPessoaDep}`);
    }

    Put(dependenteFinalidade: DependenteFinalidadeADO): Observable<DependenteFinalidadeADO> {
        return this.put<DependenteFinalidadeADO>(this.strAction + `/PutUpdateFimVinculoDependente`, dependenteFinalidade);
    }

    Post(dependenteFinalidade: DependenteFinalidadeADO): Observable<DependenteFinalidadeADO> {
        return this.post<DependenteFinalidadeADO>(this.strAction + `/PostInsertDependenteFinalidade`, dependenteFinalidade);
    }

}