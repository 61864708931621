import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PlanoCategoria } from '../../shared/models/planoCategoria.model';
import { PlanoSituacao } from '../../shared/models/planoSituacao.model';
import { Plano } from '../../shared/models/plano.model';
import { WebDocumentosRestrito } from '../../shared/models/webDocumentosRestrito.model';
import { Pessoa } from '../../shared/models/pessoa.model';
import { WebDocumentoRestritoService } from '../../shared/services/webDocumentosRestrito.service';
import { PlanoService } from '../../shared/services/plano.service';
import { PlanoCategoriaService } from '../../shared/services/planoCategoria.service';
import { PlanoSituacaoService } from '../../shared/services/planoSituacao.service';
import { PessoaService } from '../../shared/services/pessoa.service';
import { environment } from 'environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'upload-documentos',
    templateUrl: './uploadDocumentos.component.html',
    styleUrls: ['./uploadDocumentos.component.scss'],

})
export class UploadDocumentosComponent implements OnInit {
    /**
     * Constructor
     */

    form: FormGroup;
    progress: number;
    message: string;
    icParticipante: string = '';
    cpf: string = '';
    nome: string = '';
    nrInscricao: string = '';
    listaCategorias: PlanoCategoria[] = [];
    listaSituacoes: PlanoSituacao[] = [];
    listaPlanos: Plano[] = [];
    idPlano: number;
    idCategoria: number;
    idSituacao: number;
    upload: boolean = false;
    documentoRestrito: WebDocumentosRestrito = new WebDocumentosRestrito();
    pessoa: Pessoa = new Pessoa();
    timer: any;
    descricao: string = '';
    canUpload: boolean = false;

    @Output() public onUploadFinished = new EventEmitter();

    /**
     * Constructor
     *
     * @param {FormBuilder} _formBuilder
     */


    constructor(private http: HttpClient,
        private webDocumentosRestrito: WebDocumentoRestritoService,
        private planoService: PlanoService,
        private planoCategoriaService: PlanoCategoriaService,
        private planoSituacaoService: PlanoSituacaoService,
        private pessoaService: PessoaService,
        private _formBuilder: FormBuilder,
        private _snackBar: MatSnackBar) {
    }


    ngOnInit() {
        this.form = this._formBuilder.group({
            icParticipante: [],
            cpf: [],
            idCategoria: [],
            idSituacao: [],
            idPlano: [],
            descricao: [],
            nome: [],
            nrInscricao: []
        });


        this.planoService.getAll().subscribe(obj => { this.listaPlanos = obj });
        this.planoCategoriaService.getAll().subscribe(obj => { console.log(obj); this.listaCategorias = obj });
        this.planoSituacaoService.getAll().subscribe(obj => { console.log(obj); this.listaSituacoes = obj });

    }

    search(cpf) {
        clearTimeout(this.timer);
        setTimeout(obj => {
            this.pessoaService.getByCpf(cpf).subscribe(obj => {
                if (obj) {
                    console.log(obj);
                    this.nome = obj.nmPessoa;
                    this.nrInscricao = obj.nrInscricao
                    this.canUpload = true;
                } else this.canUpload = false;
            }, error => this.canUpload = false);
        }, 100);
    }

    public uploadFile = (files) => {

        console.log('teste1');

        if (this.icParticipante == null) {
            return;
        }

        console.log('teste2');

        if (files.length === 0) {
            return;
        }

        console.log('teste3');

        this.upload = true;

        let fileToUpload = <File>files[0];
        let name = Math.random().toString(36).substring(7) + `.${fileToUpload.name[fileToUpload.name.length - 3] + fileToUpload.name[fileToUpload.name.length - 2] + fileToUpload.name[fileToUpload.name.length - 1]}`;
        const formData = new FormData();
        formData.append('file', fileToUpload, name);

        console.log(name)
        
        this.http.post(environment.TrustCRMApiHost + 'api/WebAdmUpload/Upload', formData, {
            reportProgress: true, observe: 'events', headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'authkey',
                'userid': '1'
            }
        })
            .subscribe(event => {
                if (event.type === HttpEventType.UploadProgress)
                    this.progress = Math.round(100 * event.loaded / event.total);
                else if (event.type === HttpEventType.Response) {
                    this.upload = false;
                    this.onUploadFinished.emit(event.body);
                    
                    this.documentoRestrito.nmDocumento = name;
                    this.documentoRestrito.descricaoDocumento = this.descricao;
                    this.documentoRestrito.tipoParticipante = this.icParticipante;
                    this.documentoRestrito.cpfParticipante = this.cpf;
                    this.documentoRestrito.idCategoria = this.idCategoria;
                    this.documentoRestrito.idSituacao = this.idSituacao;
                    this.documentoRestrito.plano = new Plano();
                    this.documentoRestrito.plano.idPlano = this.idPlano;
                    this.documentoRestrito.dtDocumento = new Date();
                    this.webDocumentosRestrito.Post(this.documentoRestrito).subscribe(obj => {
                        if (obj) {
                            this._snackBar.open("Upload realizado com sucesso!", null, {
                                duration: 3000
                            });
                        }
                    }, error => this._snackBar.open("Falha no Upload!", null, {
                        duration: 3000
                    }));

                }
            }, error => {
                this.upload = false;
                this._snackBar.open("Falha no Upload!", null, {
                    duration: 3000
                });
            });
    }

    limpar() {
        this.descricao = undefined;
        this.nome = undefined;
        this.cpf = undefined;
        this.nrInscricao = undefined;
        this.idCategoria = undefined;
        this.idSituacao = undefined;
        this.idPlano = undefined;
      }

}
