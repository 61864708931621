import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { WebUploadDocumentosService } from '../../shared/services/webUploadDocumentos.service';
import { WebUploadDocumentos } from '../../shared/models/webUploadDocumentos.model';
import { WebRelacaoDocumentos } from '../../shared/models/webRelacaoDocumentos.model';
import { WebRelacaoDocumentosService } from '../../shared/services/webRelacaoDocumentos.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { WebTempConvitePlanoFamiliaService } from '../../shared/services/webTempConvitePlanoFamilia.service';
import { WebTempConvitePlanoFamilia } from '../../shared/models/webTempConvitePlanoFamilia.model';
import { FiltroConvite } from '../../shared/models/filtroConvite.model';
import { EsteiraService } from '../../shared/services/esteira.service';
import { Esteira } from '../../shared/models/esteira.model';

@Component({
    selector: 'consultar-Convites',
    templateUrl: './consultarConvites.component.html',
    styleUrls: ['./consultarConvites.component.scss'],
})

export class ConsultarConvitesComponent implements OnInit {


    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;

    dataSource = new MatTableDataSource<any>();
    displayedColumns: string[] = ['nome', 'cpf', 'nomeConvidado', 'cpfConvidado', 'parentesco', 'status', 'data', 'acoes'];
    listaConvite: WebTempConvitePlanoFamilia[] = [];
    convite: WebTempConvitePlanoFamilia = new WebTempConvitePlanoFamilia();

    PostFiltro: FiltroConvite = new FiltroConvite();

    selectedSituacao: number;
    dataInicio: Date;
    dataFim: Date;
    lista_Esteira: Esteira[] = [];

    constructor(
        private webTempConvitePlanoFamiliaService: WebTempConvitePlanoFamiliaService,
        private esteiraService: EsteiraService
    ) {
    }

    ngOnInit() {

        this.esteiraService.getAll().subscribe(esteira => {
            console.log(esteira);
            this.lista_Esteira = esteira;
            // this.selectedProcesso = 1; 
        });
        
    }


    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }


    handle(index: number) {
        // if (index != null) this.mensagem = this.listaRegistros[index].mensagem;
        this.convite = this.listaConvite.find(x => x.idConvite == index);
        let modal = document.querySelector('div.modal');
        modal.classList.toggle('hidden');
    }

    filtrar() {
        this.PostFiltro.idStatus = this.selectedSituacao;
        this.PostFiltro.dtInicio = this.dataInicio;
        this.PostFiltro.dtFim = this.dataFim;
        this.webTempConvitePlanoFamiliaService.PostFiltro(this.PostFiltro).subscribe(obj => {
            this.listaConvite = obj; this.dataSource = new MatTableDataSource<WebTempConvitePlanoFamilia>(this.listaConvite);
            this.dataSource.paginator = this.paginator;
        });
    }

    limpar() {
        this.selectedSituacao = undefined;
        this.dataInicio = undefined;
        this.dataFim = undefined;
    }


}