import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatIconModule } from '@angular/material/icon';

import { NotfoundComponent } from './notfound.component';


const routes = [
  {
      path     : '404',
      component: NotfoundComponent
  }
];

@NgModule({
  declarations: [NotfoundComponent],
  imports: [
    CommonModule,
    FuseSharedModule,
    RouterModule.forChild(routes),
    MatIconModule
  ],
  exports:[NotfoundComponent]
})
export class NotfoundModule { }
