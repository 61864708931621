import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { TermoResgate } from '../models/termoResgate.model';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { WebPlanoDadosCadastrais } from '../models/webPlanoDadosCadastrais.model';
import { ViewModelFiltraProcesso } from '../models/ViewModelFiltraProcesso.model';

export class WebPlanoDadosCadastraisService extends ApiService implements BaseService<WebPlanoDadosCadastrais>{

    private strAction = 'api/WebPlanoDadosCadastrais';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<WebPlanoDadosCadastrais[]> {
        return this.get<WebPlanoDadosCadastrais[]>(this.strAction + `/GetAll`);
    }

    deleteById(webPlanoDadosCadastrais: number) {
        return this.post(this.strAction + `/DeleteById?id=${webPlanoDadosCadastrais}`);
    }

    PostFiltroWebPlanoDadosCadastrais(viewModelFiltraProcesso: ViewModelFiltraProcesso): Observable<WebPlanoDadosCadastrais[]> {
        return this.post<WebPlanoDadosCadastrais[]>(this.strAction + `/PostFiltroWebPlanoDadosCadastrais`, viewModelFiltraProcesso);
    }
}