import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TermoPercentualContribuicao } from '../models/termoPercentualContribuicao';
import { ViewModelFiltraProcesso } from '../models/ViewModelFiltraProcesso.model';
import { ViewModelAtualizarConfig } from '../models/viewModelAtualizarConfig.model';

export class WebTempTermoAlteracaoPercentualService extends ApiService implements BaseService<TermoPercentualContribuicao>{

    private strAction = 'api/WebTempTermoAlteracaoPercentual';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<TermoPercentualContribuicao[]> {
        return this.get<TermoPercentualContribuicao[]>(this.strAction + `/GetAll`);
    }

    deleteById(idTermo: number) {
        return this.post(this.strAction + `/DeleteById?id=${idTermo}`);
    }

    Post(TermoTipoRenda: TermoPercentualContribuicao): Observable<TermoPercentualContribuicao[]> {
        return this.post<TermoPercentualContribuicao[]>(this.strAction + `/Criar`, TermoPercentualContribuicao);
    }

    getById(id: number): Observable<TermoPercentualContribuicao> {
        return this.get<TermoPercentualContribuicao>(this.strAction + `/GetById/${id}`);
    }

    
    PostFiltroTermoAlteracaoPercentual(viewModelFiltraProcesso: ViewModelFiltraProcesso): Observable<TermoPercentualContribuicao[]> {
        return this.post<TermoPercentualContribuicao[]>(this.strAction + `/PostFiltroTermoAlteracaoPercentual`, viewModelFiltraProcesso);
    }

    retornarConfig(): Observable<ViewModelAtualizarConfig> {
        return this.get<ViewModelAtualizarConfig>(this.strAction + `/RetornarConfig`);
    }

    Atualizar(viewModelAtualizarConfig: ViewModelAtualizarConfig): Observable<void> {
        return this.post<void>(this.strAction + `/AtualizarConfig`, viewModelAtualizarConfig);
    }
    
    getByCpf(cpf : string): Observable<TermoPercentualContribuicao[]> {
        return this.get<TermoPercentualContribuicao[]>(this.strAction + `/GetByCpf?nrCpf=${cpf}`);
    }

    Put(TermoPercentualContribuicao: TermoPercentualContribuicao): Observable<TermoPercentualContribuicao> {
        return this.post<TermoPercentualContribuicao>(this.strAction + `/Put`, TermoPercentualContribuicao);
    }



}