import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { WebUploadDocumentosService } from '../../shared/services/webUploadDocumentos.service';
import { WebUploadDocumentos } from '../../shared/models/webUploadDocumentos.model';
import { WebRelacaoDocumentos } from '../../shared/models/webRelacaoDocumentos.model';
import { WebRelacaoDocumentosService } from '../../shared/services/webRelacaoDocumentos.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { WebUploadDocumentosPessoa } from '../../shared/models/webUploadDocumentosPessoa.model';
import { WebUploadDocumentosPessoaService } from '../../shared/services/webUploadDocumentosPessoa.service';
import { WebUploadDocumentosPessoaFiltro } from '../../shared/models/webUploadDocumentosPessoaFiltro.model';

@Component({
    selector: 'consultar-documentos',
    templateUrl: './consultarDocumentos.component.html',
    styleUrls: ['./consultarDocumentos.component.scss'],
})

export class ConsultarDocumentosComponent implements OnInit {


    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;

    dataSource = new MatTableDataSource<any>();
    displayedColumns: string[] = ['nome', 'cpf', 'documento', 'data', 'acoes'];

    listaUpload: WebUploadDocumentosPessoa[] = []
    listaDocumentos: WebRelacaoDocumentos[] = [];
    filtro: WebUploadDocumentosPessoaFiltro = new WebUploadDocumentosPessoaFiltro();
    index: number;
    cpf: string = '';
    nome: string = '';
    idDocumento: number;
    dataInicio: Date;
    dataFim: Date;

    constructor(
        private webUploadDocumentosPessoaService: WebUploadDocumentosPessoaService,
        private webRelacaoDocumentosService: WebRelacaoDocumentosService
    ) {
    }

    ngOnInit() {

        this.webUploadDocumentosPessoaService.getAll().subscribe(obj => {
            this.listaUpload = obj;
            this.dataSource = new MatTableDataSource<WebUploadDocumentosPessoa>(this.listaUpload);
            this.dataSource.paginator = this.paginator;
        });


        this.webRelacaoDocumentosService.getAll().subscribe(obj => {
            this.listaDocumentos = obj;
        });

    }


    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }

    goToLink(doc: string) {
        window.open(environment.TrustCRMArHost + "Arquivos/Area/Interacao/" + doc, "_blank");
    }

    filtrar() {
        this.filtro.nome = this.nome;
        this.filtro.nrCpf = this.cpf;
        this.filtro.idDocumento = this.idDocumento;
        this.filtro.dtIni = this.dataInicio;
        this.filtro.dtFim = this.dataFim;

        this.webUploadDocumentosPessoaService.filtrar(this.filtro).subscribe(obj => {
            this.listaUpload = obj;
            this.dataSource = new MatTableDataSource<WebUploadDocumentosPessoa>(this.listaUpload);
            this.dataSource.paginator = this.paginator;
        });
    }

    limpar() {
        this.nome = '';
        this.cpf = '';
        this.idDocumento = 0;
        this.dataInicio = undefined;
        this.dataFim = undefined;

        this.filtro.nome = undefined;
        this.filtro.nrCpf = undefined;
        this.filtro.idDocumento = 0;
        this.filtro.dtIni = undefined;
        this.filtro.dtFim = undefined;

    }


}