import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseService } from 'app/core/base.service';
import { ApiService } from './api.services';
import { Esteira } from '../models/esteira.model';

@Injectable()
export class EsteiraService extends ApiService implements BaseService<Esteira>{
 
    private strAction = 'api/WebEsteiraStatus';
    

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
        }

        getAll(): Observable<Esteira[]> {
            return this.get<Esteira[]>(this.strAction + `/GetAll`);
        }

        deleteById(idEsteira: number) {
            return this.post(this.strAction + `/DeleteById?id=${idEsteira}`);
        }
    
  
    
}