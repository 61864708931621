import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Plano } from '../models/plano.model';
import { Injectable } from '@angular/core';

@Injectable()
export class PlanoService extends ApiService implements BaseService<Plano>{

    private strAction = 'api/Plano';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    deleteById(idPlano: number) {
        return this.post(this.strAction + `/DeleteById?id=${idPlano}`);
    }

    getAll(): Observable<Plano[]> {
        return this.get<Plano[]>(this.strAction + `/GetAll`);
    }


    getById(idPlano: number): Observable<Plano> {
        return this.get(this.strAction + `/GetById/${idPlano}`);
    }

}