import { Processo } from './processo.model';
import { Esteira } from './esteira.model';
import { Pessoa } from './pessoa.model';
import { Plano } from './plano.model';
import { TipoRenda } from './tipoRenda.model';
import { TipoBeneficio } from './tipoBeneficio.model';

export class TermoSaqueBeneficio {
    idTermo: number;
    pcSacado: number;
    pcNovoSaqueBeneficio: number;
    tipoRenda: TipoRenda;
    tipoBeneficio: TipoBeneficio;
    nmProtocolo: number;
    dtOpcao: Date;
    webTipoProcesso: Processo;
    webEsteiraStatus: Esteira;
    pessoa: Pessoa;
    plano: Plano;
}