import { Component } from '@angular/core';

@Component({
    selector: 'tab-ctbvoluntaria',
    templateUrl: './ctbvoluntaria.component.html',
    styleUrls: ['./ctbvoluntaria.component.scss']
})

export class CtbVoluntariaComponent {

}