import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { WebIntegrPerfilADO } from '../models/webIntegrPerfilADO.model';

@Injectable()
export class WebIntegrPerfilADOService extends ApiService implements BaseService<WebIntegrPerfilADO>{

    private strAction = 'api/WebIntegrPerfilADO';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    deleteById(idPerfil: number) {
        return this.post(this.strAction + `/DeleteById?id=${idPerfil}`);
    }

    getAll(): Observable<WebIntegrPerfilADO[]> {
        return this.get<WebIntegrPerfilADO[]>(this.strAction + `/RetornarTodos`);
    }


    getById(idPerfil: number): Observable<WebIntegrPerfilADO> {
        return this.get(this.strAction + `/GetById/${idPerfil}`);
    }

}