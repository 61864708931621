import { Processo } from './processo.model';
import { Esteira } from './esteira.model';
import { Pessoa } from './pessoa.model';
import { Plano } from './plano.model';
import { TipoRenda } from './tipoRenda.model';

export class TermoTipoRenda {
    idTermo: number;
    idPessoa: number;
    nrParcela: number;
    vlPercentual: number;
    tipoRendaOrigem: TipoRenda;
    tipoRendaDestino: TipoRenda;
    vlDestino: number;
    vlOrigem: number;
    nmProtocolo: number;
    dtOpcao: Date;
    webTipoProcesso: Processo;
    webEsteiraStatus: Esteira;
    pessoa: Pessoa;
    plano: Plano;
}