import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificacoesComponent } from './notificacoes.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { RouterModule } from '@angular/router';
import { MatButtonModule, MatFormFieldModule, MatIconModule, MatInputModule, MatSelectModule, MatDatepickerModule, MatCheckboxModule, MatTableModule, MatTabsModule, MatDividerModule, MatListModule, MatChipsModule, MatDialogModule, MatMenuModule, MatRippleModule, MatSidenavModule, MatToolbarModule, MatTooltipModule, MatPaginatorModule, MatNativeDateModule, MatStepperModule, MatSnackBarModule } from '@angular/material';
import { CadastrarComponent } from './cadastrar/cadastrar.component';
import { RegrasComponent } from './regras/regras.component';
import { RegistrosComponent } from './registros/registros.component';
import { FuseConfirmDialogModule, FuseMaterialColorPickerModule } from '@fuse/components';
import { NgxDnDModule } from '@swimlane/ngx-dnd';
import { SharedModule } from './../shared/shared.module';
import { AtualizarComponent } from './atualizar/atualizar.component';

const routes = [
  {
    path: 'notificacoes',
    component: NotificacoesComponent
  },

  {

    path: 'regrasNotificacoes',
    component: RegrasComponent
  },

  {
    path: 'registros',
    component: RegistrosComponent
  },

  {
    path: 'cadastrarNotificacoes',
    component: CadastrarComponent
  },

  
  {
    path: 'atualizarNotificacoes/:id',
    component: AtualizarComponent
  }


];

@NgModule({
  declarations: [NotificacoesComponent, CadastrarComponent,AtualizarComponent, RegrasComponent, RegistrosComponent],
  imports: [
    SharedModule,
    CommonModule,
    FuseSharedModule,
    RouterModule.forChild(routes),
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatTableModule,
    MatTabsModule,
    MatDividerModule,
    MatListModule,
    MatChipsModule,
    MatDialogModule,
    MatMenuModule,
    MatRippleModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTooltipModule,
    NgxDnDModule,
    FuseConfirmDialogModule,
    MatPaginatorModule,
    MatNativeDateModule,
    MatSnackBarModule



  ],
  exports: [NotificacoesComponent]
})
export class NotificacoesModule { }
