import { Processo } from './processo.model';
import { Esteira } from './esteira.model';
import { Pessoa } from './pessoa.model';

export class WebUploadDocumentos {
    idUpload: number;
    pessoa: Pessoa;
    icCpf: string;
    icRg: string;
    icResi: string;
    icBancarios : string;
    icNascimento : string;
    icRgResponsavel : string;
    icCpfResponsavel : string;
    webTipoProcesso: Processo;
    webEsteiraStatus: Esteira;
    dtUpload: Date;
}