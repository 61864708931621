import { Processo } from './processo.model';
import { Esteira } from './esteira.model';
import { Pessoa } from './pessoa.model';
import { Plano } from './plano.model';
import { ConfigPerfil } from './configPerfil.model';

export class TermoPerfil {
    idTermo: number;
    idPessoa: number;
    perfilOrigem: ConfigPerfil;
    perfilDestino: ConfigPerfil;
    nmProtocolo: number;
    dtOpcao: Date;
    webTipoProcesso: Processo;
    webEsteiraStatus: Esteira;
    pessoa: Pessoa;
    plano: Plano;
}