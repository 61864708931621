import { Processo } from './processo.model';
import { Esteira } from './esteira.model';
import { Pessoa } from './pessoa.model';
import { Plano } from './plano.model';

export class TermoRequerimentoAposentadoria {
    idTermo: number;
    idPessoa:number;
    vlTotalBeneficio: number;
    vlSaqueAvista: number;
    vlBeneficioBruto: number;
    idTipoRenda: number;
    qtPrazoRenda: number;
    pcSaqueAvista: number;
    pcRenda: number;
    nmProtocolo: number;
    dtOpcao: Date;
    idProcesso: number;
    idStatus: number;
    webTipoProcesso: Processo;
    webEsteiraStatus: Esteira;
    pessoa: Pessoa;
    plano: Plano;
    idPerfil: number;
}