import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { WebIntegrPerfilFuncADO } from '../models/webIntegrPerfilFuncADO.model';

export class WebIntegrPerfilFuncADOService extends ApiService{

    private strAction = 'api/WebIntegrPerfilFuncADO';
    dialogData: any;

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    RetornarTodos(): Observable<WebIntegrPerfilFuncADO[]> {
        return this.get<WebIntegrPerfilFuncADO[]>(this.strAction + `/RetornarTodos`);
    }

    RetornarPor(idPerfil: number, idPlano: number, idFuncionalidade: number): Observable<WebIntegrPerfilFuncADO[]> {
        return this.get<WebIntegrPerfilFuncADO[]>(this.strAction + `/RetornarPor?pIdPerfil=${idPerfil ? idPerfil : 0}&pIdPlano=${idPlano ? idPlano : 0}&pIdFuncionalidade=${idFuncionalidade ? idFuncionalidade : 0}`);
    }

    Inserir(webIntegrPerfilFuncADO: WebIntegrPerfilFuncADO): Observable<WebIntegrPerfilFuncADO> {
        console.log(webIntegrPerfilFuncADO)
        return this.post<WebIntegrPerfilFuncADO>(this.strAction + `/Inserir`, webIntegrPerfilFuncADO);
    }

    Deletar(idPerfil: number, idFuncionalidade: number, idPlano: number): Observable<WebIntegrPerfilFuncADO> {
        return this.delete<WebIntegrPerfilFuncADO>(this.strAction + `/Deletar?pIdPerfil=${idPerfil}&pIdFuncionalidade=${idFuncionalidade}&pIdPlano=${idPlano}`);
    }

    listInsertData(webIntegrPerfilFuncADO: WebIntegrPerfilFuncADO): void {
        this.dialogData = webIntegrPerfilFuncADO;
    }

    listDeleteData(webIntegrPerfilFuncADO: WebIntegrPerfilFuncADO): void {
        this.dialogData = webIntegrPerfilFuncADO;
    }

    getDialogData() {
        return this.dialogData;
    }


}