import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { TermoResgate } from '../models/termoResgate.model';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { WebEsteiraProcessoFluxo } from '../models/webEsteiraProcessoFluxo.model';

export class WebEsteiraProcessoFluxoService extends ApiService implements BaseService<WebEsteiraProcessoFluxo>{

    private strAction = 'api/WebEsteiraProcessoFluxo';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<WebEsteiraProcessoFluxo[]> {
        return this.get<WebEsteiraProcessoFluxo[]>(this.strAction + `/GetAll`);
    }

    deleteById(WebEsteiraProcessoFluxo: number) {
        return this.post(this.strAction + `/DeleteById?id=${WebEsteiraProcessoFluxo}`);
    }

    GetFluxoByProcesso(idProcesso: number): Observable<WebEsteiraProcessoFluxo[]> {
        return this.get(this.strAction + `/GetFluxoByProcesso?idProcesso=${idProcesso}`);
    }


}