import { Component, OnInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';

export interface item {
  color: string;
  background: string;
  text: string;
  router: string;
  
}

@Component({
  selector: 'app-notificacoes',
  templateUrl: './notificacoes.component.html',
  styleUrls: ['./notificacoes.component.scss'],
  animations   : fuseAnimations
})
export class NotificacoesComponent {

  constructor() { }

  Menus: item[] = [
    {text: 'Cadastrar Notificações', color: '#fff', background: '#1976D2', router: '/cadastrarNotificacoes'},
    {text: 'Acompanhar Notificações', color: '#fff', background: '#1976D2', router: '/regrasNotificacoes'},
  ];

}
