import { Component } from '@angular/core';

@Component({
    selector: 'tab-solicitacaoresgate',
    templateUrl: './solicitacaoresgate.component.html',
    styleUrls: ['./solicitacaoresgate.component.scss']
})

export class SolicitacaoResgateComponent {

}