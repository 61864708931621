import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseService } from 'app/core/base.service';
import { ApiService } from './api.services';

@Injectable()
export class EnvioEmailAdesaoService extends ApiService {

    private strAction = 'api/EnviarEmailAdesao';


    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    enviarEmailAdesao(idPessoa: number) {
        return this.post<any>(`​​​​​${this.strAction}​​​​​/EnviarEmailAdesao`, idPessoa);
    }

}