import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TermoPPECompleto } from '../models/termoPPECompleto.model';

export class TermoPPECompletoService extends ApiService implements BaseService<TermoPPECompleto>{

    private strAction = 'api/WebTempTermoPPECompleto';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<TermoPPECompleto[]> {
        return this.get<TermoPPECompleto[]>(this.strAction + `/GetAll`);
    }

    deleteById(id: number) {
        return this.post(this.strAction + `/DeleteById?id=${id}`);
    }

    getById(id: number): Observable<TermoPPECompleto> {
        return this.get(this.strAction + `/GetById/${id}`);
    }

    getByCpf(cpf : string): Observable<TermoPPECompleto[]> {
        return this.get<TermoPPECompleto[]>(this.strAction + `/GetByCpf?nrCpf=${cpf}`);
    }

    Put(TermoPPECompleto: TermoPPECompleto): Observable<TermoPPECompleto> {
        return this.post<TermoPPECompleto>(this.strAction + `/Put`, TermoPPECompleto);
    }

}