import { Processo } from './processo.model';
import { Esteira } from './esteira.model';
import { Pessoa } from './pessoa.model';
import { Plano } from './plano.model';

export class WebTempTermoPortabilidade {
    idTermo: number;
    nrCnpj: string;
    nmRazaoSocial: string;
    nmPlano: string;
    icPlano: string;
    nrCnpb: string;
    nrBanco: number;
    nmBanco: string;
    nrAgencia : number;
    nrConta : number;
    dtOpcao : Date;
    pessoa: Pessoa;
    nmProtocolo: number;
    webTipoProcesso: Processo;
    webEsteiraStatus: Esteira;
    plano : Plano;
}