import { AreaRestritaModule } from './main/content/arearestrita/arearestrita.module';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule, MatInputModule } from '@angular/material';
import { MatPaginatorModule } from '@angular/material/paginator'
import 'hammerjs';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { fuseConfig } from 'app/fuse-config';
import { MainModule } from 'app/main/main.module';
import { DashboardModule} from 'app/main/content/dashboard/dashboard.module';
import { MenuselectModule } from './main/content/menuselect/menuselect.module';
import { EsteiraModule } from './main/content/esteira/esteira.module';
import { NotfoundModule } from './main/content/notfound/notfound.module';
import { LoginModule } from './login/login.module';
import { DetalhesModule } from './main/content/detalhes/detalhes.module';
import { DialogModule } from './main/content/message/dialog.module';
import { JwtInterceptor } from './main/content/shared/helper/jwt.interceptor';
import { LoginStore } from './main/content/shared/store/login.store';
import { AppComponent } from 'app/app.component';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { MAT_DATE_LOCALE } from '@angular/material';
import { NotificacoesModule } from './main/content/notificacoes/notificacoes.module';
import { AreaInterativaModule } from './main/content/areainterativa/areainterativa.module';
import { InsertDialogComponent } from './main/content/arearestrita/configdashboard/dialogs/insert/insert.dialog.component';
import { EditDialogComponent } from './main/content/arearestrita/configdashboard/dialogs/edit/edit.dialog.component';
import { DeleteDialogComponent } from './main/content/arearestrita/configdashboard/dialogs/delete/delete.dialog.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { EditDialogFornecedorComponent } from './main/content/arearestrita/configfornecedores/dialogs/edit/edit.dialog.component';
import { InsertDialogFornecedorComponent } from './main/content/arearestrita/configfornecedores/dialogs/insert/insert.dialog.component';
import { DeleteDialogFornecedorComponent } from './main/content/arearestrita/configfornecedores/dialogs/delete/delete.dialog.component';
import { DeleteDialogAtasComponent } from './main/content/arearestrita/configatas/dialogs/delete/delete.dialog.component';
import { InsertDialogAtasComponent } from './main/content/arearestrita/configatas/dialogs/insert/insert.dialog.component';
import { EditDialogAtasComponent } from './main/content/arearestrita/configatas/dialogs/edit/edit.dialog.component';
import { DeleteDialogFuncionalidadesComponent } from './main/content/arearestrita/configfuncionalidades/dialogs/delete/delete.dialog.component';
import { InsertDialogFuncionalidadesComponent } from './main/content/arearestrita/configfuncionalidades/dialogs/insert/insert.dialog.component';
import { ModalComponent } from './main/content/arearestrita/notificacaoparticipante/dialogs/modal/modal.dialog.component';

const appRoutes: Routes = [
    {
        path      : ' ',
        redirectTo: 'login'
    }
];

registerLocaleData(localePt);


@NgModule({
    declarations: [
        AppComponent,
        InsertDialogComponent,
        EditDialogComponent,
        DeleteDialogComponent,
        EditDialogFornecedorComponent,
        InsertDialogFornecedorComponent,
        DeleteDialogFornecedorComponent,
        EditDialogAtasComponent,
        InsertDialogAtasComponent,
        DeleteDialogAtasComponent,
        InsertDialogFuncionalidadesComponent,
        DeleteDialogFuncionalidadesComponent,
        ModalComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),
        TranslateModule.forRoot(),

        //Color Picker Module
        ColorPickerModule,


        // Material moment date module
        MatMomentDateModule,

        // Material
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
        MatPaginatorModule,
        MatTableModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        MainModule,
        DashboardModule,
        MenuselectModule,
        EsteiraModule,
        NotfoundModule,
        LoginModule,
        DetalhesModule,
        DialogModule,
        AreaInterativaModule,
        AreaRestritaModule,
        NotificacoesModule,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
        { provide: LOCALE_ID, useValue: 'pt-BR' },
        LoginStore
       ],

    bootstrap   : [
        AppComponent
    ],

    entryComponents: [
        InsertDialogComponent,
        EditDialogComponent,
        DeleteDialogComponent,
        EditDialogFornecedorComponent,
        InsertDialogFornecedorComponent,
        DeleteDialogFornecedorComponent,
        EditDialogAtasComponent,
        InsertDialogAtasComponent,
        DeleteDialogAtasComponent,
        InsertDialogFuncionalidadesComponent,
        DeleteDialogFuncionalidadesComponent,
        ModalComponent
    ]
})
export class AppModule
{
}
