import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material';


import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatFormFieldModule, MatIconModule, MatInputModule, MatSelectModule, MatStepperModule, MatCheckboxModule, MatDialogModule, MatPaginatorModule, MatCardModule, MatTableModule } from '@angular/material';



import { DialogComponent } from './dialog.component' ;

@NgModule({
    declarations: [
        DialogComponent
    ],
    imports: [
        MatDialogModule,
        MatButtonModule
    ],
   
     entryComponents: [
         DialogComponent
     ],
})
export class DialogModule
{
}
