import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TermoSaqueBeneficio } from '../models/termoSaqueBeneficio.model';

export class TermoSaqueBeneficioService extends ApiService implements BaseService<TermoSaqueBeneficio>{

    private strAction = 'api/WebTempSaqueBeneficio';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<TermoSaqueBeneficio[]> {
        return this.get<TermoSaqueBeneficio[]>(this.strAction + `/GetAll`);
    }

    deleteById(id: number) {
        return this.post(this.strAction + `/DeleteById?id=${id}`);
    }

    getById(id: number): Observable<TermoSaqueBeneficio> {
        return this.get(this.strAction + `/GetById/${id}`);
    }

    getByCpf(cpf : string): Observable<TermoSaqueBeneficio[]> {
        return this.get<TermoSaqueBeneficio[]>(this.strAction + `/GetByCpf?nrCpf=${cpf}`);
    }

    Put(TermoRgTributario: TermoSaqueBeneficio): Observable<TermoSaqueBeneficio> {
        return this.post<TermoSaqueBeneficio>(this.strAction + `/Put`, TermoRgTributario);
    }

}