import { ApiService } from './api.services';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ViewModelBuscarSimulacao } from '../models/viewModelBuscarSimulacao.model';
import { PostFiltroSimulacao } from '../models/postFiltroSimulacao'


export class WebSimulacaoService extends ApiService {

    private strAction = 'api/WebSimulacao';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    BuscarSimulacao(PostFiltroSimulacao: PostFiltroSimulacao): Observable<ViewModelBuscarSimulacao[]> {
        return this.post(this.strAction + `/BuscarSimulacao`, PostFiltroSimulacao);
    }
}