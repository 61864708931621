import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { WebRelacaoDocumentos } from '../models/webRelacaoDocumentos.model';

export class WebRelacaoDocumentosService extends ApiService implements BaseService<WebRelacaoDocumentos>{

    private strAction = 'api/WebRelacaoDocumentos';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<WebRelacaoDocumentos[]> {
        return this.get<WebRelacaoDocumentos[]>(this.strAction + `/GetAll`);
    }

    deleteById(idDocumento: number) {
        return this.post(this.strAction + `/DeleteById?id=${idDocumento}`);
    }

    getById(idDocumento: number): Observable<WebRelacaoDocumentos[]> {
        return this.get(this.strAction + `/GetById?id=${idDocumento}`);
    }

}