import { Directive, ElementRef, HostListener, Renderer, Input } from '@angular/core';


@Directive({
    selector: '[apDarkenOnHover]'
})
export class DarkenOnHouverDirective {

    @Input() color: string= '#1563';

    constructor(
        private el: ElementRef,
        private render: Renderer
    ) { }

    @HostListener('mouseover')
    darkenOn() {
        // MANIPULANDO DOM
        // this.el.nativeElement,
        // console.log('darkenOn');
        this.render.setElementStyle(this.el.nativeElement, 'box-shadow', `inset 0 0 150px 150px ${this.color}`);
    }
    @HostListener('mouseleave')
    darkenOff() {
        // console.log('darknOff');
        this.render.setElementStyle(this.el.nativeElement, 'box-shadow', 'inset 0 0 30px 30px transparent');
    }
}