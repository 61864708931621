import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { WebConfigDash } from '../../../../shared/models/webConfigDash.model';
import { WebConfigDashService } from '../../../../shared/services/webConfigDash.service';
import { FormControl, Validators } from '@angular/forms';
import { WebListaAtas } from 'app/main/content/shared/models/webListaAtas.model';
import { WebListaAtasService } from 'app/main/content/shared/services/webListaAtas.service';

@Component({
  selector: 'app-insert.dialog',
  templateUrl: '../../dialogs/insert/insert.dialog.html',
  styleUrls: ['../../dialogs/insert/insert.dialog.scss']
})

export class InsertDialogAtasComponent {
  constructor(public dialogRef: MatDialogRef<InsertDialogAtasComponent>,
              @Inject(MAT_DIALOG_DATA) public data: WebListaAtas,
              public webListaAtasService: WebListaAtasService) { }

  formControl = new FormControl('', [
    Validators.required
    // Validators.email,
  ]);

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' :
      this.formControl.hasError('email') ? 'Not a valid email' :
        '';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public confirmInsert(): void {
    this.data.dtCadastro = new Date(this.data.dtCadastro)
    this.webListaAtasService.listInsertData(this.data);
  }
}

