import { Processo } from './processo.model';
import { Esteira } from './esteira.model';
import { Pessoa } from './pessoa.model';
import { Plano } from './plano.model';

export class TermoUSPerson {
    idTermo: number;
    nmProtocolo: number;
    icAmericano: string;
    icOutroPais: string;
    nmPais: string;
    nrNIF: number;
    dtOpcao: Date;
    webTipoProcesso: Processo;
    webEsteiraStatus: Esteira;
    pessoa: Pessoa;
    plano: Plano;
}