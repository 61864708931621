import { Processo } from './processo.model';
import { Esteira } from './esteira.model';
import { Pessoa } from './pessoa.model';
import { Plano } from './plano.model';

export class TermoAutoPatrocinio {
    idTermo: number;
    idPessoa:number; 
    vlContribuicaoBasica: number;
    vlContribuicaoSuplementar:number;
    vlContribuicaoNormal: number;
    vlContribuicaoTotal: number;
    nmProtocolo: number;
    dtOpcao: Date;
    idProcesso: number;
    idStatus: number;
    webTipoProcesso: Processo;
    webEsteiraStatus: Esteira;
    pessoa: Pessoa; 
    pcContribBasica : number;
    pcContribSuplementar : number;
    plano : Plano;
}