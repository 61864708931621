export class HistPlanoParticipante {
    iD_PESSOA : number;
    iD_PLANO : number;
    dT_INI : Date;
    dT_FIM : Date;
    iD_CATEGORIA : number;
    iD_SITUACAO : number;
    iD_MOT_SITUACAO : number;
    nM_ERRO : string;
    icValido : boolean;
}