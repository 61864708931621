import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TermoRgTributario } from '../models/termoRgTributario.model';

export class TermoRgTributarioService extends ApiService implements BaseService<TermoRgTributario>{

    private strAction = 'api/WebTempTermoRGTributario';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<TermoRgTributario[]> {
        return this.get<TermoRgTributario[]>(this.strAction + `/GetAll`);
    }

    deleteById(id: number) {
        return this.post(this.strAction + `/DeleteById?id=${id}`);
    }

    getById(id: number): Observable<TermoRgTributario> {
        return this.get(this.strAction + `/GetById/${id}`);
    }

    getByCpf(cpf : string): Observable<TermoRgTributario[]> {
        return this.get<TermoRgTributario[]>(this.strAction + `/GetByCpf?nrCpf=${cpf}`);
    }

    Put(TermoRgTributario: TermoRgTributario): Observable<TermoRgTributario> {
        return this.post<TermoRgTributario>(this.strAction + `/Put`, TermoRgTributario);
    }

}