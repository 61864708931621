import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatDividerModule } from '@angular/material/divider';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatButtonModule } from '@angular/material/button';
import {MatRippleModule} from '@angular/material/core';
import { DarkenOnHouverDirectiveModule } from '@fuse/directives/darken-on-houver-directive/darken-on-houver-directive.module';
import { MatIconModule } from '@angular/material/icon';

import { DashboardComponent } from './dashboard.component';

const routes = [
  {
      path     : 'dashboard',
      component: DashboardComponent
  }
];

@NgModule({
  declarations: [DashboardComponent],
  imports: [
    CommonModule,
    FuseSharedModule,
    RouterModule.forChild(routes),
    NgxChartsModule,
    MatDividerModule,
    FuseWidgetModule,
    MatButtonModule,
    MatRippleModule,
    MatIconModule,
    DarkenOnHouverDirectiveModule
  ],
  exports:[DashboardComponent]
})
export class DashboardModule { }
