import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'menu',
        title: 'Opções Menu',
        type: 'item',
        icon: 'dashboard',
        url: '/menu',
    },
    // {
    //     id: 'dashboard',
    //     title: 'Dashboard',
    //     type: 'item',
    //     icon: 'dashboard',
    //     url: '/dashboard'
    // },
    {
        id: 'esteira',
        title: 'Esteira de Validação',
        type: 'item',
        icon: 'assignment',
        url: '/esteira'
    },

    {
        id: 'notificacoes',
        title: 'Central de Notificações',
        type: 'collapsable',
        icon: 'chat',
        children: [
            {
                id: 'regrasNotificacoes',
                title: 'Cadastrar',
                type: 'item',
                url: '/regrasNotificacoes'
            }

        ]
    },

    {
        id: 'restrita',
        title: 'Área Restrita',
        type: 'collapsable',
        icon: 'description',
        children: [
            {
                id: 'paginainicial',
                title: 'Página Inicial',
                type: 'item',
                url: '/paginaInicial'
            },

            {
                id: 'dadosparticipante',
                title: 'Dados do Participante',
                type: 'item',
                url: '/dadosParticipante'
            },

            {
                id: 'edicaodependentes',
                title: 'Edição de Dependentes',
                type: 'item',
                url: '/edicaoDependentes'
            },

            {
                id: 'inclusaodependentes',
                title: 'Inclusão de Dependentes',
                type: 'item',
                url: '/inclusaoDependentes'
            },

            {
                id: 'planoinstituido',
                title: 'Plano Instituído',
                type: 'item',
                url: '/planoInstituido'
            },

            {
                id: 'recadastramento',
                title: 'Recadastramento',
                type: 'item',
                url: '/recadastramento'
            },

            {
                id: 'contribuicao',
                title: 'Contribuição',
                type: 'item',
                url: '/contribuicao'
            },

            {
                id: 'cobranca',
                title: 'Cobrança',
                type: 'item',
                url: '/cobranca'
            },

            {
                id: 'requerimentobeneficio',
                title: 'Requerimento de Benefício',
                type: 'item',
                url: '/requerimentoBeneficio'
            },

            {
                id: 'alteracaobeneficio',
                title: 'Alteração de Benefício',
                type: 'item',
                url: '/alteracaoBeneficio'
            },

            {
                id: 'simuladorbeneficio',
                title: 'Simulador de Benefício',
                type: 'item',
                url: '/simuladorBeneficio'
            },

            {
                id: 'emprestimos',
                title: 'Empréstimos',
                type: 'item',
                url: '/emprestimo'
            },

            {
                id: 'dossieparticipante',
                title: 'Dôssie do Participante',
                type: 'item',
                url: '/dossieParticipante'
            },

            {
                id: 'dashboard',
                title: 'Dashboard',
                type: 'item',
                url: '/configDashboard'
            },

            {
                id: 'atas',
                title: 'Atas',
                type: 'item',
                url: '/configAtas'
            },

            {
                id: 'fornecedores',
                title: 'Fornecedores',
                type: 'item',
                url: '/configFornecedores'
            },

            {
                id: 'funcionalidades',
                title: 'Funcionalidades',
                type: 'item',
                url: '/configFuncionalidades'
            },

            {
                id: 'notificaoparticipante',
                title: 'Envio de Notificação',
                type: 'item',
                url: '/notificacaoParticipante'
            },

            {
                id: 'simulacoes',
                title: 'Simulações',
                type: 'item',
                url: '/simulacoes'
            }

        ]
    },

    {
        id: 'areaInterativa',
        title: 'Área Interativa',
        type: 'collapsable',
        icon: 'description',
        children: [
            {
                id: 'uploaddocumentos',
                title: 'Upload Documentos',
                type: 'item',
                url: '/upload'
            },

            {
                id: 'administrardocumentos',
                title: 'Administrar Documentos',
                type: 'item',
                url: '/documentos'
            },

            {
                id: 'consultardocumentos',
                title: 'Consultar Documentos',
                type: 'item',
                url: '/consultarDocumentos'
            },

            {
                id: 'consultarconvites',
                title: 'Consultar Convites',
                type: 'item',
                url: '/consultarConvites'
            }

        ]
    },


];
