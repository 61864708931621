import { Component, OnInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';

export interface item {
    color: string;
    background: string;
    text: string;
    router: string;
    icon: string;
}

@Component({
    selector: 'areainterativa',
    templateUrl: './areainterativa.component.html',
    styleUrls: ['./areainterativa.component.scss'],
    animations: fuseAnimations
})

export class AreaInterativaComponent {

    // Menus= ['Operacional','Relátorios', 'Controle de Acesso', 'Configurações', 'Área Restrita'];
  
    Menus: item[] = [
        { text: 'Upload Documentos', color: '#232323aa', background: 'transparent', router: '/upload', icon: 'description' },
        { text: 'Administrar Documentos', color: '#232323aa', background: 'transparent', router: '/documentos', icon: 'description' },
        { text: 'Consultar Documentos', color: '#232323aa', background: 'transparent', router: '/consultarDocumentos', icon: 'description' },
        { text: 'Consultar Convites', color: '#232323aa', background: 'transparent', router: '/consultarConvites', icon: 'description' },
    ];
  
    constructor() { }
  
  }