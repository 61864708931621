import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ViewModelFinalizaProcesso } from '../models/ViewModelFinalizaProcesso.model';
import { Filtro } from '../models/filtro.model';
import { ViewModelFiltraProcesso } from '../models/ViewModelFiltraProcesso.model';

export class WebProcessoControleService extends ApiService implements BaseService<ViewModelFinalizaProcesso>{

    private strAction = 'api/WebProcessoControle';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<ViewModelFinalizaProcesso[]> {
        return this.get<ViewModelFinalizaProcesso[]>(this.strAction + `/GetAll`);
    }

    deleteById(TermoResgateId: number) {
        return this.post(this.strAction + `/DeleteById?id=${TermoResgateId}`);
    }

    FinalizaProcesso(ViewModelFinalizaProcesso: ViewModelFinalizaProcesso): Observable<any> {
        return this.post<any>(this.strAction + `/FinalizaProcesso`, ViewModelFinalizaProcesso);
    }

    DeferirProcesso(ViewModelFinalizaProcesso: ViewModelFinalizaProcesso): Observable<any> {
        return this.post<any>(this.strAction + `/DeferirProcesso`, ViewModelFinalizaProcesso);
    }

    DeferirProcessoLista(ViewModelFinalizaProcesso: ViewModelFinalizaProcesso[]): Observable<any> {
        return this.post<any>(this.strAction + `/DeferirProcessoLista`, ViewModelFinalizaProcesso);
    }

    IndeferirProcesso(ViewModelFinalizaProcesso: ViewModelFinalizaProcesso): Observable<any> {
        return this.post<any>(this.strAction + `/IndeferirProcesso`, ViewModelFinalizaProcesso);
    }

    IndeferirProcessoLista(ViewModelFinalizaProcesso: ViewModelFinalizaProcesso[]): Observable<any> {
        return this.post<any>(this.strAction + `/IndeferirProcessoLista`, ViewModelFinalizaProcesso);
    }

    FiltraProcesso(viewModelFiltraProcesso: ViewModelFiltraProcesso): Observable<Filtro[]> {
        return this.post<Filtro[]>(this.strAction + `/FiltraProcesso`, viewModelFiltraProcesso);
    }

    getById(id: number): Observable<ViewModelFinalizaProcesso> {
        return this.get(this.strAction + `/GetById/${id}`);
    }



}