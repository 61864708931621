import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BaseService } from "app/core/base.service";
import { Observable } from "rxjs";
import { HistPlanoParticipanteORM } from "../models/histPlanoParticipanteORM.model";
import { ApiService } from "./api.services";

@Injectable()
export class HistPlanoParticipanteORMService extends ApiService implements BaseService<HistPlanoParticipanteORM>{

    private strAction = 'api/HistPlanoParticipante';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
            super(httpClient, route);
    }

    getAll() {
        return null;
    }

    deleteById(idPerfil: number) {
        return null;
    }

    getById(idPerfil: number){
        return null;
    }
    getCategoria(idPessoa, idPlano): Observable<string> {
        return this.get<string>(this.strAction + `/RetornaCategoria?idPessoa=${idPessoa}&idPlano=${idPlano}`);
    }




}