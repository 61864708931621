import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { TermoAutoPatrocinio } from '../models/termoAutoPatrocinio.model';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ViewModelFiltraProcesso } from '../models/ViewModelFiltraProcesso.model';

export class TermoAutoPatrocinioService extends ApiService implements BaseService<TermoAutoPatrocinio>{

    private strAction = 'api/WebTempTermoAutopatrocinio';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<TermoAutoPatrocinio[]> {
        return this.get<TermoAutoPatrocinio[]>(this.strAction + `/GetAll`);
    }

    deleteById(TermoAutoPatrocinioId: number) {
        return this.post(this.strAction + `/DeleteById?id=${TermoAutoPatrocinioId}`);
    }

    PostFiltroTermoAutopatrocinio(viewModelFiltraProcesso: ViewModelFiltraProcesso): Observable<TermoAutoPatrocinio[]> {
        return this.post<TermoAutoPatrocinio[]>(this.strAction + `/PostFiltroTermoAutopatrocinio`, viewModelFiltraProcesso);
    }

    getById(id: number): Observable<TermoAutoPatrocinio> {
        return this.get(this.strAction + `/GetById/${id}`);
    }

    getByCpf(cpf : string): Observable<TermoAutoPatrocinio[]> {
        return this.get<TermoAutoPatrocinio[]>(this.strAction + `/GetByCpf?nrCpf=${cpf}`);
    }

    Put(TermoAutoPatrocinio: TermoAutoPatrocinio): Observable<TermoAutoPatrocinio> {
        return this.post<TermoAutoPatrocinio>(this.strAction + `/Put`, TermoAutoPatrocinio);
    }


}