import { Component, Input, Output, OnInit } from '@angular/core';
import { WebStatusSeguradora } from 'app/main/content/shared/models/webStatusSeguradora.model';
import { WebStatusSeguradoraService } from 'app/main/content/shared/services/webStatusSeguradora.service';
import { Adesao } from 'app/main/content/shared/models/adesao.model';
import { WebStatusObs } from 'app/main/content/shared/models/webStatusObs.model';
import { WebStatusObsService } from 'app/main/content/shared/services/webStatusObs.service';
import { WebStatusRegistro } from 'app/main/content/shared/models/webStatusRegistro.model';

@Component({
    selector: 'tab-adesao',
    templateUrl: './adesao.component.html',
    styleUrls: ['./adesao.component.scss']
})

export class AdesaoComponent implements OnInit {

    @Input() adesaoSelect: Adesao;
    @Input() dadosCadastrais: boolean;
    @Input() dadosSeguradora: boolean;
    @Input() listaObs: WebStatusObs[];
    @Input() listaStatus: WebStatusRegistro[];
    @Input() dependeSeguradora: boolean;
    @Input() totalDocs: number;
    @Input() docs: number;
    @Input() idPessoa: number;

    webStatusSeguradora: WebStatusSeguradora = new WebStatusSeguradora();
    webStatusObs: WebStatusObs = new WebStatusObs();
    mensagem: string;
    nmUsuario: string;
    mensagemObs: string;


    constructor(
        private webStatusSeguradoraService: WebStatusSeguradoraService,
        private webStatusObsService: WebStatusObsService,
    ) {
    }

    ngOnInit() {

    }

    checkDados(select: boolean) {
        this.dadosSeguradora = select;
        if (this.webStatusSeguradora) {
            this.webStatusSeguradora.idConfigAdesao = this.adesaoSelect.idConfigAdesao;
            this.webStatusSeguradora.idStatus = select ? 2 : 1;
            this.webStatusSeguradoraService.Put(this.webStatusSeguradora).subscribe();
        } else {
            this.webStatusSeguradora.idConfigAdesao = this.adesaoSelect.idConfigAdesao;
            this.webStatusSeguradora.idStatus = select ? 2 : 1;
            this.webStatusSeguradoraService.Post(this.webStatusSeguradora).subscribe();
        }
    }

    alterarDesc() {
        this.webStatusObs.nrCpf = this.adesaoSelect.nrCpf;
        this.webStatusObs.nmUsuario = "TRUST";
        this.webStatusObs.mensagem = this.mensagem;
        this.webStatusObs.dtObs = new Date();
        this.webStatusObsService.Post(this.webStatusObs).subscribe(obs => {
            this.webStatusObsService.getByCpf(this.adesaoSelect.nrCpf).subscribe(obj => { this.listaObs = obj; console.log(obj) })
        });
    }

    handle(index: number) {
        if (index != null) { this.nmUsuario = this.listaObs[index].nmUsuario; this.mensagemObs = this.listaObs[index].mensagem; }
        let modal = document.querySelector('div.modal3');
        modal.classList.toggle('hidden');
    }


}


