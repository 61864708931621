export class WebRegraNotificacao {
    idRegra: number;
    nmRegra: string;
    grupoReceptor: number;
    dtInicio: Date;
    dtFim: Date;
    icSms: string;
    icEmail: string;
    idCategoria: number;
    idSituacao: number;
    nmApi: string;
}