import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PlanoParticipante } from '../models/planoParticipante.model';
import { ViewModelBuscarPessoa } from '../models/viewModelBuscarPessoa.model';
import { PostFiltroRelatorio } from '../models/postFiltroRelatorio'


export class PlanoParticipanteService extends ApiService implements BaseService<PlanoParticipante>{

    private strAction = 'api/PlanoParticipante';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<PlanoParticipante[]> {
        return this.get<PlanoParticipante[]>(this.strAction + `/GetAll`);
    }

    deleteById(idPessoa: number) {
        return this.post(this.strAction + `/DeleteById?id=${idPessoa}`);
    }

    getById(idPessoa: number): Observable<PlanoParticipante> {
        return this.get(this.strAction + `/GetById/${idPessoa}`);
    }

    getPlano(idPessoa : number) : Observable<PlanoParticipante[]> {
        return this.get<PlanoParticipante[]>(this.strAction + `/GetPlano?idPessoa=${idPessoa}`);
    }

    BuscarPessoa(PostFiltroRelatorio: PostFiltroRelatorio): Observable<ViewModelBuscarPessoa[]> {
        return this.post(this.strAction + `/BuscarPessoa`, PostFiltroRelatorio);
    }
}