import { Component, OnInit, OnDestroy } from '@angular/core';
import { WebRegraRegistrosService } from '../../shared/services/webRegraRegistros.service';
import { WebRegraRegistros } from '../../shared/models/webRegraRegistros.model';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-registros',
  templateUrl: './registros.component.html',
  styleUrls: ['./registros.component.scss']
})
export class RegistrosComponent implements OnInit {

  listaRegistros: WebRegraRegistros[] = [];
  descricao: string;
  flag: number;
  searchDate: Date;
  searchDataInicio: Date;
  searchDataFim: Date;
  searchFlag: number;
  mensagem: string;

  constructor(
    private route: ActivatedRoute,
    public _webRegraRegistros: WebRegraRegistrosService,
    private location: Location
  ) {

  }

  ngOnInit() {
    this.route.queryParams.subscribe(obj => {
      this.flag = obj.flag;

      if (this.flag == 1) {
        this.descricao = obj.nmRegra;
        this._webRegraRegistros.getByNome(obj.nmRegra).subscribe(obj => {
          this.listaRegistros = obj;
        })

      } else if (this.flag == 2) {
        this._webRegraRegistros.getByCpf(obj.searchInput).subscribe(obj => {
          this.listaRegistros = obj;
        })
      }
      else if (this.flag == 3) {
        this._webRegraRegistros.getByData(obj.searchInput).subscribe(obj => {
          this.listaRegistros = obj;
        })
      } else if (this.flag == 4) {
        this._webRegraRegistros.getByPeriod(obj.searchInput, obj.searchInput2).subscribe(obj => {
          this.listaRegistros = obj;
        })
      }

    });

  }

  search() {
    if (this.searchFlag == 1) {
      const y = this.searchDate.getFullYear();
      const m = this.searchDate.getMonth() + 1;
      const d = this.searchDate.getDate();
      this._webRegraRegistros.getByNomeAndData(this.descricao, `${y}-${m}-${d}`).subscribe(obj => {
        this.listaRegistros = obj;
      })
    } else if (this.searchFlag == 2) {
      const y = this.searchDataInicio.getFullYear();
      const m = this.searchDataInicio.getMonth() + 1;
      const d = this.searchDataInicio.getDate();

      const y2 = this.searchDataFim.getFullYear();
      const m2 = this.searchDataFim.getMonth() + 1;
      const d2 = this.searchDataFim.getDate();

      this._webRegraRegistros.getByNomeAndPeriod(this.descricao, `${y}-${m}-${d}`, `${y2}-${m2}-${d2}`).subscribe(obj => {
        this.listaRegistros = obj;
      })
    } else if (this.searchFlag == 3) {
      this._webRegraRegistros.getByNome(this.descricao).subscribe(obj => {
        this.listaRegistros = obj;
      })
    } else if (this.searchFlag == 4) {
      const y = this.searchDate.getFullYear();
      const m = this.searchDate.getMonth() + 1;
      const d = this.searchDate.getDate();
      this._webRegraRegistros.getByData(`${y}-${m}-${d}`).subscribe(obj => {
        this.listaRegistros = obj;
      })
    } else if (this.searchFlag == 5) {
      const y = this.searchDataInicio.getFullYear();
      const m = this.searchDataInicio.getMonth() + 1;
      const d = this.searchDataInicio.getDate();

      const y2 = this.searchDataFim.getFullYear();
      const m2 = this.searchDataFim.getMonth() + 1;
      const d2 = this.searchDataFim.getDate();

      this._webRegraRegistros.getByNomeAndPeriod(this.descricao, `${y}-${m}-${d}`, `${y2}-${m2}-${d2}`).subscribe(obj => {
        this.listaRegistros = obj;
      })
    }
  }

  handle(index: number) {
    if (index != null) this.mensagem = this.listaRegistros[index].mensagem;
    let modal = document.querySelector('div.modal');
    modal.classList.toggle('hidden');
  }

  handleGoBack() {
    this.location.back();
  }

  limpar() {
    this.searchDate = undefined;
    this.searchDataInicio = undefined;
    this.searchDataFim = undefined;
  }

}
