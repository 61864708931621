export class Pessoa {
    idPessoa: number;
    idEmp : number;
    nmPessoa: string;
    nrCnpjCpf: string;
    nrRg : string;
    nmEmail : string;
    nmBairro : string;
    nmCidade : string;
    nmEndereco : string;
    nrEndereco : string;
    nmComplemento : string;
    sgUF : string;
    nrCep : string;
    nrMatricula : string;
    icResidExterior : string;
    idEstadoCivil : number;
    icExposta : string;
    icSexo : string;
    nrInscricao: string;
    nrTelefone : string;
    icAtivo : string;
    dtNascimento: Date;
    dtAdmissao : Date;
    dtDemissao : Date;
}