import { Processo } from './processo.model';
import { Esteira } from './esteira.model';

export class WebAlterarCadastroTemp {
    idAlteracaoCadastral: number;
    idEstadoCivil: string;
    icResidExterior : string;
    nrRg: string;
    sgEmissorRg: string;
    sgUfEmissorRg: string;
    dtEmissorRg: Date;
    nmPai: string;
    nmMae: string;
    nmEndereco: string;
    nrEndereco: string;
    nmComplemento: string;
    nrCep: string;
    sgUf: string;
    nmCidade: string;
    nmBairro: string;
    nmEmail: string;
    nmEmail2: string;
    nrTelefone: string;
    nrCelular: string;
    nmBanco: string;
    idConta: number;
    nrAgBanco : string;
    nrContaBanco : string;
    dtAlteracao: Date;
    idPessoa: number;
    webTipoProcesso: Processo;
    webEsteiraStatus: Esteira;
}