import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseService } from 'app/core/base.service';
import { ApiService } from './api.services';
import { EstadoCivil } from '../models/estadoCivil.model';

@Injectable()
export class EstadoCivilService extends ApiService implements BaseService<EstadoCivil>{
 
    private strAction = 'api/EstadoCivil';
    

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
        }

        getAll(): Observable<EstadoCivil[]> {
            return this.get<EstadoCivil[]>(this.strAction + `/GetAll`);
        }

        
        getById(idEstadoCivil: number): Observable<EstadoCivil> {
            return this.get(this.strAction + `/GetById/${idEstadoCivil}`);
        }

        deleteById(idEstadoCivil: number) {
            return this.post(this.strAction + `/DeleteById?id=${idEstadoCivil}`);
        }
    
  
    
}