import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-tema2',
    templateUrl: './tema2.component.html',
    styleUrls: ['./tema2.component.scss']
})
export class Tema2Component {
    public message: string;

    // constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    //             @Inject(MAT_DIALOG_DATA) public data: any) {
    //     this.message = data.message;
    // }

    constructor(
        public dialogRef: MatDialogRef<Tema2Component>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.message = data.message;
    }
}