import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { WebRegraNotificacaoService } from '../../shared/services/webRegraNotificacao.service';
import { WebRegraNotificacao } from '../../shared/models/webRegraNotificacao.model';
import { WebRegra } from '../../shared/models/webRegra.model';
import { WebRegraService } from '../../shared/services/webRegra.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-cadastrar',
  templateUrl: './cadastrar.component.html',
  styleUrls: ['./cadastrar.component.scss']
})
export class CadastrarComponent implements OnInit {

  form: FormGroup;
  submitted = false;
  web = new WebRegraNotificacao();
  listaRegra: WebRegra[] = [];
  nmRegra: string;
  dtInicio: Date;
  dtFim: Date;
  icSms: string;
  icEmail: string;
  nmApi: string;
  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {FormBuilder} _formBuilder
   */

  constructor(
    private _formBuilder: FormBuilder,
    public _webRegraNotificacao: WebRegraNotificacaoService,
    public _webRegra: WebRegraService,
    private _snackBar: MatSnackBar
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  loadRegras() {
    this._webRegra.getAll().subscribe(lista => this.listaRegra = lista);
  }

  ngOnInit() {

    this.form = this._formBuilder.group({
      Nome: [],
      DataInicio: [],
      DataFim: [],
      SMS: [],
      Email: [],
      Regras: []
    });

    this.loadRegras();

  }

  teste() {

    this.web.idRegra = 1;
    this.web.nmRegra = this.nmRegra;
    this.web.grupoReceptor = 1;
    this.web.dtInicio = this.dtInicio;
    this.web.dtFim = this.dtFim;
    this.web.icSms = this.icSms;
    this.web.icEmail = this.icEmail;
    this.web.idSituacao = 1;
    this.web.idCategoria = 1;
    this.web.nmApi = this.nmApi;

    this._webRegraNotificacao.Post(this.web).subscribe(regra => {
      console.log(regra);

      this._snackBar.open('Notificação cadastrada com sucesso!', null, {
        duration: 3000
      });

    });
  }

  limpar() {
    this.nmRegra = '';
    this.dtInicio = undefined;
    this.dtFim = undefined;
    this.icSms = undefined;
    this.icEmail = undefined;
    this.nmApi = undefined;
  }

}
