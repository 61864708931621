import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { WebUploadDocumentosPessoa } from '../models/webUploadDocumentosPessoa.model';
import { WebUploadDocumentosPessoaFiltro } from '../models/webUploadDocumentosPessoaFiltro.model';


export class WebUploadDocumentosPessoaService extends ApiService implements BaseService<WebUploadDocumentosPessoa>{

    private strAction = 'api/WebUploadDocumentosPessoa';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<WebUploadDocumentosPessoa[]> {
        return this.get<WebUploadDocumentosPessoa[]>(this.strAction + `/GetAll`);
    }

    deleteById(idUpload: number) {
        return this.post(this.strAction + `/DeleteById?id=${idUpload}`);
    }

    getById(idUpload: number): Observable<WebUploadDocumentosPessoa[]> {
        return this.get(this.strAction + `/GetById?id=${idUpload}`);
    }

    getByPessoa(idPessoa: number): Observable<WebUploadDocumentosPessoa[]> {
        return this.get<WebUploadDocumentosPessoa[]>(this.strAction + `/GetByPessoa?idPessoa=${idPessoa}`);
    }

    getByCpf(nrCpf: string): Observable<WebUploadDocumentosPessoa[]> {
        return this.get<WebUploadDocumentosPessoa[]>(this.strAction + `/GetByCpf?nrCpf=${nrCpf}`);
    }

    filtrar(webUploadDocumentosPessoaFiltro: WebUploadDocumentosPessoaFiltro) {
        return this.post<WebUploadDocumentosPessoa[]>(this.strAction + `/Filtrar`, webUploadDocumentosPessoaFiltro);
    }
}