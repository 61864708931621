import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';

import { ApiService } from './api.services';
import { Atendente } from '../models/atendente.model';
import { BaseService } from 'app/core/base.service';
import { Area } from '../models/area.model';


@Injectable()
export class AtendenteService extends ApiService implements BaseService<Atendente> {

    private strAction = 'Atendente';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<Atendente[]> {
        return this.get<Atendente[]>(this.strAction + `/GetAll`);
    }

    // getAllByAtendenteArea(areaId: number): Observable<Atendente[]> {
    //     return this.get<Atendente[]>(this.strAction + `/GetAtendenteByArea?areaId=${areaId} `);
    // }

    getById(atendenteId: number): Observable<Atendente> {
        return this.get<Atendente>(this.strAction + `/GetById/${atendenteId}`);
    }

    // getByAtendenteId(atendenteId: number): Observable<Area> {
    //     return this.get<Area>(this.strAction + `/GetByAtendenteId?atendenteId=${atendenteId}`);
    // }

    getByAtendenteLogin(idLogin: string): Observable<Atendente> {
        return this.get<Atendente>(this.strAction + `/GetByAtendenteLogin?idLogin=${idLogin}`);
    }

    deleteById(atendenteId: number) {
        return this.post(this.strAction + `/DeleteById?id=${atendenteId}`);
    }

    // save(atendente: Atendente): Observable<Atendente> {
    //     if (this.util.IsNullOrUndefined(atendente.id) || atendente.id === 0){
    //         // return this.post<Atendente>(this.strAction + `/Post`, JSON.stringify(atendente), { headers: headers });        
    //         return this.post<Atendente>(this.strAction + `/IncluirAtendente`, atendente);        
    //     }        
    //     return this.post<Atendente>(this.strAction + `/Put`, atendente);        
    // }
}