import { Component } from '@angular/core';
import { EnviarEmailCobrancasService } from '../../shared/services/enviarEmailCobrancas.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import validator from 'validator';
@Component({
    selector: 'app-cobranca',
    templateUrl: './cobranca.component.html',
    styleUrls: ['./cobranca.component.scss']
})


export class cobrancaComponent {

    dataVencimento: Date = new Date();

    constructor(private enviarEmailCobrancasService: EnviarEmailCobrancasService, private _snackBar: MatSnackBar) { }

    openSnackBar(message: string) {
        this._snackBar.open(message, null, {
            duration: 2000,
            panelClass: ['mat-toolbar', 'mat-success']
          });
    }

    openSnackBarError(message: string) {
        this._snackBar.open(message, null, {
            duration: 2000,
            panelClass: ['mat-toolbar', 'mat-warn']
          });
    }

    enviar() {

        if (this.dataVencimento == null) {
            this.openSnackBarError("Preencha uma data de vencimento válida!");
            return;
        }

        const data = this.dataVencimento.toISOString().slice(0, 10)

        if (!validator.isDate(data)) {
            this.openSnackBarError("Preencha uma data de vencimento válida!");
            return;
        }

        this.enviarEmailCobrancasService.enviarCobrancas(data).subscribe(x => this.openSnackBar("Boletos enviados com sucesso!"), error => console.log(error));
    }
}