import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TermoUSPerson } from '../models/termoUSPerson.model';

export class TermoUSPersonService extends ApiService implements BaseService<TermoUSPerson>{

    private strAction = 'api/WebTempTermoUSPerson';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<TermoUSPerson[]> {
        return this.get<TermoUSPerson[]>(this.strAction + `/GetAll`);
    }

    deleteById(id: number) {
        return this.post(this.strAction + `/DeleteById?id=${id}`);
    }

    getById(id: number): Observable<TermoUSPerson> {
        return this.get(this.strAction + `/GetById/${id}`);
    }

    getByCpf(cpf : string): Observable<TermoUSPerson[]> {
        return this.get<TermoUSPerson[]>(this.strAction + `/GetByCpf?nrCpf=${cpf}`);
    }

    Put(TermoUSPerson: TermoUSPerson): Observable<TermoUSPerson> {
        return this.post<TermoUSPerson>(this.strAction + `/Put`, TermoUSPerson);
    }

}