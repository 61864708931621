import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { WebControleAdesao } from '../models/webControleAdesao.model'

@Injectable()
export class WebControleAdesaoService extends ApiService {

    private strAction = 'api/WebControleAdesao';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }


    Post(WebControleAdesao: WebControleAdesao): Observable<WebControleAdesao> {
        return this.post<WebControleAdesao>(this.strAction + `/GetByIdPessoa`, WebControleAdesao);
    }

}