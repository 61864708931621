import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseService } from 'app/core/base.service';
import { ApiService } from './api.services';
import { Empresa } from '../models/empresa.model';

@Injectable()
export class EmpresaService extends ApiService implements BaseService<Empresa>{
 
    private strAction = 'api/Empresa';
    

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
        }

        getAll(): Observable<Empresa[]> {
            return this.get<Empresa[]>(this.strAction + `/GetAll`);
        }

        
        getById(idEmp: number): Observable<Empresa> {
            return this.get(this.strAction + `/GetById/${idEmp}`);
        }

        deleteById(ídEmp: number) {
            return this.post(this.strAction + `/DeleteById?id=${ídEmp}`);
        }
    
  
    
}