import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PlanoParticipanteADO } from '../models/planoParticipanteADO.model';


export class PlanoParticipanteADOService extends ApiService implements BaseService<PlanoParticipanteADO>{

    private strAction = 'api/PlanoParticipanteADO';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<PlanoParticipanteADO[]> {
        return this.get<PlanoParticipanteADO[]>(this.strAction + `/GetAll`);
    }

    deleteById(idPessoa: number) {
        return this.post(this.strAction + `/DeleteById?id=${idPessoa}`);
    }

    getById(idPessoa: number): Observable<PlanoParticipanteADO> {
        return this.get(this.strAction + `/GetById?id=${idPessoa}`);
    }


    getPlano(idPessoa : number): Observable<PlanoParticipanteADO[]> {
        return this.get<PlanoParticipanteADO[]>(this.strAction + `/GetPlano?idPessoa=${idPessoa}`);
    }

    Post(planoParticipante: PlanoParticipanteADO): Observable<PlanoParticipanteADO> {
        return this.post<PlanoParticipanteADO>(this.strAction + `/PostInserePlanoParticipante`, planoParticipante);
    }

}