import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Finalidade } from '../models/finalidade.model';

@Injectable()
export class FinalidadeService extends ApiService implements BaseService<Finalidade>{

    private strAction = 'api/Finalidade';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

   deleteById(idFinalidade: number) {
        return this.post(this.strAction + `/DeleteById?id=${idFinalidade}`);
    }

    getAll(): Observable<Finalidade[]> {
        return this.get<Finalidade[]>(this.strAction + `/GetAll`);
    }


    getById(idFinalidade: number): Observable<Finalidade> {
        return this.get(this.strAction + `/GetById/${idFinalidade}`);
    }


}