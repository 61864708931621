import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { WebRegra } from '../models/webRegra.model';


export class WebRegraService extends ApiService implements BaseService<WebRegra>{
    
    private strAction = 'api/WebRegra';
    
    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
        }

        getAll(): Observable<WebRegra[]> {
            return this.get<WebRegra[]>(this.strAction + `/GetAll`);
        }

        deleteById(idRegra: number) {
            return this.post(this.strAction + `/DeleteById?id=${idRegra}`);
        }
    
        Post(WebRegra: WebRegra ): Observable<WebRegra[]>{
            return this.post<WebRegra[]>(this.strAction + `/Post`, WebRegra);
        }

}