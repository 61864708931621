import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { WebRegraNotificacaoService } from '../../shared/services/webRegraNotificacao.service';
import { WebRegraNotificacao } from '../../shared/models/webRegraNotificacao.model';
import { WebRegra } from '../../shared/models/webRegra.model';
import { WebRegraService } from '../../shared/services/webRegra.service';
import { ActivatedRoute } from '@angular/router';
import { PlanoSituacao } from '../../shared/models/planoSituacao.model';
import { PlanoCategoria } from '../../shared/models/planoCategoria.model';
import { PlanoCategoriaService } from '../../shared/services/planoCategoria.service';
import { PlanoSituacaoService } from '../../shared/services/planoSituacao.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-atualizar',
  templateUrl: './atualizar.component.html',
  styleUrls: ['./atualizar.component.scss']
})
export class AtualizarComponent implements OnInit {

  form: FormGroup;
  submitted = false;
  web = new WebRegraNotificacao();
  listaRegra: WebRegra[] = [];

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {FormBuilder} _formBuilder
   */


  notificacao: WebRegraNotificacao = new WebRegraNotificacao();

  listaCategorias: PlanoCategoria[] = [];
  listaSituacoes: PlanoSituacao[] = [];
  dtInicio: FormControl;
  dtFim : FormControl
  
  constructor(
    private _formBuilder: FormBuilder,
    public _webRegraNotificacao: WebRegraNotificacaoService,
    public _webRegra: WebRegraService,
    private planoCategoriaService: PlanoCategoriaService,
    private planoSituacaoService: PlanoSituacaoService,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  loadRegras() {
    this._webRegra.getAll().subscribe(lista => this.listaRegra = lista);
  }

  ngOnInit() {


    this.form = this._formBuilder.group({
      Nome: [],
      DataInicio: [],
      DataFim: [],
      SMS: [],
      Email: [],
      Regras: [],
      GrupoReceptor: [],
      Categoria: [],
      Situacao: []
    });

    this.loadRegras();

    this.planoCategoriaService.getAll().subscribe(obj => { console.log(obj); this.listaCategorias = obj });
    this.planoSituacaoService.getAll().subscribe(obj => { console.log(obj); this.listaSituacoes = obj });

    this.route.params.subscribe(obj => {
      this._webRegraNotificacao.getById(obj.id).subscribe(obj => {
        this.notificacao = obj; 
        this.dtInicio = new FormControl(obj.dtInicio);
        this.dtFim = new FormControl(obj.dtFim);
      });
    });


  }

  atualizar() {


    this._webRegraNotificacao.Put(this.notificacao).subscribe(notificacao => {

      this._snackBar.open("Notificação atualizada com sucesso!", null, {
        duration: 3000
      });

    }, error => {

      this._snackBar.open("Error ao atualizar a notificação!", null, {
        duration: 3000
      });

    });

  }






}
