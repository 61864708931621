import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PerfilPlanoParticipante } from '../models/perfilPlanoParticipante.model';

export class PerfilPlanoParticipanteService extends ApiService implements BaseService<PerfilPlanoParticipante>{

    private strAction = 'api/PerfilPlanoParticipanteADO';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<PerfilPlanoParticipante[]> {
        return this.get<PerfilPlanoParticipante[]>(this.strAction + `/GetAll`);
    }

    deleteById(idPessoa: number) {
        return this.post(this.strAction + `/DeleteById?id=${idPessoa}`);
    }

    getById(idPessoa: number): Observable<PerfilPlanoParticipante> {
        return this.get(this.strAction + `/GetById?id=${idPessoa}`);
    }

    Post(perfilPlanoParticipante: PerfilPlanoParticipante): Observable<PerfilPlanoParticipante> {
        return this.post<PerfilPlanoParticipante>(this.strAction + `/PostInsereperfilPlanoParticipante`, perfilPlanoParticipante);
    }

}