import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PessoaADO } from '../models/pessoaAdo.model';

export class PessoaADOService extends ApiService implements BaseService<PessoaADO>{

    private strAction = 'api/PessoaADO';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<PessoaADO[]> {
        return this.get<PessoaADO[]>(this.strAction + `/GetAll`);
    }

    deleteById(idPessoa: number) {
        return this.post(this.strAction + `/DeleteById?id=${idPessoa}`);
    }

    getById(idPessoa: number): Observable<PessoaADO> {
        return this.get(this.strAction + `/GetById?id=${idPessoa}`);
    }


    getIdPessoa(nrCpf: string): Observable<number> {
        return this.get(this.strAction + `/GetIdPessoaWithPlanoByCpf?nrCpf=${nrCpf}`);
    }

    Put(pessoaADO: PessoaADO): Observable<PessoaADO> {
        return this.put<PessoaADO>(this.strAction + `/DeferirPessoa`, pessoaADO);
    }

    GerarInscricao(pessoaADO: PessoaADO): Observable<PessoaADO> {
        return this.put<PessoaADO>(this.strAction + `/GerarInscricao`, pessoaADO);
    }

}