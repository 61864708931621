import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

import { PlatformDetectorService } from 'app/main/content/shared/platform-detector/platform-detector.service';
import { AuthenticationService } from 'app/main/content/shared/services/authentication.service';
import { LoginService } from 'app/main/content/shared/services/login.service';
import { LoginStore } from 'app/main/content/shared/store/login.store';
import { AtendenteService } from 'app/main/content/shared/services/atendente.service';
import { AtendenteStore } from 'app/main/content/shared/store/atendente.store';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  hide = true;
  loginForm: FormGroup;
  submitted = false;
  lembrar: boolean = false;
  @Output() exibeApp = new EventEmitter<boolean>();
  @ViewChild('userNameInput', { static: false }) userNameInput: ElementRef<HTMLInputElement>;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private platformDetectorService: PlatformDetectorService,
    private login: LoginService,
    private loginStore: LoginStore,
    private atendenteService: AtendenteService,
    private atendenteStore: AtendenteStore

  ) {
    this.login.logout();
  }

  /**
   * On init
   */
  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    let loginAccount = JSON.parse(localStorage.getItem('loginAccount'));
    console.log(loginAccount)
    if (loginAccount) {
      this.f.username.setValue(loginAccount.username);
      this.f.password.setValue(loginAccount.password);
      this.lembrar = loginAccount.lembrar;
    }
  }

  get f() {
    return this.loginForm.controls;
  }

  onCheck() {
    if (!this.lembrar)
      this.lembrar = true;
    else
      this.lembrar = false;
      console.log(this.lembrar)
  }

  onSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    if (this.lembrar == true) {
      localStorage.setItem("loginAccount", JSON.stringify({
        username: this.f.username.value,
        password: this.f.password.value,
        lembrar: this.lembrar
      }));
      console.log('lembrou')
    } else {
      localStorage.setItem("loginAccount", undefined)
      console.log('não lembrou')
    }

    this.login
      .autenticar(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          this.login.getById(this.f.username.value).subscribe(login => {
            this.loginStore.update(login);
          });

          //   this.atendenteService.getByAtendenteLogin(this.f.username.value)
          //   .subscribe ( atendente => {
          //     this.atendenteStore.update(atendente);
          //   });

          this.exibeApp.emit(true),
            this.router.navigate(['menu'])
        },
        err => {
          this.loginForm.reset();
          this.platformDetectorService.isPlatformBrowser() &&
            this.userNameInput.nativeElement.focus();
        }
      );
  }

}
