import { Plano } from './plano.model';

export class WebDocumentosRestrito {
    idDocumento: number;
    nmDocumento: string;
    descricaoDocumento : string;
    tipoParticipante: string;
    cpfParticipante: string;
    idCategoria: number;
    idSituacao: number;
    //idPlano: number;
    plano : Plano;
    dtDocumento : Date;
}