import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { WebRegraNotificacaoService } from '../../shared/services/webRegraNotificacao.service';
import { WebRegraNotificacao } from '../../shared/models/webRegraNotificacao.model';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-regras',
    templateUrl: './regras.component.html',
    styleUrls: ['./regras.component.scss']
})
export class RegrasComponent implements OnInit, AfterViewInit {

    form: FormGroup;
    statusFiltro = false;
    filtroClick = false;
    listaNotificacao: WebRegraNotificacao[] = [];
    searchDate: Date;
    searchDataInicio: Date;
    searchDataFim: Date;
    searchInput: string;
    searchInput2: string;
    searchFlag: number;

    constructor(
        private readonly router: Router,
        private cd: ChangeDetectorRef,
        public _webRegraNotificacao: WebRegraNotificacaoService
    ) {

    }

    ngOnInit() {
        this.searchFlag = 2;
    }

    loadNotificacao() {
        this._webRegraNotificacao.getAll().subscribe(webRegraNotificacao => {
            this.listaNotificacao = webRegraNotificacao;
        })
    }
    alterarNotificacao(notificacao: WebRegraNotificacao) {
        this._webRegraNotificacao.Put(notificacao);
    }

    atualizar(id : number) {
        this.router.navigate(["/atualizarNotificacoes", id], { skipLocationChange: true });
    }

    deleteNotificacao(id: number) {
        const confirmation = confirm('Deseja REALMENTE excluir essa notificação?');
        if (confirmation) {
            this._webRegraNotificacao.deleteById(id).subscribe(obj =>
                this.listaNotificacao.map((notificacao, index) => {
                    if (notificacao.idRegra == id) {
                        this.listaNotificacao.splice(index, 1);
                    }
                }));
        }
    }
    ngAfterViewInit() {
        if (!this.filtroClick) {
            this.clickFiltro();
            this.cd.detectChanges();
        }
    }
    clickFiltro() {
        this.statusFiltro = !this.statusFiltro;
    }

    searchRegistro(nmRegra: string) {
        this.router.navigate(['registros'], { queryParams: { nmRegra, flag: 1 } });
    }

    search() {

        if (this.searchFlag === 2) {
            this.router.navigate(['registros'], { queryParams: { searchInput: this.searchInput, flag: this.searchFlag } });
        } else if (this.searchFlag === 3) {
            const y = this.searchDate.getFullYear();
            const m = this.searchDate.getMonth() + 1;
            const d = this.searchDate.getDate();
            this.searchInput = `${y}-${m}-${d}`;
            this.router.navigate(['registros'], { queryParams: { searchInput: this.searchInput, flag: this.searchFlag } });
        } else if (this.searchFlag === 4) {
            const y = this.searchDataInicio.getFullYear();
            const m = this.searchDataInicio.getMonth() + 1;
            const d = this.searchDataInicio.getDate();

            const y2 = this.searchDataFim.getFullYear();
            const m2 = this.searchDataFim.getMonth() + 1;
            const d2 = this.searchDataFim.getDate();

            this.searchInput = `${y}-${m}-${d}`;
            this.searchInput2 = `${y2}-${m2}-${d2}`;

            this.router.navigate(['registros'], { queryParams: { searchInput: this.searchInput, searchInput2: this.searchInput2, flag: this.searchFlag } });
        } else if (this.searchFlag === 5) {
            this.loadNotificacao();
            this.clickFiltro();
        }

    }

    limpar() {
        this.searchInput = '';
        this.searchDate = undefined;
        this.searchDataInicio = undefined;
        this.searchDataFim = undefined;
    }

}
