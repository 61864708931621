import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TermoSaqueBeneficio } from '../models/termoSaqueBeneficio.model';
import { TermoEstadoCivil } from '../models/termoEstadoCivil.model';
import { TermoIsencaoIR } from '../models/termoIsencaoIR.model';

export class TermoIsencaoIRService extends ApiService implements BaseService<TermoIsencaoIR>{

    private strAction = 'api/WebTempTermoIsencaoIR';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<TermoIsencaoIR[]> {
        return this.get<TermoIsencaoIR[]>(this.strAction + `/GetAll`);
    }

    deleteById(id: number) {
        return this.post(this.strAction + `/DeleteById?id=${id}`);
    }

    getById(id: number): Observable<TermoIsencaoIR> {
        return this.get(this.strAction + `/GetById/${id}`);
    }

    getByCpf(cpf : string): Observable<TermoIsencaoIR[]> {
        return this.get<TermoIsencaoIR[]>(this.strAction + `/GetByCpf?nrCpf=${cpf}`);
    }

    Put(TermoIsencaoIR: TermoIsencaoIR): Observable<TermoIsencaoIR> {
        return this.post<TermoIsencaoIR>(this.strAction + `/Put`, TermoIsencaoIR);
    }

}