import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { TermoResgate } from '../models/termoResgate.model';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ViewModelFiltraProcesso } from '../models/ViewModelFiltraProcesso.model';

export class TermoResgateService extends ApiService implements BaseService<TermoResgate>{

    private strAction = 'api/WebTempTermoResgate';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<TermoResgate[]> {
        return this.get<TermoResgate[]>(this.strAction + `/GetAll`);
    }

    deleteById(TermoResgateId: number) {
        return this.post(this.strAction + `/DeleteById?id=${TermoResgateId}`);
    }

    PostFiltroTermoResgate(viewModelFiltraProcesso: ViewModelFiltraProcesso): Observable<TermoResgate[]> {
        return this.post<TermoResgate[]>(this.strAction + `/PostFiltroTermoResgate`, viewModelFiltraProcesso);
    }


    getById(id: number): Observable<TermoResgate> {
        return this.get(this.strAction + `/GetById/${id}`);
    }

    getByCpf(cpf : string): Observable<TermoResgate[]> {
        return this.get<TermoResgate[]>(this.strAction + `/GetByCpf?nrCpf=${cpf}`);
    }

    Put(TermoResgate: TermoResgate): Observable<TermoResgate> {
        return this.post<TermoResgate>(this.strAction + `/Put`, TermoResgate);
    }


}