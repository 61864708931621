import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { WebTempTermoPortabilidade } from '../models/webTempTermoPortabilidade.model';
import { ViewModelFiltraProcesso } from '../models/ViewModelFiltraProcesso.model';

export class WebTempTermoPortabilidadeService extends ApiService implements BaseService<WebTempTermoPortabilidade>{

    private strAction = 'api/WebTempTermoPortabilidade';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<WebTempTermoPortabilidade[]> {
        return this.get<WebTempTermoPortabilidade[]>(this.strAction + `/GetAll`);
    }

    deleteById(idTermo: number) {
        return this.post(this.strAction + `/DeleteById?id=${idTermo}`);
    }

    Post(WebTempTermoPortabilidade: WebTempTermoPortabilidade): Observable<WebTempTermoPortabilidade[]> {
        return this.post<WebTempTermoPortabilidade[]>(this.strAction + `/Criar`, WebTempTermoPortabilidade);
    }

    getById(id: number): Observable<WebTempTermoPortabilidade> {
        return this.get<WebTempTermoPortabilidade>(this.strAction + `/GetById/${id}`);
    }

    
    PostFiltroTermoPortabilidade(viewModelFiltraProcesso: ViewModelFiltraProcesso): Observable<WebTempTermoPortabilidade[]> {
        return this.post<WebTempTermoPortabilidade[]>(this.strAction + `/PostFiltroTermoPortabilidade`, viewModelFiltraProcesso);
    }

    
    getByCpf(cpf : string): Observable<WebTempTermoPortabilidade[]> {
        return this.get<WebTempTermoPortabilidade[]>(this.strAction + `/GetByCpf?nrCpf=${cpf}`);
    }

    Put(WebTempTermoPortabilidade: WebTempTermoPortabilidade): Observable<WebTempTermoPortabilidade> {
        return this.post<WebTempTermoPortabilidade>(this.strAction + `/Put`, WebTempTermoPortabilidade);
    }



}