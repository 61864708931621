import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DadosBancoADO } from '../models/dadosBancoADO.model';

export class DadosBancoADOService extends ApiService implements BaseService<DadosBancoADO>{

    private strAction = 'api/DadosBancoADO';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<DadosBancoADO[]> {
        return this.get<DadosBancoADO[]>(this.strAction + `/GetAll`);
    }

    deleteById(idPessoa: number) {
        return this.post(this.strAction + `/DeleteById?id=${idPessoa}`);
    }

    getById(idPessoa: number): Observable<DadosBancoADO> {
        return this.get(this.strAction + `/GetById?id=${idPessoa}`);
    }

    Post(dadosBancariosADO: DadosBancoADO): Observable<DadosBancoADO> {
        return this.post<DadosBancoADO>(this.strAction + `/PostInsereDadosBancarios`, dadosBancariosADO);
    }

    getByAg(Ag: string): Observable<DadosBancoADO> {
        return this.get(this.strAction + `/GetBancoByAg?Ag=${Ag}`);
    }


}