export class DadosBancariosADO {
    iD_PESSOA: number;
    iD_TIPO_CONTA: number;
    nM_BANCO : string;
    nR_BANCO: string;
    nR_AG_BANCO: string;
    nR_CONTA_BANCO: string;
    iC_PADRAO: string;
    iC_ATIVO: string;
    nM_ERRO: string;
    iC_VALIDO: string;
}