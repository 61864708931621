import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { Router } from '@angular/router';

import { EsteiraService } from './../shared/services/esteira.service';
import { Processo } from '../shared/models/processo.model';
import { ProcessoService } from './../shared/services/processo.service';
import { Esteira } from '../shared/models/esteira.model';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Filtro } from '../shared/models/filtro.model';
import { Plano } from '../shared/models/plano.model';
import { PlanoService } from '../shared/services/plano.service';
import { environment } from 'environments/environment';
import { WebProcessoControleService } from '../shared/services/webProcessoControle.service';
import { ViewModelFinalizaProcesso } from '../shared/models/ViewModelFinalizaProcesso.model';
import { ViewModelFiltraProcesso } from '../shared/models/ViewModelFiltraProcesso.model';

@Component({
    selector: 'app-esteira',
    templateUrl: './esteira.component.html',
    styleUrls: ['./esteira.component.scss']
})

export default class EsteiraComponent implements OnInit {


    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    isDateFormat: boolean = environment.isDateFormat;
    lista_Processo: Processo[] = [];
    lista_Esteira: Esteira[] = [];
    lista_Plano: Plano[] = [];
    displayedColumns: string[] = ['select', 'processo', 'cpf', 'nome', 'data', 'situacao', 'plano', 'acoes'];
    selection = new SelectionModel<any>(true, []);
    dataSource = new MatTableDataSource<any>();

    selectedProcesso: number;
    selectedSituacao: number;
    selectedPlano: number;
    selectedOrderBy: number;

    dataInicio: Date;
    dataFim: Date;

    PostFiltro: ViewModelFiltraProcesso = new ViewModelFiltraProcesso();

    cpf: string = '';

    constructor(
        private readonly router: Router,
        private processoService: ProcessoService,
        private esteiraService: EsteiraService,
        private planoService: PlanoService,
        private webProcessoControleService: WebProcessoControleService,
        private spinnerService: Ng4LoadingSpinnerService,
    ) {


    }



    ngOnInit() {

        this.dataSource.paginator = this.paginator;

        this.loadPlano();
        this.loadProcesso();
        this.loadEsteira();

        let obj = JSON.parse(localStorage.getItem('search'));

        if (obj != null) {
            this.selectedProcesso = obj.selectedProcesso;
            this.selectedSituacao = obj.selectedSituacao;
            this.selectedPlano = obj.selectedPlano;
            this.dataInicio = obj.dataInicio;
            this.dataFim = obj.dataFim;
            this.selectedOrderBy = obj.selectedOrderBy;
            // this.cpf = obj.cpf;
            // this.applyFilter(obj.cpf);
            this.filtroBuscaAdesao()
        }

    }






    totalPendencia(element: Filtro) {

        if (element.status == 8) return 0;
        if (element.status == 0 || element.status >= 0 && element.status <= 4) return 1;
        if (element.status >= 5 && element.status <= 7) return 2;

        return 0;
    }

    clear() {
        this.selectedProcesso = undefined;
        this.selectedSituacao = undefined;
        this.selectedPlano = undefined;
        this.dataInicio = undefined;
        this.dataFim = undefined;
        //this.selectedOrderBy = undefined;
    }


    filtroBuscaAdesao() {
        // FILTRO ADESÃO

        this.PostFiltro.idProcesso = this.selectedProcesso;
        this.PostFiltro.idStatus = this.selectedSituacao;
        this.PostFiltro.idPlano = this.selectedPlano;
        this.PostFiltro.dtInicio = this.dataInicio;
        this.PostFiltro.dtFim = this.dataFim;
        // this.PostFiltro.selectedOrderBy = this.selectedOrderBy;

        this.cpf = undefined;

        this.spinnerService.show();

        this.webProcessoControleService.FiltraProcesso(this.PostFiltro).subscribe(solicitacoes => {

            console.log(solicitacoes)

            this.spinnerService.hide();
            this.dataSource = new MatTableDataSource<Filtro>(solicitacoes);
            this.dataSource.paginator = this.paginator;

        }, error => { this.spinnerService.hide(); });

        // switch (this.selectedProcesso) {
        //     // Adesao
        //     case 1:
        //         this.adesaoService.PostFitroPlanoAdesao(this.PostFiltro).subscribe(dados => {
        //             if (dados) {

        //                 if (dados.length) {
        //                     dados.map(adesao => {
        //                         this.listaFiltro.push({
        //                             id: adesao.idConfigAdesao,
        //                             nomeProcesso: adesao.webTipoProcesso.nmProcesso,
        //                             nrCpf: adesao.nrCpf,
        //                             nomePessoa: adesao.nmPessoa,
        //                             dtSolicitacao: adesao.dtCadastro,
        //                             nomeSituacao: adesao.webEsteiraStatus.nmStatus,
        //                             idProcesso: adesao.webTipoProcesso.idProcesso,
        //                             status: 0,
        //                             plano: this.lista_Plano.find(x => x.idPlano == adesao.idPlano),
        //                             check: false
        //                         })
        //                     })


        //                     this.spinnerService.hide();
        //                     this.dataSource = new MatTableDataSource<Filtro>(this.listaFiltro);
        //                     this.dataSource.paginator = this.paginator;

        //                     this.dataSource.filteredData.map((adesao: Filtro) => {
        //                         this.pessoa.getByCpf(adesao.nrCpf).subscribe(obj => {

        //                             if (obj != null) {

        //                                 this.webControleAdesao = new WebControleAdesao();
        //                                 this.webControleAdesao.idPessoa = obj.idPessoa;

        //                                 this.webControleAdesaoService.Post(this.webControleAdesao).subscribe(obj => {
        //                                     if (obj) {
        //                                         console.log(obj);

        //                                         if (obj.qualificacao == "S")
        //                                             adesao.status++;
        //                                         if (obj.endereco == "S")
        //                                             adesao.status++;
        //                                         if (obj.plano == "S")
        //                                             adesao.status++;
        //                                         if (obj.dps == "S")
        //                                             adesao.status++;
        //                                         if (obj.beneficiario == "S")
        //                                             adesao.status++;
        //                                         if (obj.ppe == "S")
        //                                             adesao.status++;
        //                                         if (obj.tributacao == "S")
        //                                             adesao.status++;
        //                                         if (obj.documento == "S")
        //                                             adesao.status++;

        //                                         console.log(adesao.status);
        //                                     }
        //                                 })
        //                             }
        //                         })
        //                     })
        //                 }

        //             }
        //         }, error => this.spinnerService.hide())

        //         break;
        //     // Concessão
        //     case 2:
        //         this.termoRequerimentoAposentadoriaService.PostFiltroTermoRequerimentoAposentadoria(this.PostFiltro).subscribe(dados => {
        //             if (dados) {
        //                 if (dados.length) {

        //                     console.log(dados);

        //                     dados.map(aposentadoria => {
        //                         this.listaFiltro.push({
        //                             id: aposentadoria.idTermo,
        //                             nomeProcesso: aposentadoria.webTipoProcesso.nmProcesso,
        //                             nrCpf: aposentadoria.pessoa.nrCnpjCpf,
        //                             nomePessoa: aposentadoria.pessoa.nmPessoa,
        //                             dtSolicitacao: aposentadoria.dtOpcao,
        //                             nomeSituacao: aposentadoria.webEsteiraStatus.nmStatus,
        //                             idProcesso: aposentadoria.webTipoProcesso.idProcesso,
        //                             status: 0,
        //                             plano: aposentadoria.plano,
        //                             check: false
        //                         })
        //                     })

        //                 }
        //                 this.spinnerService.hide();
        //                 this.dataSource = new MatTableDataSource<Filtro>(this.listaFiltro);
        //                 this.dataSource.paginator = this.paginator;
        //             }
        //         }, error => this.spinnerService.hide());
        //         break;
        //     //Dependentes
        //     case 3:

        //         this.webTempDependenteService.PostFiltroWebTempDependente(this.PostFiltro).subscribe(dados => {
        //             if (dados) {
        //                 if (dados.length) {
        //                     dados.map(dependente => {
        //                         this.listaFiltro.push({
        //                             id: dependente.idPessoaDep,
        //                             nomeProcesso: dependente.webTipoProcesso.nmProcesso,
        //                             nrCpf: dependente.pessoa.nrCnpjCpf,
        //                             nomePessoa: dependente.pessoa.nmPessoa,
        //                             dtSolicitacao: dependente.dtIni,
        //                             nomeSituacao: dependente.webEsteiraStatus.nmStatus,
        //                             idProcesso: dependente.webTipoProcesso.idProcesso,
        //                             status: 0,
        //                             plano: dependente.plano,
        //                             check: false
        //                         })
        //                     })


        //                     this.spinnerService.hide();
        //                     this.dataSource = new MatTableDataSource<Filtro>(this.listaFiltro);
        //                     this.dataSource.paginator = this.paginator;
        //                 }
        //             }
        //         }, error => this.spinnerService.hide());

        //         break;
        //     //Dados Cadastrais
        //     case 4:
        //         // this.webPlanoDadosCadastraisService.PostFiltroWebPlanoDadosCadastrais(this.PostFiltro).subscribe(dados => {

        //         //     dados.map(cadastrais => {
        //         //         this.listaFiltro.push({
        //         //             id: cadastrais.idAlteracaoCadastral,
        //         //             nomeProcesso: cadastrais.webTipoProcesso.nmProcesso,
        //         //             nrCpf: cadastrais.pessoa.nrCnpjCpf,
        //         //             nomePessoa: cadastrais.pessoa.nmPessoa,
        //         //             dtSolicitacao: cadastrais.dtAlteracao,
        //         //             nomeSituacao: cadastrais.webEsteiraStatus.nmStatus,
        //         //             idProcesso: cadastrais.webTipoProcesso.idProcesso,
        //         //             dados: false,
        //         //             seguradora: false,
        //         //             docs: 0,
        //         //             dadosSeguradora: false,
        //         //             plano: this.lista_Plano.find(x => x.idPlano == 1)
        //         //         })
        //         //     })

        //         //     this.spinnerService.hide();
        //         //     this.dataSource = new MatTableDataSource<Filtro>(this.listaFiltro);
        //         //     this.dataSource.paginator = this.paginator;
        //         // }, error => this.spinnerService.hide());
        //         break;
        //     //Resgate
        //     case 5:
        //         this.termoContribuicaoVoluntariaService.PostFiltroTermoContribuicaoVoluntaria(this.PostFiltro).subscribe(dados => {
        //             if (dados) {
        //                 if (dados.length) {

        //                     dados.map(voluntaria => {
        //                         this.listaFiltro.push({
        //                             id: voluntaria.idTermo,
        //                             nomeProcesso: voluntaria.webTipoProcesso.nmProcesso,
        //                             nrCpf: voluntaria.pessoa.nrCnpjCpf,
        //                             nomePessoa: voluntaria.pessoa.nmPessoa,
        //                             dtSolicitacao: voluntaria.dtOpcao,
        //                             nomeSituacao: voluntaria.webEsteiraStatus.nmStatus,
        //                             idProcesso: voluntaria.webTipoProcesso.idProcesso,
        //                             status: 0,
        //                             plano: voluntaria.plano,
        //                             check: false
        //                         })
        //                     })

        //                     this.spinnerService.hide();
        //                     this.dataSource = new MatTableDataSource<Filtro>(this.listaFiltro);
        //                     this.dataSource.paginator = this.paginator;
        //                 }
        //             }
        //         }, error => this.spinnerService.hide());
        //         break;
        //     //Autopatrocinio
        //     case 6:
        //         this.termoAutoPatrocinioService.PostFiltroTermoAutopatrocinio(this.PostFiltro).subscribe(dados => {
        //             if (dados) {
        //                 if (dados.length) {
        //                     dados.map(autopatrocinio => {
        //                         this.listaFiltro.push({
        //                             id: autopatrocinio.idTermo,
        //                             nomeProcesso: autopatrocinio.webTipoProcesso.nmProcesso,
        //                             nrCpf: autopatrocinio.pessoa.nrCnpjCpf,
        //                             nomePessoa: autopatrocinio.pessoa.nmPessoa,
        //                             dtSolicitacao: autopatrocinio.dtOpcao,
        //                             nomeSituacao: autopatrocinio.webEsteiraStatus.nmStatus,
        //                             idProcesso: autopatrocinio.webTipoProcesso.idProcesso,
        //                             status: 0,
        //                             plano: autopatrocinio.plano,
        //                             check: false
        //                         })
        //                     })

        //                     this.spinnerService.hide();
        //                     this.dataSource = new MatTableDataSource<Filtro>(this.listaFiltro);
        //                     this.dataSource.paginator = this.paginator;
        //                 }
        //             }
        //         }, error => this.spinnerService.hide());
        //         break;
        //     //Resgate
        //     case 7:
        //         this.termoResgateService.PostFiltroTermoResgate(this.PostFiltro).subscribe(dados => {
        //             if (dados) {
        //                 if (dados.length) {

        //                     dados.map(resgate => {
        //                         this.listaFiltro.push({
        //                             id: resgate.idTermo,
        //                             nomeProcesso: resgate.webTipoProcesso.nmProcesso,
        //                             nrCpf: resgate.pessoa.nrCnpjCpf,
        //                             nomePessoa: resgate.pessoa.nmPessoa,
        //                             dtSolicitacao: resgate.dtOpcao,
        //                             nomeSituacao: resgate.webEsteiraStatus.nmStatus,
        //                             idProcesso: resgate.webTipoProcesso.idProcesso,
        //                             status: 0,
        //                             plano: resgate.plano,
        //                             check: false
        //                         })
        //                     })

        //                     this.spinnerService.hide();
        //                     this.dataSource = new MatTableDataSource<Filtro>(this.listaFiltro);
        //                     this.dataSource.paginator = this.paginator;
        //                 }
        //             }
        //         }, error => this.spinnerService.hide());
        //         break;
        //     //BPD
        //     case 8:
        //         this.termoBpdService.PostFiltroTermoBpd(this.PostFiltro).subscribe(dados => {
        //             if (dados) {
        //                 if (dados.length) {

        //                     console.log(dados);

        //                     dados.map(bpd => {
        //                         console.log(bpd);
        //                         this.listaFiltro.push({
        //                             id: bpd.idTermo,
        //                             nomeProcesso: bpd.webTipoProcesso.nmProcesso,
        //                             nrCpf: bpd.pessoa.nrCnpjCpf,
        //                             nomePessoa: bpd.pessoa.nmPessoa,
        //                             dtSolicitacao: bpd.dtOpcao,
        //                             nomeSituacao: bpd.webEsteiraStatus.nmStatus,
        //                             idProcesso: bpd.webTipoProcesso.idProcesso,
        //                             status: 0,
        //                             plano: bpd.plano,
        //                             check: false
        //                         })
        //                     })

        //                     this.spinnerService.hide();
        //                     this.dataSource = new MatTableDataSource<Filtro>(this.listaFiltro);
        //                     this.dataSource.paginator = this.paginator;
        //                 }
        //             }
        //         }, error => this.spinnerService.hide());
        //         break;
        //     //Portabilidade
        //     case 9:
        //         this.termoPortabilidadeService.PostFiltroTermoPortabilidade(this.PostFiltro).subscribe(dados => {
        //             if (dados) {
        //                 if (dados.length) {

        //                     dados.map(portabilidade => {
        //                         console.log(portabilidade);
        //                         this.listaFiltro.push({
        //                             id: portabilidade.idTermo,
        //                             nomeProcesso: portabilidade.webTipoProcesso.nmProcesso,
        //                             nrCpf: portabilidade.pessoa.nrCnpjCpf,
        //                             nomePessoa: portabilidade.pessoa.nmPessoa,
        //                             dtSolicitacao: portabilidade.dtOpcao,
        //                             nomeSituacao: portabilidade.webEsteiraStatus.nmStatus,
        //                             idProcesso: portabilidade.webTipoProcesso.idProcesso,
        //                             status: 0,
        //                             plano: portabilidade.plano,
        //                             check: false
        //                         })
        //                     })

        //                     this.spinnerService.hide();
        //                     this.dataSource = new MatTableDataSource<Filtro>(this.listaFiltro);
        //                     this.dataSource.paginator = this.paginator;
        //                 }
        //             }
        //         }, error => this.spinnerService.hide());

        //         break;
        //     //Alteracao % Contribuicao
        //     case 10:

        //         this.webHistContribuicaoPercentualService.PostFiltroContribuicao(this.PostFiltro).subscribe(dados => {
        //             if (dados) {
        //                 if (dados.length) {

        //                     dados.map(hist => {
        //                         console.log(dados);
        //                         this.listaFiltro.push({
        //                             id: hist.iD_HIST,
        //                             nomeProcesso: hist.processo.nmProcesso,
        //                             nrCpf: hist.pessoa.nrCnpjCpf,
        //                             nomePessoa: hist.pessoa.nmPessoa,
        //                             dtSolicitacao: hist.dT_VIGENCIA,
        //                             nomeSituacao: hist.status.nmStatus,
        //                             idProcesso: hist.processo.idProcesso,
        //                             status: 0,
        //                             plano: hist.plano,
        //                             check: false
        //                         })
        //                     })

        //                     this.spinnerService.hide();
        //                     this.dataSource = new MatTableDataSource<Filtro>(this.listaFiltro);
        //                     this.dataSource.paginator = this.paginator;
        //                 }
        //             }
        //         }, error => this.spinnerService.hide());

        //         break;
        //     //Alteracao Perfil de Investimento
        //     case 11:
        //         break;
        //     //PPE
        //     case 12:
        //         this.termoPpeService.PostFiltroTermoPpe(this.PostFiltro).subscribe(dados => {
        //             if (dados) {
        //                 if (dados.length) {

        //                     dados.map(bpd => {
        //                         console.log(dados);
        //                         this.listaFiltro.push({
        //                             id: bpd.idTermo,
        //                             nomeProcesso: bpd.webTipoProcesso.nmProcesso,
        //                             nrCpf: bpd.pessoa.nrCnpjCpf,
        //                             nomePessoa: bpd.pessoa.nmPessoa,
        //                             dtSolicitacao: bpd.dtFim,
        //                             nomeSituacao: bpd.webEsteiraStatus.nmStatus,
        //                             idProcesso: bpd.webTipoProcesso.idProcesso,
        //                             status: 0,
        //                             plano: bpd.plano,
        //                             check: false
        //                         })
        //                     })

        //                     this.spinnerService.hide();
        //                     this.dataSource = new MatTableDataSource<Filtro>(this.listaFiltro);
        //                     this.dataSource.paginator = this.paginator;
        //                 }
        //             }
        //         }, error => this.spinnerService.hide());
        //         break;


        //     case 13:
        //         this.termoTipoRendaService.PostFiltroTermoTipoRenda(this.PostFiltro).subscribe(dados => {
        //             if (dados) {
        //                 if (dados.length) {

        //                     console.log("tipoRenda")
        //                     console.log(dados)
        //                     dados.map(tipoRenda => {
        //                         console.log(tipoRenda);
        //                         this.listaFiltro.push({
        //                             id: tipoRenda.idTermo,
        //                             nomeProcesso: tipoRenda.webTipoProcesso.nmProcesso,
        //                             nrCpf: tipoRenda.pessoa.nrCnpjCpf,
        //                             nomePessoa: tipoRenda.pessoa.nmPessoa,
        //                             dtSolicitacao: tipoRenda.dtOpcao,
        //                             nomeSituacao: tipoRenda.webEsteiraStatus.nmStatus,
        //                             idProcesso: tipoRenda.webTipoProcesso.idProcesso,
        //                             status: 0,
        //                             plano: tipoRenda.plano,
        //                             check: false
        //                         })
        //                     })

        //                     this.spinnerService.hide();
        //                     this.dataSource = new MatTableDataSource<Filtro>(this.listaFiltro);
        //                     this.dataSource.paginator = this.paginator;
        //                 }
        //             }
        //         }, error => this.spinnerService.hide());

        //         break;


        //     case 14:
        //         this.termoPerfilService.PostFiltroTermoPerfil(this.PostFiltro).subscribe(dados => {
        //             if (dados) {
        //                 if (dados.length) {

        //                     dados.map(perfil => {
        //                         this.listaFiltro.push({
        //                             id: perfil.idTermo,
        //                             nomeProcesso: perfil.webTipoProcesso.nmProcesso,
        //                             nrCpf: perfil.pessoa.nrCnpjCpf,
        //                             nomePessoa: perfil.pessoa.nmPessoa,
        //                             dtSolicitacao: perfil.dtOpcao,
        //                             nomeSituacao: perfil.webEsteiraStatus.nmStatus,
        //                             idProcesso: perfil.webTipoProcesso.idProcesso,
        //                             status: 0,
        //                             plano: perfil.plano,
        //                             check: false
        //                         })
        //                     })

        //                     this.spinnerService.hide();
        //                     this.dataSource = new MatTableDataSource<Filtro>(this.listaFiltro);
        //                     this.dataSource.paginator = this.paginator;
        //                 }
        //             }
        //         }, error => this.spinnerService.hide());

        //         break;

        //     case 15:
        //         this.termoAlteracaoPercentualService.PostFiltroTermoAlteracaoPercentual(this.PostFiltro).subscribe(dados => {
        //             if (dados) {
        //                 if (dados.length) {

        //                     dados.map(alteracao => {
        //                         this.listaFiltro.push({
        //                             id: alteracao.idTermo,
        //                             nomeProcesso: alteracao.webTipoProcesso.nmProcesso,
        //                             nrCpf: alteracao.pessoa.nrCnpjCpf,
        //                             nomePessoa: alteracao.pessoa.nmPessoa,
        //                             dtSolicitacao: alteracao.dtOpcao,
        //                             nomeSituacao: alteracao.webEsteiraStatus.nmStatus,
        //                             idProcesso: alteracao.webTipoProcesso.idProcesso,
        //                             status: 0,
        //                             plano: alteracao.plano,
        //                             check: false
        //                         })
        //                     })

        //                     this.spinnerService.hide();
        //                     this.dataSource = new MatTableDataSource<Filtro>(this.listaFiltro);
        //                     this.dataSource.paginator = this.paginator;
        //                 }
        //             }
        //         }, error => this.spinnerService.hide());

        //         break;


        //     default:
        //         this.adesaoService.PostFitroPlanoAdesao(this.PostFiltro).subscribe(dados => {
        //             if (dados) {
        //                 if (dados.length) {

        //                     dados.map(adesao => {
        //                         this.listaFiltro.push({
        //                             id: adesao.idConfigAdesao,
        //                             nomeProcesso: adesao.webTipoProcesso.nmProcesso,
        //                             nrCpf: adesao.nrCpf,
        //                             nomePessoa: adesao.nmPessoa,
        //                             dtSolicitacao: adesao.dtCadastro,
        //                             nomeSituacao: adesao.webEsteiraStatus.nmStatus,
        //                             idProcesso: adesao.webTipoProcesso.idProcesso,
        //                             status: 0,
        //                             plano: this.lista_Plano.find(x => x.idPlano == adesao.idPlano),
        //                             check: false
        //                         })
        //                     })

        //                     this.dataSource = new MatTableDataSource<Filtro>(this.listaFiltro);
        //                     this.dataSource.paginator = this.paginator;
        //                     this.spinnerService.hide();

        //                     this.dataSource.filteredData.map((adesao: Filtro) => {
        //                         this.pessoa.getByCpf(adesao.nrCpf).subscribe(obj => {

        //                             this.webControleAdesao = new WebControleAdesao();
        //                             this.webControleAdesao.idPessoa = obj.idPessoa;

        //                             this.webControleAdesaoService.Post(this.webControleAdesao).subscribe(obj => {
        //                                 if (obj) {

        //                                     if (obj.qualificacao == "S")
        //                                         adesao.status++;
        //                                     if (obj.endereco == "S")
        //                                         adesao.status++;
        //                                     if (obj.plano == "S")
        //                                         adesao.status++;
        //                                     if (obj.dps == "S")
        //                                         adesao.status++;
        //                                     if (obj.beneficiario == "S")
        //                                         adesao.status++;
        //                                     if (obj.ppe == "S")
        //                                         adesao.status++;
        //                                     if (obj.tributacao == "S")
        //                                         adesao.status++;
        //                                     if (obj.documento == "S")
        //                                         adesao.status++;

        //                                     console.log(adesao.status);
        //                                     console.log(obj);
        //                                 }
        //                             })
        //                         })
        //                     })
        //                 }
        //             }
        //         })

        //         this.webHistContribuicaoPercentualService.PostFiltroContribuicao(this.PostFiltro).subscribe(dados => {
        //             if (dados) {
        //                 if (dados.length) {
        //                     dados.map(hist => {
        //                         console.log(dados);
        //                         this.listaFiltro.push({
        //                             id: hist.iD_HIST,
        //                             nomeProcesso: hist.processo.nmProcesso,
        //                             nrCpf: hist.pessoa.nrCnpjCpf,
        //                             nomePessoa: hist.pessoa.nmPessoa,
        //                             dtSolicitacao: hist.dT_VIGENCIA,
        //                             nomeSituacao: hist.status.nmStatus,
        //                             idProcesso: hist.processo.idProcesso,
        //                             status: 0,
        //                             plano: hist.plano,
        //                             check: false
        //                         })
        //                     })

        //                     this.spinnerService.hide();
        //                     this.dataSource = new MatTableDataSource<Filtro>(this.listaFiltro);
        //                     this.dataSource.paginator = this.paginator;

        //                 }
        //             }
        //         }, error => this.spinnerService.hide());

        //         this.termoPpeService.PostFiltroTermoPpe(this.PostFiltro).subscribe(dados => {
        //             if (dados) {
        //                 if (dados.length) {
        //                     dados.map(bpd => {
        //                         console.log(dados);
        //                         this.listaFiltro.push({
        //                             id: bpd.idTermo,
        //                             nomeProcesso: bpd.webTipoProcesso.nmProcesso,
        //                             nrCpf: bpd.pessoa.nrCnpjCpf,
        //                             nomePessoa: bpd.pessoa.nmPessoa,
        //                             dtSolicitacao: bpd.dtFim,
        //                             nomeSituacao: bpd.webEsteiraStatus.nmStatus,
        //                             idProcesso: bpd.webTipoProcesso.idProcesso,
        //                             status: 0,
        //                             plano: bpd.plano,
        //                             check: false
        //                         })
        //                     })

        //                     this.spinnerService.hide();
        //                     this.dataSource = new MatTableDataSource<Filtro>(this.listaFiltro);
        //                     this.dataSource.paginator = this.paginator;
        //                 }
        //             }
        //         }, error => this.spinnerService.hide());

        //         this.termoRequerimentoAposentadoriaService.PostFiltroTermoRequerimentoAposentadoria(this.PostFiltro).subscribe(dados => {
        //             if (dados) {
        //                 if (dados.length) {
        //                     dados.map(aposentadoria => {
        //                         this.listaFiltro.push({
        //                             id: aposentadoria.idTermo,
        //                             nomeProcesso: aposentadoria.webTipoProcesso.nmProcesso,
        //                             nrCpf: aposentadoria.pessoa.nrCnpjCpf,
        //                             nomePessoa: aposentadoria.pessoa.nmPessoa,
        //                             dtSolicitacao: aposentadoria.dtOpcao,
        //                             nomeSituacao: aposentadoria.webEsteiraStatus.nmStatus,
        //                             idProcesso: aposentadoria.webTipoProcesso.idProcesso,
        //                             status: 0,
        //                             plano: aposentadoria.plano,
        //                             check: false
        //                         })
        //                     })

        //                     this.dataSource = new MatTableDataSource<Filtro>(this.listaFiltro);
        //                     this.dataSource.paginator = this.paginator;
        //                 }
        //             }
        //         });

        //         this.webTempDependenteService.PostFiltroWebTempDependente(this.PostFiltro).subscribe(dados => {
        //             if (dados) {
        //                 if (dados.length) {
        //                     dados.map(dependente => {
        //                         this.listaFiltro.push({
        //                             id: dependente.idPessoaDep,
        //                             nomeProcesso: dependente.webTipoProcesso.nmProcesso,
        //                             nrCpf: dependente.pessoa.nrCnpjCpf,
        //                             nomePessoa: dependente.pessoa.nmPessoa,
        //                             dtSolicitacao: dependente.dtIni,
        //                             nomeSituacao: dependente.webEsteiraStatus.nmStatus,
        //                             idProcesso: dependente.webTipoProcesso.idProcesso,
        //                             status: 0,
        //                             plano: dependente.plano,
        //                             check: false
        //                         })
        //                     })


        //                     this.dataSource = new MatTableDataSource<Filtro>(this.listaFiltro);
        //                     this.dataSource.paginator = this.paginator;
        //                 }
        //             }
        //         });

        //         // this.webPlanoDadosCadastraisService.PostFiltroWebPlanoDadosCadastrais(this.PostFiltro).subscribe(dados => {

        //         //     dados.map(cadastrais => {
        //         //         this.listaFiltro.push({
        //         //             id: cadastrais.idAlteracaoCadastral,
        //         //             nomeProcesso: cadastrais.webTipoProcesso.nmProcesso,
        //         //             nrCpf: cadastrais.pessoa.nrCnpjCpf,
        //         //             nomePessoa: cadastrais.pessoa.nmPessoa,
        //         //             dtSolicitacao: cadastrais.dtAlteracao,
        //         //             nomeSituacao: cadastrais.webEsteiraStatus.nmStatus,
        //         //             idProcesso: cadastrais.webTipoProcesso.idProcesso,
        //         //             dados: false,
        //         //             seguradora: false,
        //         //             docs: 0,
        //         //             dadosSeguradora: false,
        //         //             plano: this.lista_Plano.find(x => x.idPlano == 1)
        //         //         })
        //         //     })


        //         //     this.dataSource = new MatTableDataSource<Filtro>(this.listaFiltro);
        //         //     this.dataSource.paginator = this.paginator;
        //         // });

        //         this.termoContribuicaoVoluntariaService.PostFiltroTermoContribuicaoVoluntaria(this.PostFiltro).subscribe(dados => {
        //             if (dados) {
        //                 if (dados.length) {
        //                     dados.map(voluntaria => {
        //                         this.listaFiltro.push({
        //                             id: voluntaria.idTermo,
        //                             nomeProcesso: voluntaria.webTipoProcesso.nmProcesso,
        //                             nrCpf: voluntaria.pessoa.nrCnpjCpf,
        //                             nomePessoa: voluntaria.pessoa.nmPessoa,
        //                             dtSolicitacao: voluntaria.dtOpcao,
        //                             nomeSituacao: voluntaria.webEsteiraStatus.nmStatus,
        //                             idProcesso: voluntaria.webTipoProcesso.idProcesso,
        //                             status: 0,
        //                             plano: voluntaria.plano,
        //                             check: false
        //                         })
        //                     })

        //                     this.dataSource = new MatTableDataSource<Filtro>(this.listaFiltro);
        //                     this.dataSource.paginator = this.paginator;
        //                 }
        //             }
        //         });

        //         this.termoAutoPatrocinioService.PostFiltroTermoAutopatrocinio(this.PostFiltro).subscribe(dados => {
        //             if (dados) {
        //                 if (dados.length) {

        //                     dados.map(autopatrocinio => {
        //                         this.listaFiltro.push({
        //                             id: autopatrocinio.idTermo,
        //                             nomeProcesso: autopatrocinio.webTipoProcesso.nmProcesso,
        //                             nrCpf: autopatrocinio.pessoa.nrCnpjCpf,
        //                             nomePessoa: autopatrocinio.pessoa.nmPessoa,
        //                             dtSolicitacao: autopatrocinio.dtOpcao,
        //                             nomeSituacao: autopatrocinio.webEsteiraStatus.nmStatus,
        //                             idProcesso: autopatrocinio.webTipoProcesso.idProcesso,
        //                             status: 0,
        //                             plano: autopatrocinio.plano,
        //                             check: false
        //                         })
        //                     })

        //                     this.dataSource = new MatTableDataSource<Filtro>(this.listaFiltro);
        //                     this.dataSource.paginator = this.paginator;
        //                 }
        //             }
        //         });

        //         this.termoResgateService.PostFiltroTermoResgate(this.PostFiltro).subscribe(dados => {
        //             if (dados) {
        //                 if (dados.length) {

        //                     dados.map(resgate => {
        //                         this.listaFiltro.push({
        //                             id: resgate.idTermo,
        //                             nomeProcesso: resgate.webTipoProcesso.nmProcesso,
        //                             nrCpf: resgate.pessoa.nrCnpjCpf,
        //                             nomePessoa: resgate.pessoa.nmPessoa,
        //                             dtSolicitacao: resgate.dtOpcao,
        //                             nomeSituacao: resgate.webEsteiraStatus.nmStatus,
        //                             idProcesso: resgate.webTipoProcesso.idProcesso,
        //                             status: 0,
        //                             plano: resgate.plano,
        //                             check: false
        //                         })
        //                     })

        //                     this.dataSource = new MatTableDataSource<Filtro>(this.listaFiltro);
        //                     this.dataSource.paginator = this.paginator;
        //                 }
        //             }
        //         });

        //         this.termoBpdService.PostFiltroTermoBpd(this.PostFiltro).subscribe(dados => {
        //             if (dados) {
        //                 if (dados.length) {

        //                     dados.map(bpd => {
        //                         this.listaFiltro.push({
        //                             id: bpd.idTermo,
        //                             nomeProcesso: bpd.webTipoProcesso.nmProcesso,
        //                             nrCpf: bpd.pessoa.nrCnpjCpf,
        //                             nomePessoa: bpd.pessoa.nmPessoa,
        //                             dtSolicitacao: bpd.dtOpcao,
        //                             nomeSituacao: bpd.webEsteiraStatus.nmStatus,
        //                             idProcesso: bpd.webTipoProcesso.idProcesso,
        //                             status: 0,
        //                             plano: bpd.plano,
        //                             check: false
        //                         })
        //                     })

        //                     this.dataSource = new MatTableDataSource<Filtro>(this.listaFiltro);
        //                     this.dataSource.paginator = this.paginator;
        //                 }
        //             }
        //         });

        //         this.termoPortabilidadeService.PostFiltroTermoPortabilidade(this.PostFiltro).subscribe(dados => {
        //             if (dados) {
        //                 if (dados.length) {

        //                     dados.map(portabilidade => {
        //                         this.listaFiltro.push({
        //                             id: portabilidade.idTermo,
        //                             nomeProcesso: portabilidade.webTipoProcesso.nmProcesso,
        //                             nrCpf: portabilidade.pessoa.nrCnpjCpf,
        //                             nomePessoa: portabilidade.pessoa.nmPessoa,
        //                             dtSolicitacao: portabilidade.dtOpcao,
        //                             nomeSituacao: portabilidade.webEsteiraStatus.nmStatus,
        //                             idProcesso: portabilidade.webTipoProcesso.idProcesso,
        //                             status: 0,
        //                             plano: portabilidade.plano,
        //                             check: false
        //                         })
        //                     })

        //                     this.dataSource = new MatTableDataSource<Filtro>(this.listaFiltro);
        //                     this.dataSource.paginator = this.paginator;
        //                 }
        //             }
        //         });

        //         this.termoTipoRendaService.PostFiltroTermoTipoRenda(this.PostFiltro).subscribe(dados => {
        //             if (dados) {
        //                 if (dados.length) {

        //                     console.log(dados)

        //                     dados.map(tipoRenda => {
        //                         this.listaFiltro.push({
        //                             id: tipoRenda.idTermo,
        //                             nomeProcesso: tipoRenda.webTipoProcesso.nmProcesso,
        //                             nrCpf: tipoRenda.pessoa.nrCnpjCpf,
        //                             nomePessoa: tipoRenda.pessoa.nmPessoa,
        //                             dtSolicitacao: tipoRenda.dtOpcao,
        //                             nomeSituacao: tipoRenda.webEsteiraStatus.nmStatus,
        //                             idProcesso: tipoRenda.webTipoProcesso.idProcesso,
        //                             status: 0,
        //                             plano: tipoRenda.plano,
        //                             check: false
        //                         })
        //                     })

        //                     this.dataSource = new MatTableDataSource<Filtro>(this.listaFiltro);
        //                     this.dataSource.paginator = this.paginator;
        //                 }
        //             }
        //         });

        //         this.termoPerfilService.PostFiltroTermoPerfil(this.PostFiltro).subscribe(dados => {
        //             if (dados) {
        //                 if (dados.length) {

        //                     dados.map(perfil => {
        //                         this.listaFiltro.push({
        //                             id: perfil.idTermo,
        //                             nomeProcesso: perfil.webTipoProcesso.nmProcesso,
        //                             nrCpf: perfil.pessoa.nrCnpjCpf,
        //                             nomePessoa: perfil.pessoa.nmPessoa,
        //                             dtSolicitacao: perfil.dtOpcao,
        //                             nomeSituacao: perfil.webEsteiraStatus.nmStatus,
        //                             idProcesso: perfil.webTipoProcesso.idProcesso,
        //                             status: 0,
        //                             plano: perfil.plano,
        //                             check: false
        //                         })
        //                     })

        //                     this.dataSource = new MatTableDataSource<Filtro>(this.listaFiltro);
        //                     this.dataSource.paginator = this.paginator;
        //                 }
        //             }
        //         });


        //         this.termoAlteracaoPercentualService.PostFiltroTermoAlteracaoPercentual(this.PostFiltro).subscribe(dados => {
        //             if (dados) {
        //                 if (dados.length) {

        //                     dados.map(alteracao => {
        //                         this.listaFiltro.push({
        //                             id: alteracao.idTermo,
        //                             nomeProcesso: alteracao.webTipoProcesso.nmProcesso,
        //                             nrCpf: alteracao.pessoa.nrCnpjCpf,
        //                             nomePessoa: alteracao.pessoa.nmPessoa,
        //                             dtSolicitacao: alteracao.dtOpcao,
        //                             nomeSituacao: alteracao.webEsteiraStatus.nmStatus,
        //                             idProcesso: alteracao.webTipoProcesso.idProcesso,
        //                             status: 0,
        //                             plano: alteracao.plano,
        //                             check: false
        //                         })
        //                     })

        //                     this.spinnerService.hide();
        //                     this.dataSource = new MatTableDataSource<Filtro>(this.listaFiltro);
        //                     this.dataSource.paginator = this.paginator;
        //                 }
        //             }
        //         });

        //         break;

        // }

    }


    loadPlano() {
        this.planoService.getAll().subscribe(plano => {
            this.lista_Plano = plano;
        })
    }

    loadProcesso() {
        this.processoService.getAll().subscribe(processo => {
            this.lista_Processo = processo;
        });
    }

    loadEsteira() {
        this.esteiraService.getAll().subscribe(esteira => {
            this.lista_Esteira = esteira;
        });
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }


    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected == numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: Filtro): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + row.idProcesso}`;
    }

    deferir() {
        this.spinnerService.show()

        let listaProcesso: ViewModelFinalizaProcesso[] = []

        this.dataSource.data.map(element => {
            if (this.selection.isSelected(element) && element.nomeSituacao == 'PENDENTE') {
                console.log(element)
                element.nomeSituacao = "DEFERIDO";
                listaProcesso.push({
                    idProcesso: element.idProcesso,
                    idSolicitacao: element.id
                })
            }
        })

        this.dataSource = new MatTableDataSource<Filtro>(this.dataSource.data);
        this.dataSource.paginator = this.paginator;

        this.webProcessoControleService.DeferirProcessoLista(listaProcesso).subscribe(res => { this.filtroBuscaAdesao() }, error => this.spinnerService.hide())
    }

    indeferir() {
        this.spinnerService.show()

        let listaProcesso: ViewModelFinalizaProcesso[] = []

        this.dataSource.data.map(element => {
            if (this.selection.isSelected(element) && element.nomeSituacao == 'PENDENTE') {
                console.log(element)
                element.nomeSituacao = "INDEFERIDO";
                listaProcesso.push({
                    idProcesso: element.idProcesso,
                    idSolicitacao: element.id
                })
            }
        })

        this.dataSource = new MatTableDataSource<Filtro>(this.dataSource.data);
        this.dataSource.paginator = this.paginator;

        this.webProcessoControleService.IndeferirProcessoLista(listaProcesso).subscribe(res => { this.filtroBuscaAdesao() }, error => this.spinnerService.hide())
    }

    verDetalhe(element: Filtro): void {
        this.router.navigate(["/detalhes", element], { skipLocationChange: true });
        localStorage.setItem('search', JSON.stringify({
            selectedProcesso: this.selectedProcesso,
            selectedSituacao: this.selectedSituacao,
            selectedPlano: this.selectedPlano,
            dataInicio: this.dataInicio,
            dataFim: this.dataFim,
            cpf: this.cpf
        }));

        localStorage.setItem('searchData', JSON.stringify(this.dataSource.data));
    }

}
