import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { WebConfigDashService } from '../../../../shared/services/webConfigDash.service';
import { FormControl, Validators } from '@angular/forms';
import { WebListaFornecedoresService } from 'app/main/content/shared/services/webListaFornecedores.service';

@Component({
  selector: 'app-edit-fornecedor.dialog',
  templateUrl: '../../dialogs/edit/edit.dialog.html',
  styleUrls: ['../../dialogs/edit/edit.dialog.scss']
})
export class EditDialogFornecedorComponent {

  constructor(public dialogRef: MatDialogRef<EditDialogFornecedorComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, public webConfigFornecedorService: WebListaFornecedoresService) { }

  formControl = new FormControl('', [
    Validators.required
    // Validators.email,
  ]);

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' :
      this.formControl.hasError('email') ? 'Not a valid email' :
        '';
  }

  submit() {
    // emppty stuff
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  stopEdit(): void {
    this.webConfigFornecedorService.listUpdateData(this.data);
  }
}