import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PerfilInvestimentoADO } from '../models/perfilInvestimentoADO.model';

export class PerfilInvestimentoADOService extends ApiService implements BaseService<PerfilInvestimentoADO>{

    private strAction = 'api/PerfilInvestimentoADO';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<PerfilInvestimentoADO[]> {
        return this.get<PerfilInvestimentoADO[]>(this.strAction + `/GetAll`);
    }

    deleteById(idPerfil: number) {
        return this.post(this.strAction + `/DeleteById?id=${idPerfil}`);
    }

    getById(idPerfil: number): Observable<PerfilInvestimentoADO> {
        return this.get(this.strAction + `/GetById?id=${idPerfil}`);
    }

    getPerfilInvestimento(idPlano: number, idEmp: number): Observable<PerfilInvestimentoADO> {
        return this.get<PerfilInvestimentoADO>(this.strAction + `/GetPerfilInvestimento?idPlano=${idPlano}&idEmp=${idEmp}`);
    }

}