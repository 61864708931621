export class HistContribuicaoPercentual {
    iD_PESSOA: number;
    iD_PLANO: number;
    iD_CONTRIBUICAO: number;
    dT_VIGENCIA: Date;
    pC_CONTRIB: number;
    QT_PARCELAS: number;
    IC_OPTANTE: string;
    QT_TETO: number;
    QT_AJUSTE_PAGAS: number;
    IC_SUSPENSAO: string;
    ID_MOT_ALTERACAO: number;
}