import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { TermoContribuicaoVoluntaria } from '../models/termoContribuicaoVoluntaria.model';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ViewModelFiltraProcesso } from '../models/ViewModelFiltraProcesso.model';

export class TermoContribuicaoVoluntariaService extends ApiService implements BaseService<TermoContribuicaoVoluntaria>{

    private strAction = 'api/WebTempTermoContribuicaoVoluntaria';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<TermoContribuicaoVoluntaria[]> {
        return this.get<TermoContribuicaoVoluntaria[]>(this.strAction + `/GetAll`);
    }

    deleteById(TermoContribuicaoVoluntariaId: number) {
        return this.post(this.strAction + `/DeleteById?id=${TermoContribuicaoVoluntariaId}`);
    }

    PostFiltroTermoContribuicaoVoluntaria(viewModelFiltraProcesso: ViewModelFiltraProcesso): Observable<TermoContribuicaoVoluntaria[]> {
        return this.post<TermoContribuicaoVoluntaria[]>(this.strAction + `/PostFiltroTermoContribuicaoVoluntaria`, viewModelFiltraProcesso);
    }


    getById(id: number): Observable<TermoContribuicaoVoluntaria> {
        return this.get(this.strAction + `/GetById/${id}`);
    }

    getByCpf(cpf : string): Observable<TermoContribuicaoVoluntaria[]> {
        return this.get<TermoContribuicaoVoluntaria[]>(this.strAction + `/GetByCpf?nrCpf=${cpf}`);
    }

    Put(TermoContribuicaoVoluntaria: TermoContribuicaoVoluntaria): Observable<TermoContribuicaoVoluntaria> {
        return this.post<TermoContribuicaoVoluntaria>(this.strAction + `/Put`, TermoContribuicaoVoluntaria);
    }


}