import { Processo } from './processo.model';
import { Esteira } from './esteira.model';
import { Pessoa } from './pessoa.model';
import { Plano } from './plano.model';

export class TermoPPECompleto {
    idTermo: number;
    nmProtocolo: number;
    icPessoaPpe: string;
    icFamiliarPpe: string;
    dtOpcao: Date;
    webTipoProcesso: Processo;
    webEsteiraStatus: Esteira;
    pessoa: Pessoa;
    plano: Plano;
}