import { MatTableDataSource, MatSort, MatPaginatorIntl, MatDialog, MatSnackBar } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator'
import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { WebIntegrPerfilFuncADOService } from '../../shared/services/webIntegrPerfilFuncADO.service';
import { WebIntegrPerfilFuncADO } from '../../shared/models/webIntegrPerfilFuncADO.model';
import { InsertDialogFuncionalidadesComponent } from './dialogs/insert/insert.dialog.component';
import { DeleteDialogFuncionalidadesComponent } from './dialogs/delete/delete.dialog.component';
import { PlanoService } from '../../shared/services/plano.service';
import { WebIntegrPerfilADOService } from '../../shared/services/webIntegrPerfilADO.service';
import { WebIntegrPerfilADO } from '../../shared/models/webIntegrPerfilADO.model';
import { Plano } from '../../shared/models/plano.model';
import { WebIntegrFuncionalidadeADOService } from '../../shared/services/webIntegrFuncionalidadeADO.service';
import { WebIntegrFuncionalidadeADO } from '../../shared/models/webIntegrFuncionalidadeADO.model';
import { WebTempTermoPerfilService } from '../../shared/services/webTempTermoPerfil.service';
import { WebTempTermoAlteracaoPercentualService } from '../../shared/services/termoAlteracaoPercentual.service';
import { ViewModelAtualizarConfig } from '../../shared/models/viewModelAtualizarConfig.model';
import { WebTempTermoTipoRendaService } from '../../shared/services/webTempTermoTipoRenda.service';



export interface PeriodicElement {
  nome: string;
  posicao: number;
  peso: number;
  simbolo: string;
}

@Component({
  selector: 'app-configFuncionalidades',
  templateUrl: './configFuncionalidades.component.html',
  styleUrls: ['./configFuncionalidades.component.scss']
})

export class configFuncionalidadesComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns: string[] = ['actions', 'nM_PERFIL', 'nM_FUNCIONALIDADE', 'nM_PLANO'];

  dataSource = new MatTableDataSource<any>();

  listaPlano: Plano[] = [];
  listaPerfil: WebIntegrPerfilADO[] = [];
  listaFuncionalidade: WebIntegrFuncionalidadeADO[] = [];

  selectedPerfil: number;
  selectedPlano: number;
  selectedFuncionalidade: number;

  viewModelAtualizarConfigPerfil: ViewModelAtualizarConfig = new ViewModelAtualizarConfig()
  viewModelAtualizarConfigPercentual: ViewModelAtualizarConfig = new ViewModelAtualizarConfig()
  viewModelAtualizarConfigRenda: ViewModelAtualizarConfig = new ViewModelAtualizarConfig()

  constructor(public dialog: MatDialog,
    private webTempTermoTipoRendaService: WebTempTermoTipoRendaService,
    private webTempTermoPerfilService: WebTempTermoPerfilService,
    private webTempTermoAlteracaoPercentualService: WebTempTermoAlteracaoPercentualService,
    private webIntegrPerfilFuncADOService: WebIntegrPerfilFuncADOService,
    private planoService: PlanoService,
    private webIntegrPerfilADOService: WebIntegrPerfilADOService,
    private webIntegrFuncionalidadeADOService: WebIntegrFuncionalidadeADOService,
    private _snackBar: MatSnackBar) {
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, null, {
      duration: 2000,
      panelClass: ['mat-toolbar', 'mat-success']
    });
  }

  ngOnInit() {

    this.webTempTermoTipoRendaService.retornarConfig().subscribe(x => this.viewModelAtualizarConfigRenda = x )
    this.webTempTermoPerfilService.retornarConfig().subscribe(x => this.viewModelAtualizarConfigPerfil = x)
    this.webTempTermoAlteracaoPercentualService.retornarConfig().subscribe(x => this.viewModelAtualizarConfigPercentual = x)

    this.planoService.getAll().subscribe(obj => this.listaPlano = obj);
    this.webIntegrPerfilADOService.getAll().subscribe(obj => this.listaPerfil = obj);
    this.webIntegrFuncionalidadeADOService.getAll().subscribe(obj => this.listaFuncionalidade = obj);

    this.webIntegrPerfilFuncADOService.RetornarTodos().subscribe(obj => {
      this.dataSource = new MatTableDataSource<WebIntegrPerfilFuncADO>(obj);
      this.dataSource.paginator = this.paginator;
      console.log(obj);
    });

  }

  salvar() {
    this.webTempTermoPerfilService.Atualizar(this.viewModelAtualizarConfigPerfil).subscribe(x => 
      this.webTempTermoAlteracaoPercentualService.Atualizar(this.viewModelAtualizarConfigPercentual).
      subscribe(x => this.webTempTermoTipoRendaService.Atualizar(this.viewModelAtualizarConfigRenda).subscribe(x => this.openSnackBar("Configurações salvas com sucesso!"))))
  }

  buscar() {
    console.log('teste')
    this.webIntegrPerfilFuncADOService.RetornarPor(this.selectedPerfil, this.selectedPlano, this.selectedFuncionalidade).subscribe(obj => {
      this.dataSource = new MatTableDataSource<WebIntegrPerfilFuncADO>(obj);
      this.dataSource.paginator = this.paginator;
      this.refreshTable();
    });
  }

  limpar() {
    this.selectedPerfil = undefined;
    this.selectedPlano = undefined;
    this.selectedFuncionalidade = undefined;
  }

  insertNew() {
    const dialogRef = this.dialog.open(InsertDialogFuncionalidadesComponent, {
      data: { WebIntegrPerfilFuncADO: WebIntegrPerfilFuncADO }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        this.webIntegrPerfilFuncADOService.Inserir(this.webIntegrPerfilFuncADOService.getDialogData()).subscribe(listInsert => {
          this.webIntegrPerfilFuncADOService.RetornarTodos().subscribe(obj => {
            this.dataSource = new MatTableDataSource<WebIntegrPerfilFuncADO>(obj);
            this.dataSource.paginator = this.paginator;
            this.refreshTable();
          });

        });

      }
    });
  }



  deleteItem(data: WebIntegrPerfilFuncADO) {
    const dialogRef = this.dialog.open(DeleteDialogFuncionalidadesComponent, {
      data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        this.webIntegrPerfilFuncADOService.Deletar(data.iD_PERFIL, data.iD_FUNCIONALIDADE, data.iD_PLANO).subscribe(listDelete => {
          console.log(listDelete);
          this.webIntegrPerfilFuncADOService.RetornarTodos().subscribe(obj => {
            this.dataSource = new MatTableDataSource<WebIntegrPerfilFuncADO>(obj);
            this.dataSource.paginator = this.paginator;
            this.refreshTable();
          });
        });

      }
    });
  }

  private refreshTable() {
    this.paginator._changePageSize(this.paginator.pageSize);
  }
}
