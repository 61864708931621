import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Adesao } from '../models/adesao.model';


@Injectable()
export class AdesaoStore {

    private Subject = new BehaviorSubject<Adesao>(null);
    adesao = this.Subject.asObservable();

    constructor() { }

    getCurrent(): Observable<Adesao> {
        return this.adesao;
    }

    update(adesao: Adesao){
        this.Subject.next(adesao);
    }


    getnrCpf(){
        return this.Subject.asObservable();
    }

    getnmPessoa(){
        return this.Subject.asObservable();
    }

    getdtNascimento(){
        return this.Subject.asObservable();
    }

}