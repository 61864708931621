import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { WebTempConvitePlanoFamilia } from '../models/webTempConvitePlanoFamilia.model';
import { FiltroConvite } from '../models/filtroConvite.model';

export class WebTempConvitePlanoFamiliaService extends ApiService implements BaseService<WebTempConvitePlanoFamilia>{

    private strAction = 'api/WebTempConvitePlanoFamilia';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<WebTempConvitePlanoFamilia[]> {
        return this.get<WebTempConvitePlanoFamilia[]>(this.strAction + `/GetAll`);
    }

    deleteById(id: number) {
        return this.post(this.strAction + `/DeleteById?id=${id}`);
    }


    Post(WebTempConvitePlanoFamilia: WebTempConvitePlanoFamilia): Observable<WebTempConvitePlanoFamilia[]> {
        return this.post<WebTempConvitePlanoFamilia[]>(this.strAction + `/CriarConvite`, WebTempConvitePlanoFamilia);
    }


    getByCPF(nrCpf: string) {
        return this.get<WebTempConvitePlanoFamilia>(this.strAction + `/GetByCPF?nrCpf=${nrCpf}`)
    }

    PostFiltro(PostFiltro: FiltroConvite): Observable<WebTempConvitePlanoFamilia[]> {
        return this.post<WebTempConvitePlanoFamilia[]>(this.strAction + `/PostFiltro`, PostFiltro);
    }


}