import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { WebPerfilPlanoParticipante } from '../models/webPerfilPlanoParticipante.model';

export class WebPerfilPlanoParticipanteService extends ApiService implements BaseService<WebPerfilPlanoParticipante>{

    private strAction = 'api/WebPerfilPlanoParticipante';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<WebPerfilPlanoParticipante[]> {
        return this.get<WebPerfilPlanoParticipante[]>(this.strAction + `/GetAll`);
    }

    deleteById(idPessoa: number) {
        return this.post(this.strAction + `/DeleteById?id=${idPessoa}`);
    }

    getById(idPessoa: number): Observable<WebPerfilPlanoParticipante> {
        return this.get(this.strAction + `/GetById/${idPessoa}`);
    }

    Put(webPerfilPlanoParticipante: WebPerfilPlanoParticipante): Observable<WebPerfilPlanoParticipante> {
        return this.post<WebPerfilPlanoParticipante>(this.strAction + `/Put`, webPerfilPlanoParticipante);
    }

    getByPessoa(idPessoa: number): Observable<WebPerfilPlanoParticipante> {
        return this.get(this.strAction + `/GetCadastroByPessoa?idPessoa=${idPessoa}`);
    }

}