import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Contribuicao } from '../models/contribuicao.model';

export class ContribuicaoService extends ApiService implements BaseService<Contribuicao>{

    private strAction = 'api/Contribuicao';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<Contribuicao[]> {
        return this.get<Contribuicao[]>(this.strAction + `/GetAll`);
    }

    deleteById(idContribuicao: number) {
        return this.post(this.strAction + `/DeleteById?id=${idContribuicao}`);
    }

}