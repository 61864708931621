import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { TermoBpd } from '../models/termoBpd.model';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ViewModelFiltraProcesso } from '../models/ViewModelFiltraProcesso.model';

export class TermoBpdService extends ApiService implements BaseService<TermoBpd>{
    
    private strAction = 'api/WebTempTermoBpd';
    
    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
        }

        getAll(): Observable<TermoBpd[]> {
            return this.get<TermoBpd[]>(this.strAction + `/GetAll`);
        }

        deleteById(TermoBpdId: number) {
            return this.post(this.strAction + `/DeleteById?id=${TermoBpdId}`);
        }
    
        PostFiltroTermoBpd(viewModelFiltraProcesso: ViewModelFiltraProcesso): Observable<TermoBpd[]>{
            return this.post<TermoBpd[]>(this.strAction + `/PostFiltroTermoBpd`, viewModelFiltraProcesso);
        }

        getById(id: number): Observable<TermoBpd> {
            return this.get(this.strAction + `/GetById/${id}`);
        }
    
        getByCpf(cpf : string): Observable<TermoBpd[]> {
            return this.get<TermoBpd[]>(this.strAction + `/GetByCpf?nrCpf=${cpf}`);
        }

        Put(TermoBpd: TermoBpd): Observable<TermoBpd> {
            return this.post<TermoBpd>(this.strAction + `/Put`, TermoBpd);
        }

}