import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { WebStatusObs } from '../models/webStatusObs.model';

export class WebStatusObsService extends ApiService implements BaseService<WebStatusObs>{

    private strAction = 'api/WebStatusObs';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<WebStatusObs[]> {
        return this.get<WebStatusObs[]>(this.strAction + `/GetAll`);
    }

    deleteById(id: number) {
        return this.post(this.strAction + `/DeleteById?id=${id}`);
    }

    
    getByCpf(nrCpf: string): Observable<WebStatusObs[]> {
        return this.get(this.strAction + `/GetObsByCpf?nrCpf=${nrCpf}`);
    }

    
    Post(webStatusRegistro: WebStatusObs): Observable<WebStatusObs> {
        return this.post<WebStatusObs>(this.strAction + `/CriarObs`, webStatusRegistro);
    }


}