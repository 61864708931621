import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { WebConfigDashService } from '../../../../shared/services/webConfigDash.service';
import { FormControl, Validators } from '@angular/forms';
import { WebListaAtasService } from 'app/main/content/shared/services/webListaAtas.service';

@Component({
  selector: 'app-edit.dialog',
  templateUrl: '../../dialogs/edit/edit.dialog.html',
  styleUrls: ['../../dialogs/edit/edit.dialog.scss']
})
export class EditDialogAtasComponent {

  constructor(public dialogRef: MatDialogRef<EditDialogAtasComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, public webListaAtasService: WebListaAtasService) { }

  formControl = new FormControl('', [
    Validators.required
    // Validators.email,
  ]);

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' :
      this.formControl.hasError('email') ? 'Not a valid email' :
        '';
  }

  submit() {
    // emppty stuff
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  stopEdit(): void {
    this.webListaAtasService.listUpdateData(this.data);
  }
}