import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseService } from 'app/core/base.service';
import { ApiService } from './api.services';
import { Processo } from './../models/processo.model';

@Injectable()
export class ProcessoService extends ApiService implements BaseService<Processo>{
 
    private strAction = 'api/WebTipoProcesso';
    

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
        }

        getAll(): Observable<Processo[]> {
            return this.get<Processo[]>(this.strAction + `/GetAll`);
        }

        deleteById(adesaoId: number) {
            return this.post(this.strAction + `/DeleteById?id=${adesaoId}`);
        }
    
  
    
}