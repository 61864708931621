import { MatTableModule } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatDividerModule } from '@angular/material/divider';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule, MatOptionModule, MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { MatTabsModule, MatRadioModule, MatTableDataSource, MatProgressBarModule, MatTooltipModule, MatDialogModule, MatPaginatorModule, MatToolbarModule, MatSidenavModule, MatMenuModule, MatChipsModule, MatListModule, MatProgressBar, MatSnackBarModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { FuseMaterialColorPickerModule } from '@fuse/components/material-color-picker/material-color-picker.module';
import { HttpClientModule } from '@angular/common/http';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { SharedModule } from '../shared/shared.module';
import { DocumentosComponent } from './documentos/documentos.component';
import { UploadDocumentosComponent } from './uploadDocumentos/uploadDocumentos.component';
import { FuseConfirmDialogModule } from '@fuse/components';
import { NgxDnDModule } from '@swimlane/ngx-dnd';
import { AreaInterativaComponent } from './areainterativa.component';
import { ConsultarDocumentosComponent } from './consultarDocumentos/consultarDocumentos.component';
import { ConsultarConvitesComponent } from './consultarConvites/consultarConvites.component';



const routes = [
    {
        path: 'area-interativa',
        component: AreaInterativaComponent
    },
    {
        path: 'documentos',
        component: DocumentosComponent
    },
    {
        path: 'upload',
        component: UploadDocumentosComponent
    },

    {
        path: 'consultarDocumentos',
        component: ConsultarDocumentosComponent
    },

    {
        path: 'consultarConvites',
        component: ConsultarConvitesComponent
    }

];

@NgModule({
    declarations: [AreaInterativaComponent, UploadDocumentosComponent, DocumentosComponent, ConsultarDocumentosComponent, ConsultarConvitesComponent],
    imports: [
        SharedModule,
        CommonModule,
        FuseSharedModule,
        FuseWidgetModule,
        RouterModule.forChild(routes),
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatTableModule,
        MatTabsModule,
        MatDividerModule,
        MatListModule,
        MatChipsModule,
        MatDialogModule,
        MatMenuModule,
        MatRippleModule,
        MatSidenavModule,
        MatToolbarModule,
        MatTooltipModule,
        NgxDnDModule,
        FuseConfirmDialogModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatNativeDateModule,
        MatSnackBarModule
    ],
    exports: [AreaInterativaComponent, UploadDocumentosComponent, DocumentosComponent, ConsultarDocumentosComponent]
})
export class AreaInterativaModule { }