export class PessoaADO {
    iC_SEXO: string;
    iC_RESID_EXTERIOR: string;
    nR_RG: string;
    sG_EMISSOR_RG: string;
    sG_UF_EMISSOR_RG: string;
    dT_NASCIMENTO: Date;
    dT_EMISS_RG: Date;
    nM_ENDERECO: string;
    nR_ENDERECO: string;
    nM_COMPLEMENTO: string;
    nR_CEP: string;
    sG_UF: string;
    nM_CIDADE: string;
    nM_BAIRRO: string;
    nM_EMAIL: string;
    nM_EMAIL2: string;
    nR_TELEFONE: string;
    nR_CELULAR: string;
    iD_PESSOA: number;
    nM_PESSOA: string;
    iC_PARTICIPANTE: string;
    nR_CNPJ_CPF: string;
    iD_EMP: number;
    iD_ESTADO_CIVIL: string;
    iC_PERMITE_NULO: string;
    sG_PESSOA: string;
    iC_EXPOSTA: string;
    iD_NACIONALIDADE: number;
    nM_MAE: string;
    nM_PAI: string;
    nM_ERRO: string;
    iC_ATIVO : string;
    iC_CNPJ_CPF : string;
    iC_VALIDO: boolean;
}