import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { WebConfigDash } from '../../../../shared/models/webConfigDash.model';
import { WebConfigDashService } from '../../../../shared/services/webConfigDash.service';
import { FormControl, Validators } from '@angular/forms';
import { WebListaFornecedoresService } from 'app/main/content/shared/services/webListaFornecedores.service';
import { WebListaFornecedores } from 'app/main/content/shared/models/webListaFornecedores.model';

@Component({
  selector: 'app-insert-fornecedor.dialog',
  templateUrl: '../../dialogs/insert/insert.dialog.html',
  styleUrls: ['../../dialogs/insert/insert.dialog.scss']
})

export class InsertDialogFornecedorComponent {
  constructor(public dialogRef: MatDialogRef<InsertDialogFornecedorComponent>,
              @Inject(MAT_DIALOG_DATA) public data: WebListaFornecedores,
              public webListaFornecedoresService: WebListaFornecedoresService) { }

  formControl = new FormControl('', [
    Validators.required
    // Validators.email,
  ]);

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' :
      this.formControl.hasError('email') ? 'Not a valid email' :
        '';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public confirmInsert(): void {
    this.webListaFornecedoresService.listInsertData(this.data);
  }
}

