import { Processo } from './processo.model';
import { Esteira } from './esteira.model';
import { Pessoa } from './pessoa.model';
import { Plano } from './plano.model';

export class WebTempDependente {
    idPessoaDep: number;
    idParentesco: number;
    idFinalidade: number;
    participacao: number;
    icInvalido: string;
    dtIni: Date;
    dtFim: Date;
    icintegrado: string;
    nrProtocolo: string;
    pcParticipacao: number;
    pessoa: Pessoa;
    webTipoProcesso: Processo;
    webEsteiraStatus: Esteira;
    plano: Plano;
}