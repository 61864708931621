import { Pessoa } from './pessoa.model';

export class Empresa {
    idEmp : number;
    pessoaEmp : Pessoa;
    icExclusaoPrest : number;
    idEmpContabil : number;
    nrLoteArrec : number;
    nrLoteAutoPat : number;
    icAtivo : string;
    nrLotePortab : number;
    nrLoteConcessao : number;
    idPerfilArrec : number;
    nrLoteCusteio : number;
    nrLoteRendimento : number;
    icEmpContabil : string;
    nrLoteRateio : number;
}