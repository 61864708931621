import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseService } from 'app/core/base.service';
import { ApiService } from './api.services';
import { PessoaContaBancaria } from '../models/pessoaContaBancaria.model';

@Injectable()
export class PessoaContaBancariaService extends ApiService implements BaseService<PessoaContaBancaria>{
 
    private strAction = 'api/PessoaContaBancaria';
    

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
        }

        getAll(): Observable<PessoaContaBancaria[]> {
            return this.get<PessoaContaBancaria[]>(this.strAction + `/GetAll`);
        }

        getById(idPessoa: number): Observable<PessoaContaBancaria> {
            return this.get(this.strAction + `/GetById/${idPessoa}`);
        }


        deleteById(idPessoa: number) {
            return this.post(this.strAction + `/DeleteById?id=${idPessoa}`);
        }
    
  
    
}