import { MatDividerModule } from '@angular/material/divider';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatButtonModule } from '@angular/material';
import { FuseMaterialColorPickerModule } from '@fuse/components';
import { TemaComponent } from './tema.component';

@NgModule({
    declarations: [TemaComponent],
    imports: [MatDialogModule, MatButtonModule, CommonModule, MatDividerModule, FuseMaterialColorPickerModule],
    entryComponents: [TemaComponent]
})
export class TemaModule {}