import { MatTableDataSource, MatSort, MatPaginatorIntl, MatDialog } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator'
import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpClientJsonpModule, HttpHeaders } from '@angular/common/http';
import { WebConfigDashService } from '../../shared/services/webConfigDash.service';
import { WebConfigDash } from '../../shared/models/webConfigDash.model';
import { WebListaAtasService } from '../../shared/services/webListaAtas.service';
import { WebListaAtas } from '../../shared/models/webListaAtas.model';
import { InsertDialogAtasComponent } from './dialogs/insert/insert.dialog.component';
import { EditDialogAtasComponent } from './dialogs/edit/edit.dialog.component';
import { DeleteDialogAtasComponent } from './dialogs/delete/delete.dialog.component';



export interface PeriodicElement {
  nome: string;
  posicao: number;
  peso: number;
  simbolo: string;
}

@Component({
  selector: 'app-configAtas',
  templateUrl: './configAtas.component.html',
  styleUrls: ['./configAtas.component.scss']
})

export class configAtasComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns: string[] = ['actions', 'idAta', 'nmTitulo', 'nmDescricao', 'dtCadastro'];

  dataSource = new MatTableDataSource<any>();
  listaConfig: WebConfigDash[] = [];


  constructor(public dialog: MatDialog,
    private webListaAtasService: WebListaAtasService) {
  }

  ngOnInit() {


    this.webListaAtasService.getAll().subscribe(obj => {
      this.dataSource = new MatTableDataSource<WebListaAtas>(obj);
      this.dataSource.paginator = this.paginator;
      console.log(obj);
    });

  }

  insertNew() {
    const dialogRef = this.dialog.open(InsertDialogAtasComponent, {
      data: { WebConfigDash: WebConfigDash }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        this.webListaAtasService.criar(this.webListaAtasService.getDialogData()).subscribe(listInsert => {
          console.log(listInsert);

          this.webListaAtasService.getAll().subscribe(obj => {
            this.dataSource = new MatTableDataSource<WebListaAtas>(obj);
            this.dataSource.paginator = this.paginator;
            console.log(obj);
            this.refreshTable();
          });

        });

      }
    });
  }

  startEdit(idAta: number, nmTitulo: string, nmDescricao: string,
    dtCadastro: Date) {
    const dialogRef = this.dialog.open(EditDialogAtasComponent, {
      data: {
        idAta: idAta, nmTitulo: nmTitulo, nmDescricao: nmDescricao,
        dtCadastro: dtCadastro
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        // When using an edit things are little different, firstly we find record inside DataService by id
        this.webListaAtasService.Put(this.webListaAtasService.getDialogData()).subscribe(listUpdate => {
          console.log(listUpdate);

          this.webListaAtasService.getAll().subscribe(obj => {
            this.dataSource = new MatTableDataSource<WebListaAtas>(obj);
            this.dataSource.paginator = this.paginator;
            console.log(obj);
            this.refreshTable();
          });

        });

      }
    });
  }

  deleteItem(idAta: number, nmTitulo: string, nmDescricao: string,
    dtCadastro: Date) {
    const dialogRef = this.dialog.open(DeleteDialogAtasComponent, {
      data: {
        idAta: idAta, nmTitulo: nmTitulo, nmDescricao: nmDescricao,
        dtCadastro: dtCadastro
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        this.webListaAtasService.deleteById(idAta).subscribe(listDelete => {
          console.log(listDelete);
            this.webListaAtasService.getAll().subscribe(obj => {
            this.dataSource = new MatTableDataSource<WebListaAtas>(obj);
            this.dataSource.paginator = this.paginator;
            console.log(obj);
            this.refreshTable();
          });
        });

      }
    });
  }

  private refreshTable() {
    this.paginator._changePageSize(this.paginator.pageSize);
  }
}
