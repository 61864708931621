import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { WebConfigDashService } from '../../../../shared/services/webConfigDash.service';
import { WebListaFornecedoresService } from 'app/main/content/shared/services/webListaFornecedores.service';


@Component({
  selector: 'app-delete-fornecedor.dialog',
  templateUrl: '../../dialogs/delete/delete.dialog.html',
  styleUrls: ['../../dialogs/delete/delete.dialog.scss']
})
export class DeleteDialogFornecedorComponent {

  constructor(public dialogRef: MatDialogRef<DeleteDialogFornecedorComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, public webConfigFornecedorService: WebListaFornecedoresService) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  confirmDelete(): void {
    this.webConfigFornecedorService.listDeleteData(this.data.idFornecedor);
  }
}