import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { WebIntegrPerfilFuncADOService } from 'app/main/content/shared/services/webIntegrPerfilFuncADO.service';
import { WebIntegrPerfilFuncADO } from 'app/main/content/shared/models/webIntegrPerfilFuncADO.model';
import { PlanoService } from 'app/main/content/shared/services/plano.service';
import { Plano } from 'app/main/content/shared/models/plano.model';
import { WebIntegrPerfilADOService } from 'app/main/content/shared/services/webIntegrPerfilADO.service';
import { WebIntegrFuncionalidadeADOService } from 'app/main/content/shared/services/webIntegrFuncionalidadeADO.service';
import { WebIntegrPerfilADO } from 'app/main/content/shared/models/webIntegrPerfilADO.model';
import { WebIntegrFuncionalidadeADO } from 'app/main/content/shared/models/webIntegrFuncionalidadeADO.model';

@Component({
  selector: 'app-insert.dialog',
  templateUrl: '../../dialogs/insert/insert.dialog.html',
  styleUrls: ['../../dialogs/insert/insert.dialog.scss']
})


export class InsertDialogFuncionalidadesComponent implements OnInit {

  listaPlano: Plano[] = [];
  listaPerfil: WebIntegrPerfilADO[] = [];
  listaFuncionalidade: WebIntegrFuncionalidadeADO[] = [];

  constructor(public dialogRef: MatDialogRef<InsertDialogFuncionalidadesComponent>,
              @Inject(MAT_DIALOG_DATA) public data: WebIntegrPerfilFuncADO,
              public webIntegrPerfilFuncADOService: WebIntegrPerfilFuncADOService,
              private webIntegrFuncionalidadeADOService : WebIntegrFuncionalidadeADOService,
              private planoService: PlanoService,
              private webIntegrPerfilADOService: WebIntegrPerfilADOService) { }

  formControl = new FormControl('', [
    Validators.required
    // Validators.email,
  ]);

  ngOnInit() {
    this.planoService.getAll().subscribe(obj => this.listaPlano = obj);
    this.webIntegrPerfilADOService.getAll().subscribe(obj => this.listaPerfil = obj);
    this.webIntegrFuncionalidadeADOService.getAll().subscribe(obj => this.listaFuncionalidade = obj);
  }

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' :
      this.formControl.hasError('email') ? 'Not a valid email' :
        '';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public confirmInsert(): void {
    this.webIntegrPerfilFuncADOService.listInsertData(this.data);
  }
}

