import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { WebConfigDash } from '../models/webConfigDash.model';

export class WebConfigDashService extends ApiService implements BaseService<WebConfigDash>{

    private strAction = 'api/WebConfigDash';
    dialogData: any;

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    //Data FROM dialogs
    listInsertData(webConfigDash: WebConfigDash): void {
        this.dialogData = webConfigDash;
    }

    listUpdateData(webConfigDash: WebConfigDash): void {
        this.dialogData = webConfigDash;
    }

    listDeleteData(id: number): void {
        this.dialogData = id;
    }

    //Data to be used in CRUD
    getDialogData() {
        return this.dialogData;
    }

    //Data FROM BD
    getAll(): Observable<WebConfigDash[]> {
        return this.get<WebConfigDash[]>(this.strAction + `/GetAll`);
    }

    getById(id: number): Observable<WebConfigDash> {
        return this.get<WebConfigDash>(this.strAction + `/GetById/${id}`);
    }

    deleteById(id: number) {
        return this.post(this.strAction + `/DeleteById?id=${id}`);
    }

    Post(WebConfigDash: WebConfigDash): Observable<WebConfigDash[]> {
        return this.post<WebConfigDash[]>(this.strAction + `/Post`, WebConfigDash);
    }

    Put(WebConfigDash: WebConfigDash): Observable<WebConfigDash[]> {
        return this.post<WebConfigDash[]>(this.strAction + `/Put`, WebConfigDash);
    }

}