import { Processo } from './processo.model';
import { Esteira } from './esteira.model';
import { Pessoa } from './pessoa.model';
import { Plano } from './plano.model';

export class TermoContribuicaoVoluntaria {
    idTermo: number;
    idPessoa:number; 
    vlContribuicaoVoluntaria: number;
    icOrigemContrib: string;
    nmOrigemContrib: string;
    nmProtocolo: number;
    dtOpcao: Date;
    idProcesso: number;
    idStatus: number;
    webTipoProcesso: Processo;
    webEsteiraStatus: Esteira;
    pessoa: Pessoa;
    plano: Plano;
}