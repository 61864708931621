import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ViewModelFiltraProcesso } from '../models/ViewModelFiltraProcesso.model';
import { TermoAlteracaoResponsavel } from '../models/termoAlteracaoResponsavel.model';
import { ViewModelBuscarPessoa } from '../models/viewModelBuscarPessoa.model';

export class WebTempTermoAlteracaoResponsavelService extends ApiService implements BaseService<TermoAlteracaoResponsavel>{

    private strAction = 'api/WebTempTermoAlteracaoResponsavel';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<TermoAlteracaoResponsavel[]> {
        return this.get<TermoAlteracaoResponsavel[]>(this.strAction + `/GetAll`);
    }

    deleteById(idTermo: number) {
        return this.post(this.strAction + `/DeleteById?id=${idTermo}`);
    }

    PostFiltroTermoPpe(viewModelFiltraProcesso: ViewModelFiltraProcesso): Observable<TermoAlteracaoResponsavel[]> {
        return this.post<TermoAlteracaoResponsavel[]>(this.strAction + `/PostFiltroTermoAlteracaoResponsavel`, viewModelFiltraProcesso);
    }

    getById(id: number): Observable<TermoAlteracaoResponsavel> {
        return this.get(this.strAction + `/GetById/${id}`);
    }

    getByCpf(cpf : string): Observable<TermoAlteracaoResponsavel[]> {
        return this.get<TermoAlteracaoResponsavel[]>(this.strAction + `/GetByCpf?nrCpf=${cpf}`);
    }

    Put(TermoAlteracaoResponsavel: TermoAlteracaoResponsavel): Observable<TermoAlteracaoResponsavel> {
        return this.post<TermoAlteracaoResponsavel>(this.strAction + `/Put`, TermoAlteracaoResponsavel);
    }
    
}