import { Processo } from './processo.model';
import { Esteira } from './esteira.model';

export class WebPlanoParticipante {
    idPessoa: number;
    idPlano: number;
    dtIniPlano: Date;
    dtFimPlano: Date;
    dtIniAuto: Date;
    dtFimAuto: Date;
    icParcial: string;
    icTributacao: string;
    icCalculoAuto: string;
    nrVencimento: number;
    idModoPagRec: number;
    webTipoProcesso: Processo;
    webEsteiraStatus: Esteira;
}