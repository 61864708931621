import { Processo } from './processo.model';
import { Esteira } from './esteira.model';
import { Pessoa } from './pessoa.model';
import { Plano } from './plano.model';

export class TermoContaComplementar {
    idTermo: number;
    nmProtocolo: string;
    nmBanco: string;
    nrBanco: number;
    nrAgencia: number;
    nrConta: number;
    dtOpcao: Date;
    webTipoProcesso: Processo;
    webEsteiraStatus: Esteira;
    pessoa: Pessoa;
    plano: Plano;
}