import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { WebHistContribuicaoPercentual } from '../models/webHistContribuicaoPercentual.model';
import { ViewModelFiltraProcesso } from '../models/ViewModelFiltraProcesso.model';

export class WebHistContribuicaoPercentualService extends ApiService implements BaseService<WebHistContribuicaoPercentual>{

    private strAction = 'api/WebHistContribuicaoPercentual';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<WebHistContribuicaoPercentual[]> {
        return this.get<WebHistContribuicaoPercentual[]>(this.strAction + `/GetAll`);
    }

    deleteById(idHist: number) {
        return this.post(this.strAction + `/DeleteById?id=${idHist}`);
    }

    getById(idHist: number): Observable<WebHistContribuicaoPercentual> {
        return this.get(this.strAction + `/GetById/${idHist}`);
    }

    getAllHist(idPessoa: number): Observable<WebHistContribuicaoPercentual[]> {
        return this.get<WebHistContribuicaoPercentual[]>(this.strAction + `/GetAllHist?idPessoa=${idPessoa}`);
    }

    GetAllHistByCont(idPessoa: number, idContribuicao: number): Observable<WebHistContribuicaoPercentual> {
        return this.get(this.strAction + `/GetAllHistByCont?idPessoa=${idPessoa}&idContribuicao=${idContribuicao}`);
    }

    
    getHist(idPessoa: number, idPlano: number, idContribuicao: number): Observable<WebHistContribuicaoPercentual> {
        return this.get(this.strAction + `/GetHist?idPessoa=${idPessoa}&idPlano=${idPlano}&idContribuicao=${idContribuicao}`);
    }

        
    RetornaListaWebHistContribuicaoPercentual(idPessoa: number): Observable<WebHistContribuicaoPercentual[]> {
        return this.get(this.strAction + `/RetornaListaWebHistContribuicaoPercentual/${​​idPessoa}`);
    }


    Put(WebHistContribuicaoPercentual: WebHistContribuicaoPercentual): Observable<WebHistContribuicaoPercentual> {
        return this.post<WebHistContribuicaoPercentual>(this.strAction + `/Put`, WebHistContribuicaoPercentual);
    }

    
    PostFiltroContribuicao(viewModelFiltraProcesso: ViewModelFiltraProcesso): Observable<WebHistContribuicaoPercentual[]> {
        return this.post<WebHistContribuicaoPercentual[]>(this.strAction + `/PostFiltroHist`, viewModelFiltraProcesso);
    }

    getByCpf(cpf : string): Observable<WebHistContribuicaoPercentual[]> {
        return this.get<WebHistContribuicaoPercentual[]>(this.strAction + `/GetByCpf?nrCpf=${cpf}`);
    }

}