import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { TermoResgate } from '../models/termoResgate.model';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { WebTempDependente } from '../models/webTempDependente.model';
import { WebInclusaoDependenteTempADO } from '../models/webInclusaoDependenteTempADO.model';
import { ViewModelFiltraProcesso } from '../models/ViewModelFiltraProcesso.model';

export class WebTempDependenteService extends ApiService implements BaseService<WebTempDependente>{

    private strAction = 'api/WebTempDependente';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<WebTempDependente[]> {
        return this.get<WebTempDependente[]>(this.strAction + `/GetAll`);
    }

    deleteById(webTempDependente: number) {
        return this.post(this.strAction + `/DeleteById?id=${webTempDependente}`);
    }

    PostFiltroWebTempDependente(viewModelFiltraProcesso: ViewModelFiltraProcesso): Observable<WebTempDependente[]> {
        return this.post<WebTempDependente[]>(this.strAction + `/PostFiltroWebTempDependente`, viewModelFiltraProcesso);
    }

    getByPessoaAndPessoaDep(idPessoa: number, idPessoaDep: number): Observable<WebTempDependente> {
        return this.get(this.strAction + `/GetDependenteByPessoaAndPessoaDep?idPessoa=${idPessoa}&idPessoaDep=${idPessoaDep}`);
    }

    Put(WebInclusaoDependenteTempADO: WebInclusaoDependenteTempADO): Observable<WebInclusaoDependenteTempADO[]> {
        return this.put<WebInclusaoDependenteTempADO[]>('api/WebInclusaoDependenteTempADO/PutDependente', WebInclusaoDependenteTempADO);
    }

    Put2(WebTempDependente: WebTempDependente): Observable<WebTempDependente> {
        return this.put<WebTempDependente>(this.strAction + `/Put`, WebTempDependente);
    }

    getByPessoa(idPessoa: number): Observable<WebTempDependente[]> {
        return this.get(this.strAction + `/GetDependenteByPessoa?idPessoa=${idPessoa}`);
    }

    getById(id: number): Observable<WebTempDependente> {
        return this.get<WebTempDependente>(this.strAction + `/GetById/${id}`);
    }

    getByCpf(cpf : string): Observable<WebTempDependente[]> {
        return this.get<WebTempDependente[]>(this.strAction + `/GetByCpf?nrCpf=${cpf}`);
    }



}