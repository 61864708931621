import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { WebPlanoParticipante } from '../models/webPlanoParticipante.model';


export class WebPlanoParticipanteService extends ApiService implements BaseService<WebPlanoParticipante>{

    private strAction = 'api/WebPlanoParticipante';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<WebPlanoParticipante[]> {
        return this.get<WebPlanoParticipante[]>(this.strAction + `/GetAll`);
    }

    deleteById(idPlano: number) {
        return this.post(this.strAction + `/DeleteById?id=${idPlano}`);
    }

    getById(idPlano: number): Observable<WebPlanoParticipante[]> {
        return this.get(this.strAction + `/GetById/${idPlano}`);
    }

    getByPessoa(idPessoa: number): Observable<WebPlanoParticipante> {
        return this.get(this.strAction + `/GetCadastroByPessoa?idPessoa=${idPessoa}`);
    }


    Put(webPlanoParticipante: WebPlanoParticipante): Observable<WebPlanoParticipante> {
        return this.post<WebPlanoParticipante>(this.strAction + `/Put`, webPlanoParticipante);
    }

}