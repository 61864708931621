import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PlanoCategoria } from '../models/planoCategoria.model';
import { Injectable } from '@angular/core';

@Injectable()
export class PlanoCategoriaService extends ApiService implements BaseService<PlanoCategoria>{

    private strAction = 'api/PlanoCategoria';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    deleteById(idCategoria: number) {
        return this.post(this.strAction + `/DeleteById?id=${idCategoria}`);
    }

    getAll(): Observable<PlanoCategoria[]> {
        return this.get<PlanoCategoria[]>(this.strAction + `/GetAll`);
    }


    getById(idCategoria: number): Observable<PlanoCategoria> {
        return this.get(this.strAction + `/GetById/${idCategoria}`);
    }

}