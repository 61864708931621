import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { WebHistPlanoParticipante } from '../models/webHistPlanoParticipante.model';


export class WebHistPlanoParticipanteService extends ApiService implements BaseService<WebHistPlanoParticipante>{

    private strAction = 'api/WebHistPlanoParticipante';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<WebHistPlanoParticipante[]> {
        return this.get<WebHistPlanoParticipante[]>(this.strAction + `/GetAll`);
    }

    deleteById(idPessoa: number) {
        return this.post(this.strAction + `/DeleteById?id=${idPessoa}`);
    }

    getById(idPessoa: number): Observable<WebHistPlanoParticipante> {
        return this.get(this.strAction + `/GetById/${idPessoa}`);
    }

    Put(webHistPlanoParticipante: WebHistPlanoParticipante): Observable<WebHistPlanoParticipante> {
        return this.post<WebHistPlanoParticipante>(this.strAction + `/Put`, webHistPlanoParticipante);
    }

}