import { MatTableDataSource, MatSort, MatPaginatorIntl, MatDialog } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator'
import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpClientJsonpModule, HttpHeaders } from '@angular/common/http';
import { WebConfigDashService } from '../../shared/services/webConfigDash.service';
import { WebConfigDash } from '../../shared/models/webConfigDash.model';
import { InsertDialogComponent } from './dialogs/insert/insert.dialog.component';
import { EditDialogComponent } from './dialogs/edit/edit.dialog.component';
import { DeleteDialogComponent } from './dialogs/delete/delete.dialog.component';



export interface PeriodicElement {
  nome: string;
  posicao: number;
  peso: number;
  simbolo: string;
}

@Component({
  selector: 'app-configDashboard',
  templateUrl: './configDashboard.component.html',
  styleUrls: ['./configDashboard.component.scss']
})

export class configDashboardComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns: string[] = ['actions','idConfig', 'idPlano', 'idCategoria', 'idSituacao', 'idCardPosicao', 'nrCardCorFundo', 'nrCardCorFundoTexto', 'nrCardCorHeader', 'nrCardCorHeaderTexto', 'nrCardCorFooter', 'nrCardCorFooterTexto', 'nmCardImg', 'nmCardTitulo', 'nmCardSubtitulo', 'nmCardLink', 'icCardBlank'];

  dataSource = new MatTableDataSource<any>();
  listaConfig: WebConfigDash[] = [];


  constructor(public dialog: MatDialog,
    private webConfigDashService: WebConfigDashService) {
  }

  ngOnInit() {


    this.webConfigDashService.getAll().subscribe(obj => {
      this.dataSource = new MatTableDataSource<WebConfigDash>(obj);
      this.dataSource.paginator = this.paginator;
       console.log(obj);
    });

  }

  insertNew() {
    const dialogRef = this.dialog.open(InsertDialogComponent, {
      data: {WebConfigDash: WebConfigDash }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        this.webConfigDashService.Post(this.webConfigDashService.getDialogData()).subscribe(listInsert => {
          console.log(listInsert);
        });
        this.refreshTable();
      }
    });
  }

  startEdit(idConfig: string, idPlano: string, idCategoria: string,
    idSituacao: string, idCardPosicao: string, nrCardCorFundo: string,
    nrCardCorFundoTexto: string, nrCardCorHeader: string,
    nrCardCorHeaderTexto: string, nrCardCorFooter: string,
    nrCardCorFooterTexto: string, nmCardImg: string, nmCardTitulo: string,
    nmCardSubtitulo: string, nmCardLink: string, icCardBlank: string) {
    const dialogRef = this.dialog.open(EditDialogComponent, {
      data: {idConfig: idConfig, idPlano: idPlano, idCategoria: idCategoria,
        idSituacao: idSituacao, idCardPosicao: idCardPosicao, nrCardCorFundo: nrCardCorFundo,
        nrCardCorFundoTexto: nrCardCorFundoTexto, nrCardCorHeader: nrCardCorHeader,
        nrCardCorHeaderTexto: nrCardCorHeaderTexto, nrCardCorFooter: nrCardCorFooter,
        nrCardCorFooterTexto: nrCardCorFooterTexto, nmCardImg: nmCardImg, nmCardTitulo: nmCardTitulo,
        nmCardSubtitulo: nmCardSubtitulo, nmCardLink: nmCardLink, icCardBlank: icCardBlank}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        // When using an edit things are little different, firstly we find record inside DataService by id
        this.webConfigDashService.Put(this.webConfigDashService.getDialogData()).subscribe(listUpdate => {
          console.log(listUpdate);
        });
        this.refreshTable();
      }
    });
  }

  deleteItem(idConfig: string, idPlano: string, idCategoria: string,
    idSituacao: string, idCardPosicao: string, nrCardCorFundo: string,
    nrCardCorFundoTexto: string, nrCardCorHeader: string,
    nrCardCorHeaderTexto: string, nrCardCorFooter: string,
    nrCardCorFooterTexto: string, nmCardImg: string, nmCardTitulo: string,
    nmCardSubtitulo: string, nmCardLink: string, icCardBlank: string) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {idConfig: idConfig, idPlano: idPlano, idCategoria: idCategoria,
        idSituacao: idSituacao, idCardPosicao: idCardPosicao, nrCardCorFundo: nrCardCorFundo,
        nrCardCorFundoTexto: nrCardCorFundoTexto, nrCardCorHeader: nrCardCorHeader,
        nrCardCorHeaderTexto: nrCardCorHeaderTexto, nrCardCorFooter: nrCardCorFooter,
        nrCardCorFooterTexto: nrCardCorFooterTexto, nmCardImg: nmCardImg, nmCardTitulo: nmCardTitulo,
        nmCardSubtitulo: nmCardSubtitulo, nmCardLink: nmCardLink, icCardBlank: icCardBlank}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        this.webConfigDashService.deleteById(this.webConfigDashService.getDialogData()).subscribe(listDelete => {
          console.log(listDelete);
        });
        this.refreshTable();
      }
    });
  }

  private refreshTable() {
    this.paginator._changePageSize(this.paginator.pageSize);
  }
}
