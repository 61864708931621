import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { TermoRequerimentoAposentadoria } from '../models/termoRequerimentoAposentadoria.model';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ViewModelFiltraProcesso } from '../models/ViewModelFiltraProcesso.model';

export class TermoRequerimentoAposentadoriaService extends ApiService implements BaseService<TermoRequerimentoAposentadoria>{

    private strAction = 'api/WebTempTermoRequerimentoAposentadoria';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<TermoRequerimentoAposentadoria[]> {
        return this.get<TermoRequerimentoAposentadoria[]>(this.strAction + `/GetAll`);
    }

    deleteById(TermoRequerimentoAposentadoriaId: number) {
        return this.post(this.strAction + `/DeleteById?id=${TermoRequerimentoAposentadoriaId}`);
    }

    PostFiltroTermoRequerimentoAposentadoria(viewModelFiltraProcesso: ViewModelFiltraProcesso): Observable<TermoRequerimentoAposentadoria[]> {
        return this.post<TermoRequerimentoAposentadoria[]>(this.strAction + `/PostFiltroTermoRequerimentoAposentadoria`, viewModelFiltraProcesso);
    }

    getById(id: number): Observable<TermoRequerimentoAposentadoria> {
        return this.get(this.strAction + `/GetById/${id}`);
    }

    getByCpf(cpf : string): Observable<TermoRequerimentoAposentadoria[]> {
        return this.get<TermoRequerimentoAposentadoria[]>(this.strAction + `/GetByCpf?nrCpf=${cpf}`);
    }

    Put(TermoRequerimentoAposentadoria: TermoRequerimentoAposentadoria): Observable<TermoRequerimentoAposentadoria> {
        return this.post<TermoRequerimentoAposentadoria>(this.strAction + `/Put`, TermoRequerimentoAposentadoria);
    }



}