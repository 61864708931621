import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Pessoa } from '../models/pessoa.model';
import { Injectable } from '@angular/core';

@Injectable()
export class PessoaService extends ApiService implements BaseService<Pessoa>{

    private strAction = 'api/Pessoa';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

   deleteById(idPessoa: number) {
        return this.post(this.strAction + `/DeleteById?id=${idPessoa}`);
    }

    getAll(): Observable<Pessoa[]> {
        return this.get<Pessoa[]>(this.strAction + `/GetAll`);
    }


    getById(idPessoa: number): Observable<Pessoa> {
        return this.get(this.strAction + `/GetById/${idPessoa}`);
    }

    getByCpf(nrCpf: string): Observable<Pessoa> {
        return this.get(this.strAction + `/GetPessoaByCpf?nrCpf=${nrCpf}`);
    }

}