import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { WebContratoSeguro } from '../models/webContratoSeguro.model';

export class WebContratoSeguroService extends ApiService implements BaseService<WebContratoSeguro>{
    
    private strAction = 'api/WebContratoSeguro';
    
    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
        }

        getAll(): Observable<WebContratoSeguro[]> {
            return this.get<WebContratoSeguro[]>(this.strAction + `/GetAll`);
        }

        deleteById(idPessoa: number) {
            return this.post(this.strAction + `/DeleteById?id=${idPessoa}`);
        }
    
        getByPessoa(idPessoa: number): Observable<WebContratoSeguro[]> {
            return this.get(this.strAction + `/GetContratosByPessoa?idPessoa=${idPessoa}`);
        }

        Post(WebContratoSeguro: WebContratoSeguro): Observable<WebContratoSeguro[]>{
            return this.post<WebContratoSeguro[]>(this.strAction + `/Post`, WebContratoSeguro);
        }

        Put(WebContratoSeguro: WebContratoSeguro): Observable<WebContratoSeguro> {
            return this.post<WebContratoSeguro>(this.strAction + `/Put`, WebContratoSeguro);
        }

}