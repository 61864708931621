import { Component, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatPaginatorIntl } from '@angular/material';
import { WebDocumentoRestritoService } from '../../shared/services/webDocumentosRestrito.service';
import { WebDocumentosRestrito } from '../../shared/models/webDocumentosRestrito.model';
import { PlanoCategoria } from '../../shared/models/planoCategoria.model';
import { PlanoSituacao } from '../../shared/models/planoSituacao.model';
import { Plano } from '../../shared/models/plano.model';
import { PlanoSituacaoService } from '../../shared/services/planoSituacao.service';
import { PlanoCategoriaService } from '../../shared/services/planoCategoria.service';
import { PlanoService } from '../../shared/services/plano.service';
import { environment } from 'environments/environment';

@Component({
    selector: 'documentos',
    templateUrl: './documentos.component.html',
    styleUrls: ['./documentos.component.scss'],
})

export class DocumentosComponent implements OnInit {

    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    dataSource = new MatTableDataSource<any>();
    displayedColumns: string[] = ['descricao', 'CPF', 'categoria', 'situacao', 'plano', 'data', 'acoes'];
    listaDocumentos: WebDocumentosRestrito[] = [];
    listaCategorias: PlanoCategoria[] = [];
    listaSituacoes: PlanoSituacao[] = [];
    listaPlanos: Plano[] = [];
    icParticipante: string = '';
    dataInicio: Date;
    dataFim: Date;
    cpf: string = '';
    idPlano: number = null;
    idCategoria: number = null;
    idSituacao: number = null;


    constructor(
        private webDocumentosRestritoService: WebDocumentoRestritoService,
        private planoService: PlanoService,
        private planoCategoriaService: PlanoCategoriaService,
        private planoSituacaoService: PlanoSituacaoService
    ) {
    }

    ngOnInit() {
        this.dataSource.paginator = this.paginator;
        this.webDocumentosRestritoService.getAll().subscribe(obj => {
            this.listaDocumentos = obj
            this.dataSource = new MatTableDataSource<WebDocumentosRestrito>(this.listaDocumentos);
            this.dataSource.paginator = this.paginator;

            this.planoService.getAll().subscribe(obj => { this.listaPlanos = obj });
            this.planoCategoriaService.getAll().subscribe(obj => { this.listaCategorias = obj });
            this.planoSituacaoService.getAll().subscribe(obj => { this.listaSituacoes = obj });

        });
    }


    search() {
        this.webDocumentosRestritoService.getBy(this.icParticipante, this.cpf, this.idPlano, this.idCategoria, this.idSituacao).subscribe(obj => {
            this.listaDocumentos = obj
            this.dataSource = new MatTableDataSource<WebDocumentosRestrito>(this.listaDocumentos);
            this.dataSource.paginator = this.paginator;

        });
    }

    clear() {
        this.icParticipante = undefined;
        this.cpf = undefined;
        this.idPlano = undefined;
        this.idCategoria = undefined;
        this.idSituacao = undefined;
    }

    getCat(id) {
        let result = '';
        this.listaCategorias.map(cat => { if (cat.idCategoria == id) { result = cat.nmCategoria } });
        return result;
    }

    getSituacao(id) {
        let result = '';
        this.listaSituacoes.map(situacao => { if (situacao.idSituacao == id) { result = situacao.nmSituacao } })
        return result;
    }

    delete(id) {

        const confirmation = confirm('Deseja REALMENTE excluir esse documento?');
        if (confirmation) {
            this.webDocumentosRestritoService.deleteById(id).subscribe(obj =>
                this.listaDocumentos.map((documento, index) => {
                    if (documento.idDocumento == id) {
                        this.listaDocumentos.splice(index, 1);
                        this.dataSource = new MatTableDataSource<WebDocumentosRestrito>(this.listaDocumentos);
                        this.dataSource.paginator = this.paginator;
                    }
                }));
        }
    }

    goToLink(doc: string) {
        window.open(environment.TrustCRMApiHost + "resources/images/" + doc, "_blank");
    }

    limpar() {
        this.cpf = undefined;
        this.idCategoria = undefined;
        this.idSituacao = undefined;
        this.idPlano = undefined;
      }

}