import { MatDialog } from '@angular/material/dialog';
import { Component } from '@angular/core';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet' ;
import { TemaComponent } from './tema/tema.component';
import { Tema2Component } from './tema2/tema2.component';



export interface item {
    text: string;
    router: string;
}

@Component({
    selector: 'app-paginainicial',
    templateUrl: './paginainicial.component.html',
    styleUrls: ['./paginainicial.component.scss']
})

export class paginainicialComponent {
    
    Painel: item[] = [
        { text: 'Página Inicial', router: '#' },
        { text: 'Dados do Participante', router: '#' },
        { text: 'Dependentes ', router: '#' },
        { text: 'Plano Instituido', router: '#' },
        { text: 'Recadastramento', router: '#' },
        { text: ' Contribuições', router: '#' },
        { text: 'Institutos', router: '#' },
        { text: 'Benefícios', router: '#' },
        { text: 'Empréstimos', router: '#' },
        { text: 'Dossiê do participante', router: '#' },
        { text: ' Notificações do participante', router: '#' }
    ];

    constructor(public dialog: MatDialog) {

    }

    showMessage(message: string): void {
        const dialogRef = this.dialog.open(TemaComponent, {
            width: '350px',
            data: { message: message }
        });
 
        dialogRef.afterClosed().subscribe(result => {

        });
    }
    
    showMessage2(message: string): void {

        const dialogRef2 = this.dialog.open(Tema2Component, {
            width: '350px',
            data: { message: message }
        });
 
        dialogRef2.afterClosed().subscribe(result => {

        });
    }

}

