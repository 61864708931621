import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { DependenteFinalidade } from '../models/dependentefinalidade.model';

@Injectable()
export class DependenteFinalidadeService extends ApiService implements BaseService<DependenteFinalidade>{

    private strAction = 'api/DependenteFinalidade';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    deleteById(idPessoa: number) {
        return this.post(this.strAction + `/DeleteById?id=${idPessoa}`);
    }

    getAll(): Observable<DependenteFinalidade[]> {
        return this.get<DependenteFinalidade[]>(this.strAction + `/GetAll`);
    }


    getById(idPessoa: number): Observable<DependenteFinalidade> {
        return this.get(this.strAction + `/GetById/${idPessoa}`);
    }


    getByPessoa(idPessoa: number): Observable<DependenteFinalidade[]> {
        return this.get(this.strAction + `/GetDependenteByPessoa?idPessoa=${idPessoa}`);
    }

}