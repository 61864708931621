import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { environment } from 'environments/environment';
import { tap, catchError } from 'rxjs/operators';
import { Login } from '../models/login.model';
import { Credencial } from '../models/credencial.model';
import { DialogComponent } from '../../message/dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    public dialog: MatDialog,
    public HttpClient: HttpClient
  ) { }

  public get currentTokenValue(): string {
    return localStorage.getItem('token');
  }

  retornatoken(user: string, password: string) {

    const credencial = new Credencial();

    credencial.login = user;
    credencial.senha = password;

    return this.HttpClient
      .post<{ token: string; username: string }>(
        environment.TrustCRMApiHost + 'api/Login/Autenticar', credencial
      )
      .pipe(
        tap(res => {
          localStorage.setItem('token', res.token);
        }),
        catchError(this.handleError<Login>('Recuperação do token falhou.'))
      );

  }

  logout() {
    localStorage.clear();
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.showMessage('Credenciais inválidas.');
      console.error(operation + ' Erro: ' + error.message);

      return of(result as T);
    };
  }


  showMessage(message: string): void {
    const dialogRef = this.dialog.open(
      DialogComponent, {
      width: '350px',
      data: { message: message }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

}
