import { Processo } from './processo.model';
import { Esteira } from './esteira.model';
import { Pessoa } from './pessoa.model';

export class Adesao {
    idConfigAdesao: number;
    nrCpf: string;
    nmPessoa: string;
    dtNascimento: Date;
    icSexo: string;
    nmEmail: string;
    nrTelefone: string;
    icInvalidez: string;
    icPensaoMorte: string;
    nmFormaPagamento: string;
    nrDiaPagamento: number;
    nmRenda: string;
    idPlano: number;
    dtCadastro: Date;
    nmBemVindo: any;
    icPrimeiroAcesso: string;
    idProcesso: number;
    idStatus: number;
    webTipoProcesso: Processo;
    webEsteiraStatus: Esteira;
    dados: boolean;
    seguradora: boolean;
    docs : number;
}