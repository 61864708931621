import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { PlanoService } from '../../shared/services/plano.service';
import { Plano } from '../../shared/models/plano.model';
import { ViewModelBuscarPessoa } from '../../shared/models/viewModelBuscarPessoa.model';
import { PlanoParticipanteService } from '../../shared/services/planoParticipante.service';
import { SelectionModel } from '@angular/cdk/collections';
import { PostFiltroRelatorio } from '../../shared/models/postFiltroRelatorio';
import { Pessoa } from '../../shared/models/pessoa.model';
import { WebNotificacaoService } from '../../shared/services/webNotificacao.service';
import { ViewModelEnviarNotificacao } from '../../shared/models/viewModelEnviarNotificacao.model';
import { WebSimulacaoService } from '../../shared/services/webSimulacao.service';
import { PostFiltroSimulacao } from '../../shared/models/postFiltroSimulacao';
import { ViewModelBuscarSimulacao } from '../../shared/models/viewModelBuscarSimulacao.model';

@Component({
    selector: 'app-simulacoes',
    templateUrl: './simulacoes.component.html',
    styleUrls: ['./simulacoes.component.scss']
})
export class SimulacoesComponent implements OnInit {

    selectedStatus: number
    dataInicio: Date;
    dataFim: Date
    postFiltroSimulacao: PostFiltroSimulacao = new PostFiltroSimulacao();

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    displayedColumns: string[] = ['cpf', 'nome', 'email', 'data', 'status'];

    dataSource = new MatTableDataSource<any>();
    selection = new SelectionModel<any>(true, []);

    constructor(private webSimulacaoService: WebSimulacaoService) {

    }

    ngOnInit() {
    }

    buscar() {
        this.postFiltroSimulacao.idStatus = this.selectedStatus;
        this.postFiltroSimulacao.dataInicio = this.dataInicio;
        this.postFiltroSimulacao.dataFim = this.dataFim;
        this.webSimulacaoService.BuscarSimulacao(this.postFiltroSimulacao).subscribe(obj => {
            console.log(obj)
            this.dataSource = new MatTableDataSource<ViewModelBuscarSimulacao>(obj);
            this.dataSource.paginator = this.paginator;
            this.refreshTable();
        }, error => console.log(error));
    }

    limpar() {
        this.selectedStatus = undefined;
        this.dataInicio = undefined;
        this.dataFim = undefined;
    }

    private refreshTable() {
        this.paginator._changePageSize(this.paginator.pageSize);
    }

  
}
