import { Injectable } from '@angular/core';
import { Adesao } from './../models/adesao.model';
import { BaseService } from 'app/core/base.service';
import { ApiService } from './api.services';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ViewModelFiltraProcesso } from '../models/ViewModelFiltraProcesso.model';

@Injectable()
export class AdesaoService extends ApiService implements BaseService<Adesao>{
 
    private strAction = 'api/WebPlanoAdesao';
    

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
        }

        getAll(): Observable<Adesao[]> {
            return this.get<Adesao[]>(this.strAction + `/GetAll`);
        }

        deleteById(adesaoId: number) {
            return this.post(this.strAction + `/DeleteById?id=${adesaoId}`);
        }

        getById(adesaoId: number): Observable<Adesao> {
            return this.get<Adesao>(this.strAction + `/GetById/${adesaoId}`);
        }
    
        PostFitroPlanoAdesao(viewModelFiltraProcesso: ViewModelFiltraProcesso): Observable<Adesao[]>{
            return this.post<Adesao[]>(this.strAction + `/PostFitroPlanoAdesao`, viewModelFiltraProcesso);
        }

        PutStatus(AdesaoLista: Adesao[]): Observable<Adesao[]>{
            return this.put<Adesao[]>(this.strAction + `/PutStatus`, AdesaoLista);
        }

        
}