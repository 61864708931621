import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { WebListaAtas } from '../models/webListaAtas.model';
import { WebListaFornecedores } from '../models/webListaFornecedores.model';

export class WebListaFornecedoresService extends ApiService implements BaseService<WebListaFornecedores>{

    private strAction = 'api/WebListaFornecedores';
    dialogData: any;

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    listInsertData(webListaFornecedores: WebListaFornecedores): void {
        this.dialogData = webListaFornecedores;
    }

    listUpdateData(webListaFornecedores: WebListaFornecedores): void {
        this.dialogData = webListaFornecedores;
    }

    listDeleteData(id: number): void {
        this.dialogData = id;
    }

    //Data to be used in CRUD
    getDialogData() {
        return this.dialogData;
    }

    getAll(): Observable<WebListaFornecedores[]> {
        return this.get<WebListaFornecedores[]>(this.strAction + `/GetAll`);
    }

    deleteById(idFornecedor: number) {
        return this.post(this.strAction + `/DeleteById?id=${idFornecedor}`);
    }

    getById(idFornecedor: number): Observable<WebListaAtas> {
        return this.get(this.strAction + `/GetById/${idFornecedor}`);
    }

    Put(fornecedor: WebListaFornecedores) {
        return this.post<WebListaFornecedores>(this.strAction + `/Put`, fornecedor);
    }

    criar(fornecedor: WebListaFornecedores) {
        return this.post<WebListaFornecedores>(this.strAction + `/Criar`, fornecedor);
    }

}