import { Component } from '@angular/core';

@Component({
    selector: 'tab-ppe',
    templateUrl: './ppe.component.html',
    styleUrls: ['./ppe.component.scss']
})

export class PPEComponent {

}