import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PlanoSituacao } from '../models/planoSituacao.model';
import { Injectable } from '@angular/core';

@Injectable()
export class PlanoSituacaoService extends ApiService implements BaseService<PlanoSituacao>{

    private strAction = 'api/PlanoSituacao';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    deleteById(idSituacao: number) {
        return this.post(this.strAction + `/DeleteById?id=${idSituacao}`);
    }

    getAll(): Observable<PlanoSituacao[]> {
        return this.get<PlanoSituacao[]>(this.strAction + `/GetAll`);
    }


    getById(idSituacao: number): Observable<PlanoSituacao> {
        return this.get(this.strAction + `/GetById/${idSituacao}`);
    }

}