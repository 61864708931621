import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { WebStatusRegistro } from '../models/webStatusRegistro.model';

export class WebStatusRegistroService extends ApiService implements BaseService<WebStatusRegistro>{

    private strAction = 'api/WebStatusRegistro';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<WebStatusRegistro[]> {
        return this.get<WebStatusRegistro[]>(this.strAction + `/GetAll`);
    }

    deleteById(id: number) {
        return this.post(this.strAction + `/DeleteById?id=${id}`);
    }

    
    getByCpf(nrCpf: string): Observable<WebStatusRegistro[]> {
        return this.get(this.strAction + `/GetRegistroByCpf?nrCpf=${nrCpf}`);
    }

    
    Post(webStatusRegistro: WebStatusRegistro): Observable<WebStatusRegistro[]> {
        return this.post<WebStatusRegistro[]>(this.strAction + `/CriarRegistro`, webStatusRegistro);
    }


}