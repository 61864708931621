import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { TermoResgate } from '../models/termoResgate.model';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { WebPlanoAdesaoNovo } from '../models/WebPlanoAdesaoNovo.model';

export class WebPlanoAdesaoNovoService extends ApiService implements BaseService<WebPlanoAdesaoNovo>{

    private strAction = 'api/WebPlanoAdesaoNovo';
    
    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
        }

        getAll(): Observable<WebPlanoAdesaoNovo[]> {
            return this.get<WebPlanoAdesaoNovo[]>(this.strAction + `/GetAll`);
        }

        deleteById(id: number) {
            return this.post(this.strAction + `/DeleteById?id=${id}`);
        }
    
        getById(id: number ): Observable<WebPlanoAdesaoNovo>{
            return this.get<WebPlanoAdesaoNovo>(this.strAction + `/GetById/${id}`);
        }

        getByPessoa(id: number ): Observable<WebPlanoAdesaoNovo>{
            return this.get<WebPlanoAdesaoNovo>(this.strAction + `/GetByPessoa?idPessoa=${id}`);
        }

}