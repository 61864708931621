import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { WebIntegrFuncionalidadeADO } from '../models/webIntegrFuncionalidadeADO.model';

@Injectable()
export class WebIntegrFuncionalidadeADOService extends ApiService implements BaseService<WebIntegrFuncionalidadeADO>{

    private strAction = 'api/WebIntegrFuncionalidadeADO';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    deleteById(idFuncionalidade: number) {
        return this.post(this.strAction + `/DeleteById?id=${idFuncionalidade}`);
    }

    getAll(): Observable<WebIntegrFuncionalidadeADO[]> {
        return this.get<WebIntegrFuncionalidadeADO[]>(this.strAction + `/RetornarTodos`);
    }


    getById(idFuncionalidade: number): Observable<WebIntegrFuncionalidadeADO> {
        return this.get(this.strAction + `/GetById/${idFuncionalidade}`);
    }

}