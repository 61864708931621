export class WebLogin {
    idPessoa : number;
    nmNome : string;
    nmEmail : string;
    nrCpf : string;
    nmSenha : string;
    dtCriacao : Date;
    dtAlteracao : Date;
    idCliente : number;
    icAtivo : string;
}