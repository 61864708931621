import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ViewModelFiltraProcesso } from '../models/ViewModelFiltraProcesso.model';
import { TermoRE } from '../models/termoRE.model';

export class TermoREService extends ApiService implements BaseService<TermoRE>{

    private strAction = 'api/WebTempTermoRE';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<TermoRE[]> {
        return this.get<TermoRE[]>(this.strAction + `/GetAll`);
    }

    deleteById(TermoREId: number) {
        return this.post(this.strAction + `/DeleteById?id=${TermoREId}`);
    }

    PostFiltroTermoPpe(viewModelFiltraProcesso: ViewModelFiltraProcesso): Observable<TermoRE[]> {
        return this.post<TermoRE[]>(this.strAction + `/PostFiltroTermoRe`, viewModelFiltraProcesso);
    }

    getById(id: number): Observable<TermoRE> {
        return this.get(this.strAction + `/GetById/${id}`);
    }

    getByCpf(cpf : string): Observable<TermoRE[]> {
        return this.get<TermoRE[]>(this.strAction + `/GetByCpf?nrCpf=${cpf}`);
    }

    Put(TermoRE: TermoRE): Observable<TermoRE> {
        return this.post<TermoRE>(this.strAction + `/Put`, TermoRE);
    }

}