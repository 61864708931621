import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { WebAlterarCadastroTemp } from '../models/webAlterarCadastroTemp.model';

export class WebAlterarCadastroTempService extends ApiService implements BaseService<WebAlterarCadastroTemp>{

    private strAction = 'api/WebAlterarCadastroTemp';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<WebAlterarCadastroTemp[]> {
        return this.get<WebAlterarCadastroTemp[]>(this.strAction + `/GetAll`);
    }

    deleteById(idUpload: number) {
        return this.post(this.strAction + `/DeleteById?id=${idUpload}`);
    }

    getById(idUpload: number): Observable<WebAlterarCadastroTemp[]> {
        return this.get(this.strAction + `/GetById?id=${idUpload}`);
    }

    getByPessoa(idPessoa: number): Observable<WebAlterarCadastroTemp> {
        return this.get(this.strAction + `/GetCadastroByPessoa?idPessoa=${idPessoa}`);
    }

}