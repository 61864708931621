import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { WebLogin } from '../models/WebLogin.model';

export class WebLoginService extends ApiService implements BaseService<WebLogin>{

    private strAction = 'api/WebLogin';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<WebLogin[]> {
        return this.get<WebLogin[]>(this.strAction + `/GetAll`);
    }

    deleteById(idPessoa: number) {
        return this.post(this.strAction + `/DeleteById?id=${idPessoa}`);
    }

    getById(idPessoa: number): Observable<WebLogin> {
        return this.get(this.strAction + `/GetById/${idPessoa}`);
    }

    createLogin(WebLogin: WebLogin) {
        return this.post<WebLogin>(this.strAction + `/Adesao`, WebLogin);
    }

}