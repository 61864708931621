import { Component, OnInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';

export interface item {
  color: string;
  background: string;
  text: string;
  router: string;
  icon: string;
}

@Component({
  selector: 'app-menuselect',
  templateUrl: './menuselect.component.html',
  styleUrls: ['./menuselect.component.scss'],
  animations: fuseAnimations
})
export class MenuselectComponent {

  // Menus= ['Operacional','Relátorios', 'Controle de Acesso', 'Configurações', 'Área Restrita'];

  Menus: item[] = [
    // { text: 'Operacional', color: '#232323aa', background: 'transparent', router: '/dashboard', icon: 'developer_board' },
    { text: 'Esteira de Validação', color: '#232323aa', background: 'transparent', router: '/esteira', icon: 'view_list' },
    { text: 'Central de Notificações', color: '#232323aa', background: 'transparent', router: '/regrasNotificacoes', icon: 'notification_important' },
    // {text: 'Configurações', color: '#232323aa', background: 'transparent', router: '/404', icon: ''},
    { text: 'Área Restrita', color: '#232323aa', background: 'transparent', router: '/restrita', icon: 'description' },


    { text: 'Área Interativa', color: '#232323aa', background: 'transparent', router: '/area-interativa', icon: 'description' },
  ];

  constructor() { }

}
