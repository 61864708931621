import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { DependenteADO } from '../models/dependenteADO.model';

@Injectable()
export class DependenteADOService extends ApiService implements BaseService<DependenteADO>{

    private strAction = 'api/DependenteADO';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    deleteById(idPessoa: number) {
        return this.post(this.strAction + `/DeleteById?id=${idPessoa}`);
    }

    getAll(): Observable<DependenteADO[]> {
        return this.get<DependenteADO[]>(this.strAction + `/GetAll`);
    }


    getById(idPessoa: number): Observable<DependenteADO> {
        return this.get(this.strAction + `/GetByIdPessoa/${idPessoa}`);
    }

    Put(dependente: DependenteADO): Observable<DependenteADO> {
        return this.put<DependenteADO>(this.strAction + `/PutUpdateFimVinculoDependente`, dependente);
    }

    Post(dependente: DependenteADO): Observable<DependenteADO> {
        return this.post<DependenteADO>(this.strAction + `/PostInsertDependente`, dependente);
    }

}