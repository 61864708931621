import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ApiService } from './api.services';
import { Router } from '@angular/router';

import { AuthenticationService } from './authentication.service';
import { Login } from '../models/login.model';
import { BaseService } from '../../../../core/base.service';
// import { Util } from '../util';
// import { LoginNewPassword } from '../models/loginNewPassword.model';

@Injectable({
  providedIn: 'root'
})
export class EnviarEmailCobrancasService extends ApiService {

  private strAction = 'api/EnvioEmailAdesao';
  
  constructor(
              httpClient: HttpClient,
            //   private util: Util,
              private route: Router,
              private auth: AuthenticationService) {
              super(httpClient, route); 
              }
  
  enviarCobrancas(dataVencimento: string): Observable<void> {
    return this.post<void>(this.strAction + `/EnviarEmailCobrancas?dataVencimento=${dataVencimento}`);
  }

}
