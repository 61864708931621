export class PlanoParticipanteADO {
    iD_PESSOA: number;
    iD_PLANO: number;
    nM_PLANO : string;
    nR_PLANO_SPC : string;
    dT_INI_PLANO: Date;
    dT_FIM_PLANO: Date;
    dT_INI_AUTO: Date;
    dT_FIM_AUTO: Date;
    iC_PARCIAL: string;
    iC_TRIBUTACAO: string;
    iC_CALCULO_AUTO: string;
    nR_VENCIMENTO : number;
    iD_MODO_PAG_REC : number;
    iD_PESSOA_RESP : number;
    nM_ERRO: string;
    iC_VALIDO: boolean;
}