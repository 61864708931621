import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { TermoPpe } from '../models/TermoPpe.model';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ViewModelFiltraProcesso } from '../models/ViewModelFiltraProcesso.model';

export class TermoPpeService extends ApiService implements BaseService<TermoPpe>{

    private strAction = 'api/WebTempTermoPpe';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    getAll(): Observable<TermoPpe[]> {
        return this.get<TermoPpe[]>(this.strAction + `/GetAll`);
    }

    deleteById(TermoPpeId: number) {
        return this.post(this.strAction + `/DeleteById?id=${TermoPpeId}`);
    }

    PostFiltroTermoPpe(viewModelFiltraProcesso: ViewModelFiltraProcesso): Observable<TermoPpe[]> {
        return this.post<TermoPpe[]>(this.strAction + `/PostFiltroTermoPpe`, viewModelFiltraProcesso);
    }

    getById(id: number): Observable<TermoPpe> {
        return this.get(this.strAction + `/GetById/${id}`);
    }

    getByCpf(cpf : string): Observable<TermoPpe[]> {
        return this.get<TermoPpe[]>(this.strAction + `/GetByCpf?nrCpf=${cpf}`);
    }

    Put(TermoPpe: TermoPpe): Observable<TermoPpe> {
        return this.post<TermoPpe>(this.strAction + `/Put`, TermoPpe);
    }

}