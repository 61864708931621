import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Atendente } from '../models/atendente.model';

@Injectable()
export class AtendenteStore {

    private subject = new BehaviorSubject<Atendente>(null);
    atendente = this.subject.asObservable();

    constructor() { }

    getCurrent(): Observable<Atendente> {
        return this.atendente;
    }

    update (atendente: Atendente) {
        this.subject.next(atendente);
    }
}