import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { WebContratoSeguroRespostas } from '../models/webContratoSeguroRespostas.model';

export class WebContratoSeguroRespostasService extends ApiService implements BaseService<WebContratoSeguroRespostas>{
    
    private strAction = 'api/WebContratoSeguroRespostas';
    
    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
        }

        getAll(): Observable<WebContratoSeguroRespostas[]> {
            return this.get<WebContratoSeguroRespostas[]>(this.strAction + `/GetAll`);
        }

        deleteById(idPessoa: number) {
            return this.post(this.strAction + `/DeleteById?id=${idPessoa}`);
        }
  
        getByContrato(idContrato: Number): Observable<WebContratoSeguroRespostas[]> {
            return this.get(this.strAction + `/GetRespostasByContrato?idContrato=${idContrato}`);
        }

        Post(WebContratoSeguro: WebContratoSeguroRespostas): Observable<WebContratoSeguroRespostas[]>{
            return this.post<WebContratoSeguroRespostas[]>(this.strAction + `/Post`, WebContratoSeguroRespostas);
        }

}