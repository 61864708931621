import { ApiService } from './api.services';
import { BaseService } from 'app/core/base.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { WebDocumentosRestrito } from '../models/webDocumentosRestrito.model';
import { Injectable } from '@angular/core';

@Injectable()
export class WebDocumentoRestritoService extends ApiService implements BaseService<WebDocumentosRestrito>{

    private strAction = 'api/WebDocumentosRestrito';

    constructor(
        httpClient: HttpClient,
        private route: Router) {
        super(httpClient, route);
    }

    deleteById(idDocumento: number) {
        return this.post(this.strAction + `/DeleteById?id=${idDocumento}`);
    }

    getAll(): Observable<WebDocumentosRestrito[]> {
        return this.get<WebDocumentosRestrito[]>(this.strAction + `/GetAll`);
    }


    getById(idDocumento: number): Observable<WebDocumentosRestrito> {
        return this.get(this.strAction + `/GetById/${idDocumento}`);
    }

    getBy(tipoParticipante: string, cpfParticipante: string, idPlano: number, idCategoria: number, idSituacao: number): Observable<WebDocumentosRestrito[]> {
        if (tipoParticipante == 'C')
            return this.get(this.strAction + `/Get?tipoParticipante=${tipoParticipante}&cpfParticipante=${cpfParticipante}`);
        if (tipoParticipante == 'P')
            return this.get(this.strAction + `/Get?tipoParticipante=${tipoParticipante}&idPlano=${idPlano}`);
        if (tipoParticipante == 'S')
            return this.get(this.strAction + `/Get?tipoParticipante=${tipoParticipante}&idCategoria=${idCategoria}&idSituacao=${idSituacao}`);
        return null;
    }


    Post(WebDocumentosRestrito: WebDocumentosRestrito): Observable<WebDocumentosRestrito> {
        return this.post<WebDocumentosRestrito>(this.strAction + `/CriarDocumento`, WebDocumentosRestrito);
    }

}