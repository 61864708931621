export class WebConfigDash {
    idConfig: string;
    idPlano: string;
    idCategoria: string;
    idSituacao: string;
    idCardPosicao: string;
    nrCardCorFundo: string;
    nrCardCorFundoTexto: string;
    nrCardCorHeader: string;
    nrCardCorHeaderTexto: string;
    nrCardCorFooter: string;
    nrCardCorFooterTexto: string;
    nmCardImg: string;
    nmCardTitulo: string;
    nmCardSubtitulo: string;
    nmCardLink: string;
    icCardBlank: string;
}