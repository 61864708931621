import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Login } from '../models/login.model';

@Injectable()
export class LoginStore {

    private Subject = new BehaviorSubject<Login>(null);
    login = this.Subject.asObservable();

    constructor() { }

    getCurrent(): Observable<Login> {
        return this.login;
    }

    update(login: Login){
        this.Subject.next(login);
    }

    
    getUser(){
    return this.Subject.asObservable();
  }
   

}