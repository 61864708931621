import { Processo } from './processo.model';
import { Esteira } from './esteira.model';
import { Pessoa } from './pessoa.model';
import { Plano } from './plano.model';


export class TermoPercentualContribuicao {
    idTermo: number;
    idPessoa: number;
    nmProtocolo: number;
    vlBasica: number;
    vlSuplementar: number;
    vlSalario: number;
    pcBasica: number;
    pcSuplementar: number;
    dtOpcao: Date;
    webTipoProcesso: Processo;
    webEsteiraStatus: Esteira;
    pessoa: Pessoa;
    plano: Plano;
}